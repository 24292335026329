
import { observer } from "mobx-react-lite"
import { FC, useContext } from "react"
import {useLocation} from "react-router-dom"
import {Context} from "../../.."
import {XImage} from "../../../core/models/XImage"

import styles from "../../../styles/Subpage.module.scss"

const BCHImgs: XImage[] = [
  { url: "/assets/images/events/bch/1.jpeg", title: "" },
  { url: "/assets/images/events/bch/2.jpeg", title: "" },
  { url: "/assets/images/events/bch/3.jpeg", title: "" },
  { url: "/assets/images/events/bch/4.jpeg", title: "" },
  { url: "/assets/images/events/bch/5.jpeg", title: "" },
  { url: "/assets/images/events/bch/6.jpeg", title: "" }
]

const CP1Imgs: XImage[] = [
  { url: "/assets/images/events/corp/1.jpeg", title: "" },
  { url: "/assets/images/events/corp/2.jpeg", title: "" },
  { url: "/assets/images/events/corp/3.jpeg", title: "" }
]

const CP2Imgs: XImage[] = [
  { url: "/assets/images/events/corp/4.jpeg", title: "" },
  { url: "/assets/images/events/corp/5.jpeg", title: "" },
  { url: "/assets/images/events/corp/6.jpeg", title: "" },
  { url: "/assets/images/events/corp/7.jpeg", title: "" },
  { url: "/assets/images/events/corp/8.jpeg", title: "" },
  { url: "/assets/images/events/corp/9.jpeg", title: "" }
]

const CP3Imgs: XImage[] = [
  { url: "/assets/images/events/corp/10.jpeg", title: "" },
  { url: "/assets/images/events/corp/11.jpeg", title: "" },
  { url: "/assets/images/events/corp/12.jpeg", title: "" },
  { url: "/assets/images/events/corp/13.jpeg", title: "" },
  { url: "/assets/images/events/corp/14.jpeg", title: "" },
  { url: "/assets/images/events/corp/15.jpeg", title: "" }
]

const SCHImgs: XImage[] = [
  { url: "/assets/images/events/sch/1.jpeg", title: "" },
  { url: "/assets/images/events/sch/2.jpeg", title: "" },
  { url: "/assets/images/events/sch/4.jpeg", title: "" }
]

const CC1Imgs: XImage[] = [
  { url: "/assets/images/events/cate/1.jpeg", title: "" },
  { url: "/assets/images/events/cate/2.jpeg", title: "" },
  { url: "/assets/images/events/cate/3.jpeg", title: "" }
]

const CC2Imgs: XImage[] = [
  { url: "/assets/images/events/cate/4.jpeg", title: "" },
  { url: "/assets/images/events/cate/5.jpeg", title: "" },
  { url: "/assets/images/events/cate/6.jpeg", title: "" },
  { url: "/assets/images/events/cate/7.jpeg", title: "" },
  { url: "/assets/images/events/cate/8.jpeg", title: "" },
  { url: "/assets/images/events/cate/9.jpeg", title: "" }
]

const T1Imgs: XImage[] = [
  { url: "/assets/images/events/torg/1.jpeg", title: "" },
  { url: "/assets/images/events/torg/2.jpeg", title: "" },
  { url: "/assets/images/events/torg/3.jpeg", title: "" },
  { url: "/assets/images/events/torg/4.jpeg", title: "" },
  { url: "/assets/images/events/torg/5.jpeg", title: "" },
  { url: "/assets/images/events/torg/6.jpeg", title: "" }
]

const T2Imgs: XImage[] = [
  { url: "/assets/images/events/torg/7.jpeg", title: "" },
  { url: "/assets/images/events/torg/8.jpeg", title: "" },
  { url: "/assets/images/events/torg/9.jpeg", title: "" },
  { url: "/assets/images/events/torg/10.jpeg", title: "" },
  { url: "/assets/images/events/torg/11.jpeg", title: "" },
  { url: "/assets/images/events/torg/12.jpeg", title: "" }
]

const KIDSImgs: XImage[] = [
  { url: "/assets/images/events/kids/1.jpeg", title: "" },
  { url: "/assets/images/events/kids/2.jpeg", title: "" },
  { url: "/assets/images/events/kids/3.jpeg", title: "" },
  { url: "/assets/images/events/kids/4.jpeg", title: "" },
  { url: "/assets/images/events/kids/5.jpeg", title: "" },
  { url: "/assets/images/events/kids/6.jpeg", title: "" },
  { url: "/assets/images/events/kids/7.jpeg", title: "" },
  { url: "/assets/images/events/kids/8.jpeg", title: "" },
  { url: "/assets/images/events/kids/9.jpeg", title: "" }
]

const Events: FC = () => {

  const { store } = useContext(Context)
  const location = useLocation()

  const openGalleryModal = (imgs: XImage[], index: number) => {
    store.setSelectedImages(imgs)
    store.setSelectedImageIndex(index)
    store.setIsImageViewVisible(true)
  }

  return (
    <>
      {location.pathname == "/detskie-prazdniki" ? <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2><strong>Детские праздники</strong></h2>
                
              <p>
                Для самых маленьких гостей мы предлагаем массу вариантов проведения праздника, которые точно понравится имениннику и его друзьям. Наша анимационная команда устроит для вашего ребёнка не просто праздник, а настоящее торжество, чудо, созданное специально для него. Море эмоций в этот день вам гарантировано!<br/><br/>

                Аниматоры отеля Arkhyz Royal Resort & SPA 5* - настоящие профессионалы, обладающие терпением, тактом, артистизмом, разбирающиеся в особенностях психического развития детей и умеющие находить выход из любой неожиданной ситуации. Они найдут подход к любому ребенку и подарят ему только положительные эмоции.<br/>
                А разнообразие костюмов для наших аниматоров порадует даже самого взыскательного гостя.<br/><br/>
              </p>
                
              <div className={styles.grid_images}>
                <div className={styles.gi_other}>
                  {KIDSImgs.map((image, ix) => <div style={{height: "260px", backgroundImage: `url(${image.url})`}} onClick={() => openGalleryModal(KIDSImgs, ix)}></div>)}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div> : <></>}
      
      {location.pathname == "/torghestvo" ? <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2><strong>День Рождения, годовщина, юбилей</strong></h2>
                
              <p>
                Ресторан «Гризайль» предлагает все условия для создания незабываемой атмосферы торжественного ужина. Забудьте о множестве мелких дел и организационной суматохе.<br/>
                Мы предоставляем нашим гостям лучшее предложение для организации волшебных банкетов, которые надолго останутся в вашей памяти.<br/><br/>

                День Рождения бывает всего раз в году, и каждый раз – особенный и неповторимый. А крупные юбилеи случаются всего раз в жизни. Мы ценим наше время с друзьями, любимыми, близкими. Подарите себе незабываемые мгновения праздника в ресторане «Гризайль».<br/><br/>
              </p>
                
              <div className={styles.grid_images}>
                <div className={styles.gi_other}>
                  {T1Imgs.map((image, ix) => <div style={{height: "260px", backgroundImage: `url(${image.url})`}} onClick={() => openGalleryModal(T1Imgs, ix)}></div>)}
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div> : <></>}

      {/** */}
      
      {location.pathname == "/corporate-catering" ? <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2><strong>Кейтеринг в горах</strong> - ваш ресторан на природе</h2>
                
              <p>
                Кейтеринг от отеля Arkhya Royal Resort & SPA 5* – это изысканная ресторанная еда, качественное обслуживание и безупречный сервис на свежем воздухе.<br/><br/>

                Лучшие повара ресторана «Гризайль» и команда вежливых профессиональных официантов сделают так, что ваше событие прошло по высшему классу, независимо от его направленности и тематики. Будь то банкет, фуршет, свадьба, день рождения или корпоративное мероприятие – вы и ваши гости останетесь довольны!<br/><br/>

                Банкет на природе может быть организован как самостоятельная программа вашего отдыха, а может стать завершающей частью командообразующего мероприятия и ждать вас на финише после джипинга.<br/><br/>

                Для тех, кто ценит качество и индивидуальный подход, в дополнение к банкету мы готовы организовать:<br/>
                - доставку к месту проведения банкета на джипах;<br/>
                - конные прогулки;<br/>
                - фотосессии;<br/>
                - развлекательную программу и многое другое.<br/><br/>

                Чтобы после вы могли с еще большим удовольствием насладиться ресторанной едой и высококачественным обслуживанием. Блюда от нашего шеф-повара будут еще вкуснее на свежем воздухе в окружении дикой природы.<br/><br/>
              </p>
                
              <div className={styles.grid_images}>
                <div className={styles.gi_other}>
                  {CC1Imgs.map((image, ix) => <div style={{height: "260px", backgroundImage: `url(${image.url})`}} onClick={() => openGalleryModal(CC1Imgs, ix)}></div>)}
                </div>
              </div>
                
            </div>
          </div>
        </div>
      </div> : <></>}
      
      {location.pathname == "/small-conference-hall" ? <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2><strong>VIP-зал</strong> – для переговоров</h2>
                
              <p>
                Переговорная комната отеля Arkhyz Royal Resort & SPA 5* своей камерной атмосферой располагает к спокойному и успешному ведению переговоров. Она подходит для деловых встреч в небольшом составе. Дизайнерский интерьер, удобные кресла, просторный стол — хорошее начало доверительной беседы с партнёрами.<br/><br/>

                <strong>Вместительность:</strong> до 12 человек.<br/>
                <strong>Аренда VIP-зала:</strong> 1500 руб./час<br/><br/>
              </p>
                
              <div className={styles.grid_images}>
                <div className={styles.gi_flex}>
                  {SCHImgs.map((image, ix) => <div style={{height: "260px", backgroundImage: `url(${image.url})`}} onClick={() => openGalleryModal(SCHImgs, ix)}></div>)}
                </div>
              </div>

              <h2 className={styles.mt64} style={{marginBottom: "20px"}}><strong>Оставьте заявку</strong> - получите самое выгодное предложение!</h2>

              <p style={{textAlign: "center", marginBottom: "12px", display: "none"}}>Внимание акция! При регистрации <span style={{color: "red"}}>- 10% скидка на меню ресторана!</span></p>

              <iframe 
                scrolling="no" 
                id="form176320-451" 
                name="form176320-451" 
                src="https://formdesigner.ru/form/iframe/176320?center=1"
                style={{height: "781px", width: "100%", border: "0px"}}>
              </iframe>

            </div>
          </div>
        </div>
      </div> : <></>}

      {location.pathname == "/big-conference-hall" ? <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2><strong>Большой конференц-зал</strong> - для бизнеса</h2>
                
              <p>
                Конференц-зал Arkhyz Royal Resort & SPA 5* оснащен самым современным оборудованием для проведения конференций, семинаров, вебинаров различной сложности. Благодаря современным технологиям и технической поддержке, входящей в пакеты конференций, настройка и подключение оборудование займет не более 5 минут.<br/><br/>

                <strong>Вместительность:</strong> до 80 человек.<br/>
                <strong>Оборудование:</strong> проектор, экран, флип-чарт, аудиосистема.<br/>
                <strong>Аренда большого конференц-зала:</strong> 2500 руб./час<br/><br/>
              </p>
                
              <div className={styles.grid_images}>
                <div className={styles.gi_other}>
                  {BCHImgs.map((image, ix) => <div style={{height: "260px", backgroundImage: `url(${image.url})`}} onClick={() => openGalleryModal(BCHImgs, ix)}></div>)}
                </div>
              </div>

              <h2 className={styles.mt64} style={{marginBottom: "20px"}}><strong>Оставьте заявку</strong> - получите самое выгодное предложение!</h2>

              <p style={{textAlign: "center", marginBottom: "12px", display: "none"}}>Внимание акция! При регистрации <span style={{color: "red"}}>- 10% скидка на меню ресторана!</span></p>

              <iframe 
                scrolling="no" 
                id="form176320-451" 
                name="form176320-451" 
                src="https://formdesigner.ru/form/iframe/176320?center=1"
                style={{height: "781px", width: "100%", border: "0px"}}>
              </iframe>

            </div>
          </div>
        </div>
      </div> : <></>}
      
      {location.pathname == "/corporativnie-predlogheniya" ? <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>Корпоративные предложения для бизнеса</h2>
                
              <p>
                Корпоративное мероприятие в горах Архыза – это отличная идея для любого времени года. Единовременно в отеле могут остановиться около 230 гостей, и каждому будет оказан поистине королевский прием!<br/><br/>
                Для проведения деловых встреч наш отель предлагает конференц-зал вместимостью до 80 человек и переговорную VIP-комнату на 12 человек. К услугам наших гостей мы предоставляем современное аудио- и видеооборудование для конференций, семинаров, вебинаров различной сложности.<br/><br/>
                По запросу организаторов участникам мероприятий могут быть предложены кофе-брейки, обеды, ужины и фуршеты, а также выездные банкеты на природе.<br/><br/>
              </p>
                
              <div className={styles.grid_images}>
                <div className={styles.gi_other}>
                  {CP1Imgs.map((image, ix) => <div style={{height: "260px", backgroundImage: `url(${image.url})`}} onClick={() => openGalleryModal(CP1Imgs, ix)}></div>)}
                </div>
              </div>

              <h2 className={styles.mt64}>Корпоративный отдых с пользой и удовольствием!</h2>
              
              <p>
                Тимбилдинги, тренинги, стратегические сессии, фитнес-туры, коучинг-сессии и корпоративы – возможности нашего отеля позволяют провести мероприятие практически любой сложности!<br/><br/>

                Приезжайте вместе с сотрудниками в Arkhyz Royal Resort & Spa 5* - это прекрасная возможность совместить корпоративный отдых и приключение, повысить командный дух и всем вместе узнать что-то новое.<br/><br/>

                На территории комплекса подготовлены лучшие виды услуг для активного и спокойного отдыха:<br/><br/>

                — 87 комфортных номеров со стильными интерьерами;<br/><br/>

                — Ресторанный комплекс «Гризайль» с изысканными блюдами европейской кухни и кавказскими специалитетами;<br/><br/>

                — Конференц-зал на 80 человек с необходимым оборудованием, Wi-Fi;<br/><br/>

                — VIP зал на 12 чел.;<br/><br/>

                — Открытый всесезонный подогреваемый бассейн с видом на Кавказские горы;<br/><br/>

                — Большой SPA-комплекс «Термаль» с крытым бассейном, комплексом бань и саун;<br/><br/>

                — Активный отдых – конный клуб, велосипеды, треккинг в горах, экскурсии;<br/><br/>

                — Банкет на природе может стать завершающей частью вашего командообразующего мероприятия.<br/><br/>
              </p>

              <div className={styles.grid_images}>
                <div className={styles.gi_other}>
                  {CP2Imgs.map((image, ix) => <div style={{height: "260px", backgroundImage: `url(${image.url})`}} onClick={() => openGalleryModal(CP2Imgs, ix)}></div>)}
                </div>
              </div>

              <h2 className={styles.mt64}>Ваше торжество - день рождения, годовщина, юбилей</h2>

              <p>
                Лучшие повара и команда профессиональных официантов ресторана «Гризайль» сделают так, что ваше событие прошло по высшему классу, независимо от его направленности и тематики.<br/><br/>
                Будь то банкет, фуршет, свадьба, день рождения или корпоративное мероприятие – вы и ваши гости останетесь довольны!<br/><br/>
                Ресторан предлагает все условия для создания незабываемой атмосферы торжественного ужина.<br/><br/>
                А если вы хотите провести мероприятие на свежем воздухе, к вашим услугам - кейтеринг от отеля Arkhya Royal Resort & SPA 5*. Это изысканная ресторанная еда, качественное обслуживание и безупречный сервис на свежем воздухе.<br/><br/>
              </p>

              <div className={styles.grid_images}>
                <div className={styles.gi_other}>
                  {CP3Imgs.map((image, ix) => <div style={{height: "260px", backgroundImage: `url(${image.url})`}} onClick={() => openGalleryModal(CP3Imgs, ix)}></div>)}
                </div>
              </div>

              <h2 className={styles.mt64}>Получите индивидуальное предложение - прямо сейчас!</h2>

              <iframe 
                scrolling="no" 
                id="form176320-472" 
                name="form176320-472" 
                src="https://formdesigner.ru/form/iframe/176320?center=1" 
                style={{height: "781px", width: "100%", border: "0px"}}></iframe>

            </div>
          </div>
        </div>
      </div> : <></>}
    </>
  )
}

export default observer(Events)