import { FC, useContext, useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import AppRouter from './components/AppRouter';
import { Context } from '.';
import useWindowDimensions from './core/hooks/useWindowDimensions';

import './styles/App.scss';
import Header from './components/Header';
import Footer from './components/Footer';
import { SliderToggleDirection, SliderToggleDir, StorageKeys } from './core/utils/defaults';

import ImageViewModal from "./components/ModalImageView"
import {Helmet} from 'react-helmet';
import AppRouterWinter from './components/AppRouterWinter';
import {useLocation} from 'react-router-dom';
import ThemeSwitcher from './components/ThemeSwitcher';
import AdminManager from './pages/Admin/AdminManager';
import {roomsCompiled} from './core/utils/menu_winter';
import {useFetching} from './core/hooks/useFetching';
import {ApiService} from './core/api/api';

const App: FC = () => {

  const { store, adminStore } = useContext(Context)

  const location = useLocation()
  
  const [userName, setUserName] = useState("")
  const [userPhone, setUserPhone] = useState("")
  
  const { width, height } = useWindowDimensions()

  const [fetchSettings, isSettingsLoading] = useFetching(async () => {
    const pagesRestore = localStorage.getItem(StorageKeys.PAGES)
    if (pagesRestore != null) {
      adminStore.setPages(JSON.parse(pagesRestore))

      const themeRest = localStorage.getItem(StorageKeys.SETTINGS_THEME)
      const metaRest = localStorage.getItem(StorageKeys.SETTINGS_META)
      const roomsRest = localStorage.getItem(StorageKeys.SETTINGS_ROOMS)
      const salesRest = localStorage.getItem(StorageKeys.SETTINGS_SALES)

      if (themeRest != null && metaRest != null && roomsRest != null && salesRest != null) {
        // store.setTheme(themeRest)
        adminStore.setMetaData(JSON.parse(metaRest))
        adminStore.setRoomsData(JSON.parse(roomsRest))
        adminStore.setSalesData(JSON.parse(salesRest))
      }
    }
    
    const pagesRes = await ApiService.pages.getList()
    const res = await ApiService.core.getSettings()

    console.log(res, pagesRes)

    const settings: any[] = res.data.settings;
    const theme = settings.find(v => v.name == "theme")
    const meta = settings.find(v => v.name == "meta_config")
    const rooms = settings.find(v => v.name == "rooms")
    const sales = settings.find(v => v.name == "sales")

    const pages = pagesRes.data.pages

    localStorage.setItem(StorageKeys.SETTINGS_THEME, theme.data.value)
    localStorage.setItem(StorageKeys.SETTINGS_ROOMS, JSON.stringify(rooms.data))
    localStorage.setItem(StorageKeys.SETTINGS_META, JSON.stringify(meta.data))
    localStorage.setItem(StorageKeys.SETTINGS_SALES, JSON.stringify(sales.data))
    localStorage.setItem(StorageKeys.PAGES, JSON.stringify(pages))

    store.setBackTheme(theme.data.value)
    
    if (theme.data.value == "winter") {
      if (!location.pathname.includes("summer")) {
        store.setTheme(theme.data.value)
      }
    } else {
      if (!location.pathname.includes("winter")) {
        store.setTheme(theme.data.value)
      }
    }

    adminStore.setMetaData(meta.data)
    adminStore.setRoomsData(rooms.data)
    adminStore.setSalesData(sales.data)
    adminStore.setPages(pages)
  })

  useEffect(() => {
    if (width <= 960) {
      store.setIsMobile(true);
    } else {
      store.setIsMobile(false);
    }
  }, [width, height]);

  useEffect(() => {
    if (width <= 960) {
      store.setIsMobile(true);
    } else {
      store.setIsMobile(false);
    }

    setTimeout(() => {
      store.setIsFaderShown(false);
      setTimeout(() => {
        store.setIsFaderVisible(false);
      }, 400);
    }, 1300);

    store.checkAuth()

    fetchSettings()

    //console.log(JSON.stringify(roomsCompiled))

    if (store.theme == "winter" && !location.pathname.includes("summer")) {
      document.body.classList.add(store.theme)
    }
  }, []);


  return (
    location.pathname != "/admin/auth" ? <>
      {store.backTheme == "winter" && !location.pathname.includes("summer") ? <Helmet>
        <meta name="theme-color" content="#63849c" />
        <link rel="icon" href="/favicon_winter.ico" />
        <link rel="apple-touch-icon" href="/favicon_winter.ico" />
        <meta property="og:image" content="https://royalresort.ru/favicon_winter.ico" />
      </Helmet> : <Helmet>
        <meta name="theme-color" content="#879970" />
        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/favicon.ico" />
        <meta property="og:image" content="https://royalresort.ru/summer/favicon.ico" />
      </Helmet>}      

      <div className="fader" style={{display: store.isFaderVisible ? "block" : "none", opacity: store.isFaderShown ? "1" : "0"}}></div>

      <Header />  
      
      {<AppRouter />}
      
      <ThemeSwitcher />

      <Footer />

      {store.isAuth ? <AdminManager /> : <></>}

      <ImageViewModal
        visible={store.isImageViewVisible}
        images={store.selectedImages}
        selectedIndex={store.selectedImageIndex}
        onToggleImage={(dir: SliderToggleDirection) => {
          if (dir == SliderToggleDir.NEXT) {
            if (store.selectedImageIndex < (store.selectedImages.length - 1)) {
              store.setSelectedImageIndex(store.selectedImageIndex + 1)
            } else {
              store.setSelectedImageIndex(0)
            }
          } else {
            if (store.selectedImageIndex > 0) {
              store.setSelectedImageIndex(store.selectedImageIndex - 1)
            } else {
              store.setSelectedImageIndex(store.selectedImages.length - 1)
            }
          }
        }}
        onClose={() => store.setIsImageViewVisible(false)} />
    </> : <>{store.theme == "winter" ? <AppRouterWinter /> : <AppRouter />}</>
  )
}

export default observer(App)
