import { PromoSale } from "../models/PromoSale";

export const Promos: PromoSale[] = [
  {
    index: 1,
    image: "/assets/images/promos/1.webp",
    url: "besplatnaya-otmena",
    title: "Бесплатная отмена<br/>за 24 часа!",
    tag: "Новые тарифы",
    data: {
      title: "БЕСПЛАТНАЯ ОТМЕНА брони за 24 часа!",
      content: `
        Друзья, наши тарифы стали еще гибче!<br/><br/>
        Размер скидки зависит от условий отмены - для невозвратных тарифов предоставляется самая большая скидка. Для тарифов без скидки бесплатная отмена возможна всего за 24 часа! Смело планируйте свой отдых, ведь в случае изменения планов Вы ничего не потеряете! Или экономьте, бронируя по самой низкой цене.<br/><br/>
        В стоимость проживания входит завтрак "шведский стол" и посещение нашего SPA-комплекса с двумя бассейнами, хаммамом, финской сауной, аромасауной и соляной комнатой. Открытый подогреваемый бассейн позволит насладиться комфортной температурой круглый год!<br/><br/>
        Скидка предоставляется только на основные места, при размещении детей или взрослых на дополнительных местах скидка отобразится чуть меньше. Тарифы действуют только для новых бронирований физлиц и только на нашем официальном сайте.<br/>
      `
    }
  }, {
    index: 2,
    image: "/assets/images/promos/2.webp",
    url: "prozhivanie-ot-9700r-za-dvoih",
    title: "Номер для двоих<br/>от 9700р.",
    tag: "Успейте забронировать",
    data: {
      title: "Проживание от 9700р. за двоих!",
      content: `
        Пятизвездочный отдых по супер выгодным ценам!<br/>
        Номер Deluxe в октябре - всего от 9 700р!<br/>
        В мае -от 11 500! В июне - от 12 400!<br/><br/>
        Выходные по цене будней! Скидка 50% на проживание детей на дополнительных местах!<br/><br/>
        Ценообразование динамическое, количество номеров по этим ценам ограничено!<br/><br/>
        В стоимость входит завтрак «шведский стол» и посещение SPA-комплекса.<br/><br/>
        🐆 Успейте забронировать Ваш роскошный отдых в Arkhyz Royal Resort & SPA 5*❤<br/>
        Лучшие цены - на официальном сайте отеля или по телефону <a href="tel:88002221027"><strong>8 800 222 1027</strong></a>
        `
    }
  }, {
    index: 3,
    image: "/assets/images/promos/3.webp",
    url: "dlitelnoe-prozhivanie-so-skidkoy",
    title: "Длительное проживание<br/>со скидкой 20%",
    tag: "Отдых от 5 ночей",
    data: {
      title: "ДЛИТЕЛЬНОЕ ПРОЖИВАНИЕ со скидкой 20%!",
      content: `
        Самые выгодные условия для тех, кто остается у нас на 5 ночей и более.<br/><br/>
        Скидка 20%, даже на ближайшие даты!*<br/>
        Безлимитный SPA – в подарок!<br/><br/>
        Проведите отпуск у нас с максимальной выгодой!<br/><br/>
        В стоимость проживания входит завтрак "шведский стол" и безлимитное посещение SPA-комплекса с двумя бассейнами, хаммамом, финской сауной, аромасауной и соляной комнатой. Открытый подогреваемый бассейн позволит насладиться комфортной температурой круглый год!<br/><br/>
        Скидка предоставляется только на основные места, при размещении детей или взрослых на дополнительных местах скидка отобразится чуть меньше. Тарифы действуют только для новых бронирований физлиц и только на нашем официальном сайте.<br/><br/>
        * Акция действует при бронировании проживания в летний и осенний период.<br/><br/>
        Не упустите возможность забронировать роскошный отдых по лучшей цене!<br/>
      `
    }
  }, {
    index: 4,
    image: "/assets/images/promos/4.webp",
    url: "detox-v-gorah",
    title: "Комплексное очищение<br/>организма и снижение веса",
    tag: "Detox в горах",
    data: {
      title: "Detox в горах - комплексное очищение организма и снижение веса!",
      content: `
        Почувствовать себя вновь наполненным энергией, избавиться от лишних килограммов и ощутить настоящую легкость в теле помогут уникальные программы нашего Центра здоровья на базе отеля Arkhyz Royal Resort & SPA 5*.<br/><br/>
        Детокс – комплексное очищения и обновления организма, генеральная уборка в теле. Это комплекс процедур, который помогает быстро избавиться от токсинов, шлаков, бактерий и грибов, восстановить жизненные силы и укрепить иммунную систему.<br/><br/>
        Мы разработали комплекс оздоровительных и детокс-программ, объединив актуальные тренды превентивной медицины, достижения современной косметологии, очищения организма и премиальный отдых в 5-звездочном отеле с высоким уровнем сервиса.<br/><br/>
        Забудьте о голоданиях на одних смузи! Детокс у нас — это не изматывающая диета и голодание. Это индивидуально подобранный 5-разовый рацион изысканных ресторанных блюд.<br/><br/>
        В нашей программе устойчивой детоксикации нет быстрых диет или экстремального голодания. Специальное detox-меню разработано опытным врачом-гастроэнтерологом совместно с шеф-поваром ресторана «Гризайль», чтобы сбалансировать полезные оздоровительные и изысканные вкусовые качества наших блюд.<br/>
      `
    }
  }, {
    index: 5,
    image: "/assets/images/promos/5.webp",
    url: "restoran",
    title: "Новое авторское меню<br/>в ресторане «Гризайль»",
    tag: "Уникальные вкусовые сочетания",
    data: {
      title: "",
      content: ``
    }
  }, {
    index: 6,
    image: "/assets/images/promos/6.webp",
    url: "stroyka-na-vremya-kurort-navsegda",
    title: "Стройка на время,<br/>курорт навсегда!",
    tag: "Информация для гостей",
    data: {
      title: "Стройка на время, курорт навсегда!",
      content: `
        Уважаемые гости! Наш курорт стремительно развивается, сейчас его ежегодно посещают более 700 тысяч туристов. По прогнозам к 2030 году турпоток на «Архыз» должен вырасти в несколько раз. Такие масштабные изменения невозможны без строительных работ, о которых мы считаем нужным Вас предупредить.<br/><br/>
        В скором времени будут построены горнолыжные трассы, канатные дороги, гостиницы, и другие объекты туристической инфраструктуры. Также запланировано строительство и реконструкция инженерных сетей.<br/><br/>
        Мы, в свою очередь, делаем все возможное, чтобы это никак не повлияло на качество Вашего отдыха в нашем отеле. Спасибо за понимание! Ждем Вас!<br/>
      `
    }
  }, {
    index: 7,
    image: "/assets/images/promos/7.webp",
    url: "zimniy-sezon",
    title: "Продажи горнолыжного<br/>сезона 23/24 открыты!",
    tag: "Зимний сезон",
    data: {
      title: "Зимний сезон: старт продаж!",
      content: `
        МЫ ОТКРЫВАЕМ БРОНИРОВАНИЕ НА ГОРНОЛЫЖНЫЙ СЕЗОН 23-24.<br/><br/>
        Ощутить прилив эндорфинов от спуска по заснеженным трассам. Или получить удовольствие от вечернего катания. Разные уровни сложности, безупречная работа сервисов и эмоции, которые хочется пережить вновь.<br/><br/>
        Лучший отель всесезонного курорта Архыза это:<br/>
        -безупречный сервис 24 часа в сутки;<br/>
        -термальная зона с одним из самых красивых панорамных бассейнов России;<br/>
        -ресторан авторской кухни «Гризайль»;<br/>
        -оздоровительные программы;<br/>
        -прокат горнолыжного оборудования и многое другое.<br/>
      `
    }
  }, {
    index: 8,
    image: "/assets/images/promos/8.webp",
    url: "semeynie-vihodnie-v-arkhyze",
    title: "Отдых в горах для всей семьи<br/>и безлимитное посещение SPA",
    tag: "Семейные выходные в Архызе",
    data: {
      title: "Семейные выходные в Архызе",
      content: `
        Устали от работы и школы? Хотите снять напряжение и отдохнуть от повседневной рутины?<br/>
        Посетите ARKHYZ ROYAL Resort & SPA 5*<br/><br/>
        Отдых в премиальном отеле с удобными кроватями и восхитительным видом на горы способствует полному расслаблению и здоровому сну.<br/><br/>
        Всем гостям мы дарим бесплатное и безлимитное посещение SPA-центра Термаль», который включает в себя комплекс бань и саун, а также 2 бассейна, один из которых расположен под открытым небом и круглогодично подогревается.<br/><br/>
        Турецкая баня-хаммам с теплым влажным паром и сухая финская сауна быстро снимут усталость и напряжение. Арома-сауна наполнит каждую клеточку тела полезными веществами эфирных масел, улучшит самочувствие. Обязательно посетите соляную комнату — по своей эффективности она сравнима с отдыхом на морском курорте: микроэлементы, насыщающие воздух, выведут токсины из организма и укрепят иммунитет.<br/><br/>
        Никакого стресса – только комфорт и полноценный отдых!
      `
    }
  }
]