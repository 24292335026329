import { observer } from "mobx-react-lite"
import { FC, useEffect } from "react"

import styles from "../../../styles/Subpage.module.scss"

const Reviews: FC = () => {
  
  useEffect(() => {
    
  }, [])

  return (
    <>
      <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2><strong>Отзывы</strong> - узнайте что думают гости о нас!</h2>
              
              <div className="col-12 col-md-6 offset-md-3"><iframe id="tlFrame0.8520103744736751" name="tlFrame0.8520103744736751" frameBorder="0" width="100%" height="1976" scrolling="no" style={{width: "100%", background: "#fff", maxHeight: "1180px"}} src="https://yandex.ru/maps-reviews-widget/60762596873?comments"></iframe></div>
              
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(Reviews)