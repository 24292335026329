import {message, Switch, Upload, UploadProps} from "antd"
import { observer } from "mobx-react-lite"
import { FC, useContext, useState, useEffect, useRef } from "react"
import { useLocation } from "react-router-dom"
import { Context } from "../../.."
import {ApiService} from "../../../core/api/api"
import {PromoSale} from "../../../core/models/PromoSale"
import {API_URL, StorageKeys} from "../../../core/utils/defaults"
import {translit} from "../../../core/utils/utils"
import imageCompression from "browser-image-compression"
import { Editor } from "@tinymce/tinymce-react"

import { InfoCircleOutlined, InfoOutlined, LoadingOutlined } from "@ant-design/icons"

import styles from "../../../styles/AdminManager.module.scss"
import Modal from "../../../components/Modal"
import {IXImages} from "../../../core/models/XPage"

const SalesManager: FC = () => {
  
  const { store, adminStore } = useContext(Context)
  const location = useLocation()

  const [actualSales, setActualSales] = useState([] as PromoSale[])
  const [offSales, setOffSales] = useState([] as PromoSale[])
  const [activeTab, setActiveTab] = useState(1)
  const [activeTabEd, setActiveTabEd] = useState(1)
  
  const [isFormInfoVisible, setIsFormInfoVisible] = useState(false)
  const [isDataLoading, setIsDataLoading] = useState(false)
  const [isDeleteVisible, setIsDeleteVisible] = useState(false)
  
  const [saleImages, setSaleImages] = useState([] as IXImages[])
  const [saleImage, setSaleImage] = useState("")
  const [saleTitle, setSaleTitle] = useState("")
  const [saleText, setSaleText] = useState("")
  const [saleTextT, setSaleTextT] = useState("")
  const [saleUrl, setSaleUrl] = useState("")
  const [saleTag, setSaleTag] = useState("")
  const [saleStatus, setSaleStatus] = useState(0)
  const [saleContact, setSaleContact] = useState(0)
  const [saleFormId, setSaleFormId] = useState("0")
  const [saleOrder, setSaleOrder] = useState(0)
  const [selectedSale, setSelectedSale] = useState(null as null | PromoSale)

  const editorRef = useRef(null as any)

  const props3: UploadProps = {
    name: 'images',
    multiple: false,
    maxCount: 1,
    showUploadList: false,
    headers: {
      "Authorization": "Bearer " + store.token
    },
    action: `${API_URL}/core/uploader/images`,
    beforeUpload: async (file, FileList) => {
      setIsDataLoading(prev => prev = true);
      const options = { maxSizeMB: 0.8, maxWidthOrHeight: 1920, useWebWorker: true }
      try {
        const compressedFile = await imageCompression(file, options);
        console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
        console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
        return compressedFile;
      } catch (error) {
        console.log(error);
      }
    },
    onChange: async (info) => {
      const { status } = info.file;
      if (status === 'done') {
        message.success(`Файл успешно загружен!`);
        console.log(info.file.response)
        setSaleImage(prev => prev = info.file.response.urls[0])
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  }

  const props4: UploadProps = {
    name: 'images',
    multiple: false,
    maxCount: 1,
    showUploadList: false,
    headers: {
      "Authorization": "Bearer " + store.token
    },
    action: `${API_URL}/core/uploader/images`,
    beforeUpload: async (file, FileList) => {
      setIsDataLoading(prev => prev = true);
      const options = { maxSizeMB: 0.8, maxWidthOrHeight: 1920, useWebWorker: true }
      try {
        const compressedFile = await imageCompression(file, options);
        console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
        console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
        return compressedFile;
      } catch (error) {
        console.log(error);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  }

  const fetchSales = () => {
    setActualSales(prev => prev = adminStore.salesData.filter(v => v.status == 1))
    setOffSales(prev => prev = adminStore.salesData.filter(v => v.status == 0))
  }

  const selectSale = (sale: PromoSale) => {
    setSaleImages(sale.images!)
    setSaleImage(sale.image)
    setSaleTitle(sale.data.title)
    setSaleText(sale.data.content)
    setSaleUrl(sale.url)
    setSaleTag(sale.tag)
    setSaleStatus(sale.status!)
    setSaleContact(sale.contact_form!)
    setSaleFormId(sale.contact_form_id!)
    setSaleOrder(sale.order!)
    setSelectedSale(prev => prev = sale)
  }

  const addNewSaleAction = async () => {
    setIsDataLoading(prev => prev = true)

    const newLst = [...adminStore.salesData]

    newLst.push({
      order: 0,
      images: [],
      image: "https://royalresort.ru/uploads/images/default_avatar.png",
      title: "Не заполнено",
      url: "ne-zapolneno",
      contact_form: 0,
      status: 0,
      tag: "",
      data: {
        title: "Не заполнено",
        content: "Не заполнено"
      }
    })

    newLst.forEach((element, ix) => {
      element.index = ix + 1
    })

    const res = await ApiService.core.updateSetting(4, {
      data: newLst
    })

    console.log(res)

    adminStore.setSalesData(newLst)
    localStorage.setItem(StorageKeys.SETTINGS_SALES, JSON.stringify(newLst))

    fetchSales()

    setActiveTab(prev => prev = 2)

    message.success("Новая акция успешно добавлена!")
    setIsDataLoading(prev => prev = false)
  }

  const saveSaleAction = async () => {
    setIsDataLoading(prev => prev = true)

    let newLst: PromoSale[] = []
    let newPromoSale: PromoSale | null = null
    adminStore.salesData.forEach((item) => {
      if (selectedSale != null) {
        if (item.index == selectedSale.index) {
          newPromoSale = {
            index: newLst.length + 1,
            image: saleImage,
            images: saleImages,
            title: saleTitle,
            url: saleUrl,
            status: saleStatus,
            contact_form: saleContact,
            contact_form_id: saleFormId,
            order: saleOrder,
            tag: saleTag,
            data: {
              title: saleTitle,
              content: editorRef.current.getContent()
            }
          }
          newLst.push(newPromoSale)
        } else {
          newLst.push(item)
        }
      } else {
        newLst.push(item)
      }
    })

    newLst.forEach((element, ix) => {
      element.index = ix + 1
    })

    const res = await ApiService.core.updateSetting(4, {
      data: newLst
    })

    adminStore.setSalesData(newLst)
    localStorage.setItem(StorageKeys.SETTINGS_SALES, JSON.stringify(newLst))

    if (newPromoSale != null) {
      selectSale(newPromoSale)
    }

    fetchSales()

    message.success("Акция успешно обновлена!")
    setIsDataLoading(prev => prev = false)
  }

  const deleteSaleAction = async () => {
    setIsDeleteVisible(prev => prev = false)
    setIsDataLoading(prev => prev = true)

    let newLst: PromoSale[] = []
    adminStore.salesData.forEach((item) => {
      if (selectedSale != null) {
        if (item.index != selectedSale.index) {
          newLst.push(item)
        }
      } else {
        newLst.push(item)
      }
    })

    newLst.forEach((element, ix) => {
      element.index = ix + 1
    })

    const res = await ApiService.core.updateSetting(4, {
      data: newLst
    })

    adminStore.setSalesData(newLst)
    localStorage.setItem(StorageKeys.SETTINGS_SALES, JSON.stringify(newLst))
    setSelectedSale(prev => prev = null)

    fetchSales()

    message.success("Акция успешно удалена!")
    setIsDataLoading(prev => prev = false)
  }

  const isDataChanged = () => {
    if (
      selectedSale != null &&
      (
        saleImage != selectedSale.image ||
        saleTitle != selectedSale.data.title ||
        saleTextT != selectedSale.data.content ||
        saleUrl != selectedSale.url ||
        saleTag != selectedSale.tag ||
        saleStatus != selectedSale.status ||
        saleContact != selectedSale.contact_form ||
        saleFormId != selectedSale.contact_form_id ||
        saleOrder != selectedSale.order
      )
    ) {
      return true
    } else {
      return false
    }
  }

  const addImagesBlock = () => {
    setSaleImages(prev => prev = [...prev!, {
      height: "260px",
      items: []
    } as IXImages])
  }

  const deleteImagesBlock = (ix: number) => {
    setSaleImages(prev => prev = [...prev!.filter((v, i) => i != ix)])
  }

  const handleOver = (ev: any) => {
    ev.preventDefault();
  }

  const handleDrag = (ev: any) => {
    if (ev.currentTarget != undefined) {
      adminStore.setDragId(parseInt(ev.currentTarget.id));
    }
  }

  const handleDrop = async (ev: any) => {
    ev.preventDefault();
    const dragItem = (activeTab == 1 ? actualSales : offSales).find((item: any) => item.index == adminStore.dragId);
    const dropItem = (activeTab == 1 ? actualSales : offSales).find(
      (item: any) => item.index == parseInt(ev.currentTarget.id)
    );
    
    const arr = moveItem(dragItem!.index - 1, dropItem!.index - 1)

    adminStore.setSalesData(arr)

    const res = await ApiService.core.updateSetting(4, {
      data: [...arr]
    });

    fetchSales()

    message.success("Расположение акций успешно обновлено!");
  }

  const moveItem = (from: number, to: number) => {
    const f = adminStore.salesData.findIndex((v: any) => v.index == (from + 1));
    const t = adminStore.salesData.findIndex((v: any) => v.index == (to + 1));
    let slds: any[] = [];
    let sldsEx: any[] = [...adminStore.salesData];

    //store.aSlides.splice(to, 0, f)
    adminStore.salesData.forEach((itm: any, ix: number) => {
      if (ix == f) {
        slds.push(sldsEx[t]);
      } else if (ix == t) {
        slds.push(sldsEx[f]);
      } else {
        slds.push(itm);
      }
    });

    return slds;
  }

  useEffect(() => {
    fetchSales()
  }, [])

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className={styles.grid_block}>
              <h3><span style={{
                display: selectedSale != null ? "inline-block" : "none"
              }} onClick={() => setSelectedSale(prev => prev = null)}><i className="far fa-arrow-left-long"></i> </span>{selectedSale == null ? "Список акций" : "Редактирование акции"}</h3>
              
              {selectedSale == null ? <>
                <div className={styles.xtabs + " " + styles.minimized + " " + styles[`active${activeTab}`]}>
                  <div className={styles.xtabs_item} onClick={() => setActiveTab(prev => prev = 1)}>Активные</div>
                  <div className={styles.xtabs_item} onClick={() => setActiveTab(prev => prev = 2)}>Отключенные</div>
                </div>

                <div className={styles.xactions}>
                  <div className={styles.xactions_item + " " + styles.success} onClick={() => addNewSaleAction()}>Добавить акцию</div>
                </div>
                
                <p className={styles.xdesc}>Вы можете менять расположение акций.<br/>Для этого зажмите акцию и перенесите на нужное место, взамен другой.</p>
                  
                <div className={styles.xgrid}>
                  {(activeTab == 1 ? actualSales : offSales).map((item, ix) => <div className={styles.xgrid_item}>
                    <div 
                      className={styles.xcard} 
                      onClick={() => selectSale(item)}
                      draggable={true}
                      key={ix}
                      id={`${item.index}`}
                      onDragOver={(e) => handleOver(e)}
                      onDragStart={(e) => handleDrag(e)}
                      onDrop={(e) => handleDrop(e)}>
                      <div className={styles.xcard_image} style={{backgroundImage: `url(${item.image})`}}></div>
                      <div className={styles.xcard_data}>
                        <div className={styles.xcard_data__title} dangerouslySetInnerHTML={{__html: item.title}}></div>
                      </div>
                      <div className={styles.xcard_actions}></div>
                    </div>
                  </div>)}
                </div>
              </> : <>
                <div className={styles.xactions}>
                  <div className={styles.xactions_item + " " + styles.success + (!isDataChanged() ? " " + styles.disabled : "")} onClick={() => saveSaleAction()}>Сохранить</div>
                  <div className={styles.xactions_item + " " + styles.danger} onClick={() => setIsDeleteVisible(true)}>Удалить</div>
                </div>
                
                <div className={styles.xtabs + " " + styles.minimized + " " + styles[`active${activeTabEd}`]}>
                  <div className={styles.xtabs_item} onClick={() => setActiveTabEd(prev => prev = 1)}>Основное</div>
                  <div className={styles.xtabs_item} onClick={() => setActiveTabEd(prev => prev = 2)}>Изображения</div>
                </div>

                <div className="row" style={{display: activeTabEd == 2 ? "flex" : "none"}}>
                  <div className="col-12 col-md-9">
                  <div className={styles.xform} style={{display: activeTabEd == 2 ? "block" : "none"}}>
                      <div className={styles.xform_input}>
                        <label htmlFor="sale_title">Изображения</label>
                        <div className={styles.ximages}>
                          <div className={styles.ximages_add}><button onClick={() => addImagesBlock()}>Добавить блок</button></div>
                          {saleImages != null ? saleImages.map((imagesBlock: IXImages, ix: number) => <div className={styles.ximages_block}>
                            <div className={styles.ximages_delete} onClick={() => deleteImagesBlock(ix)}>Удалить блок</div>
                            <label>Высота изображений</label>
                            <input type="text" placeholder="" value={imagesBlock.height} onChange={(event) => {
                              setSaleImages(prev => {
                                if (prev != null) {
                                  let newImages: IXImages[] = []
                                  prev.forEach((im: IXImages, ixx: number) => {
                                    let newIm: any = {...im}
                                    if (ix == ixx) {
                                      newIm.height = event.target.value
                                    }
                                    newImages.push(newIm)
                                  });
                                  return newImages
                                } else {
                                  return []
                                }
                              })
                            }} />
                            <Upload {...props4} onChange={(info) => {
                              const { status } = info.file;
                              setIsDataLoading(prev => prev = false)
                              if (status === 'done') {
                                message.success(`Файл успешно загружен!`);
                                console.log(info.file.response)
                                setSaleImages(prev => {
                                  let lst: IXImages[] = []

                                  if (prev != null) {
                                    prev.forEach((el, ixy) => {
                                      if (ixy == ix) {
                                        el.items.push({title: "", url: info.file.response.urls[0]})
                                      }
                                      lst.push(el)
                                    })
                                  }

                                  return lst
                                })
                              }
                            }} style={{width: "100%"}}>
                              <div className={styles.xuploader}>
                                <div className={styles.xupload_data}>
                                  <h4>Изображения</h4>
                                  <p>Нажмите или перенесите файл в эту область,<br/>в формате <b>PNG</b> / <b>JPG</b> / <b>WEBP</b></p>
                                </div>
                                <div className={styles.xupload_items}>
                                  {imagesBlock.items.map((image, ixxx) => <div className={styles.xupload_image} style={{backgroundImage: `url(${image.url})`}}>
                                    <div className={styles.delete} onClick={(event) => {
                                      event.preventDefault()
                                      event.stopPropagation()
                                      setSaleImages(prev => {
                                        if (prev != null) {
                                          let newImages: any[] = []
                                          prev.forEach((im: IXImages, ixxxx: number) => {
                                            let newIm: any = {...im}
                                            if (ix == ixxxx) {
                                              newIm.items = newIm.items.filter((v: any) => v.url != image.url)
                                            }
                                            newImages.push(newIm)
                                          });
                                          return newImages
                                        } else {
                                          return []
                                        }
                                      })
                                    }}><i className="far fa-xmark"></i></div>
                                  </div>)}
                                </div>
                              </div>
                            </Upload>
                          </div>) : <></>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row" style={{display: activeTabEd == 1 ? "flex" : "none"}}>
                  <div className="col-12 col-md-9">
                    <div className={styles.xform}>
                      <Upload {...props3} style={{width: "100%"}}>
                        <div className={styles.xupload}>
                          <div className={styles.xupload_image} style={{backgroundImage: `url(${saleImage})`}}></div>
                          <div className={styles.xupload_data}>
                            <h4>Картинка акции</h4>
                            <p>Нажмите или перенесите файл в эту область,<br/>в формате <b>PNG</b> / <b>JPG</b> / <b>WEBP</b></p>
                          </div>
                        </div>
                      </Upload>
                      <div className={styles.flexed}>
                        <div className={styles.xform_input}>
                          <label htmlFor="sale_url">URL акции</label>
                          <input type="text" id="sale_url" disabled={true} placeholder="URL акции" value={saleUrl} onChange={(event) => setSaleUrl(prev => prev = event.target.value)} />
                        </div>
                        <div className={styles.xform_input}>
                          <label htmlFor="sale_tag">Тэг акции</label>
                          <input type="text" id="sale_tag" placeholder="Тэг акции" value={saleTag} onChange={(event) => setSaleTag(prev => prev = event.target.value)} />
                        </div>
                        <div className={styles.xform_input}>
                          <label htmlFor="sale_form_id">ID Формы <span onClick={() => setIsFormInfoVisible(prev => prev = true)}><InfoCircleOutlined rev={null} /></span></label>
                          <input type="text" id="sale_form_id" placeholder="ID из FormDesigner" value={saleFormId} onChange={(event) => setSaleFormId(prev => prev = event.target.value)} />
                        </div>
                      </div>
                      <div className={styles.flexed}>
                        <div className={styles.xform_input}>
                          <label>Статус акции</label>
                          <Switch defaultChecked={saleStatus == 1} onChange={(event) => {setSaleStatus(prev => prev = event ? 1 : 0)}} /> {saleStatus == 1 ? "Активна" : "Отключена"}
                        </div>
                        <div className={styles.xform_input}>
                          <label>Обратная связь</label>
                          <Switch defaultChecked={saleContact == 1} onChange={(event) => {setSaleContact(prev => prev = event ? 1 : 0)}} /> {saleContact == 1 ? "Активна" : "Отключена"}
                        </div>
                        <div className={styles.xform_input}>
                          <label>Кнопка брони</label>
                          <Switch defaultChecked={saleOrder == 1} onChange={(event) => {setSaleOrder(prev => prev = event ? 1 : 0)}} /> {saleOrder == 1 ? "Активна" : "Отключена"}
                        </div>
                      </div>
                      <div className={styles.xform_input}>
                        <label htmlFor="sale_title">Заголовок</label>
                        <input type="text" id="sale_title" placeholder="Заголовок акции" value={saleTitle} onChange={(event) => {
                          setSaleTitle(prev => prev = event.target.value)
                          let vvl = ""
                          const spl = event.target.value.split(" ")
                          if (spl.length > 1) {
                            vvl = translit(`${spl[0]}-${spl[1]}`)
                          } else {
                            vvl = translit(`${spl[0]}`)
                          }
                          setSaleUrl(prev => prev = vvl.toLowerCase())
                        }} />
                      </div>
                      <div className={styles.xform_input}>
                        <label htmlFor="sale_title">Контент</label>
                        <Editor
                          onInit={(evt, editor) => editorRef.current = editor}
                          initialValue={saleText}
                          onEditorChange={(content: string) => {
                            setSaleTextT(prev => prev = content)
                          }}
                          apiKey="ez24admnrtvwwg51bfav53892ae99r6lwfqz058ayemt3a42"
                          init={{
                            height: 400,
                            menubar: true,
                            language: "ru",
                            plugins: [
                              'autolink', 'lists','link','image','charmap','preview','anchor','searchreplace','visualblocks',
                              'fullscreen','insertdatetime','media','table','help','wordcount'
                            ],
                            toolbar: 'undo redo | formatselect | ' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'removeformat | help',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; color: #000;}'
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>}

            </div>
          </div>
        </div>
      </div>

      <Modal
        visible={isDeleteVisible}
        title="Удалить акцию"
        content={<>
          <div className="modal_text">
            <p>Вы действительно хотите удалить акцию?</p>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={() => deleteSaleAction()} style={{marginBottom: "0px"}}>Удалить</button>
            </div>
          </div>
        </>}
        onClose={() => setIsDeleteVisible(false)} />

      <Modal
        visible={isFormInfoVisible}
        title="Form Designer"
        content={<>
          <div className="modal_text">
            <p>
            В это поле <b>необходимо</b> вставить ID формы, из платформы FormDesigner, которая будет использоваться на странице.<br/><br/>
            К примеру:<br/>
            <b>206917</b> - новогодняя форма<br/>
            <b>176320</b> - корпоративная форма
            </p>
          </div>
        </>}
        onClose={() => setIsFormInfoVisible(false)} />

      <Modal
        visible={isDataLoading}
        title="Загрузка..."
        content={<>
          <div className="modal_text">
            <p style={{textAlign: "center", padding: "32px 0"}}><LoadingOutlined rev={null} style={{color: "#000", fontSize: "42px"}} /></p>
          </div>
        </>}
        onClose={() => setIsDataLoading(false)} />
    </>
  )
}

export default observer(SalesManager)