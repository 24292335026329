import { observer } from "mobx-react-lite"
import { FC, useContext } from "react"
import {useLocation} from "react-router-dom"
import {Context} from "../../.."
import {XImage} from "../../../core/models/XImage"

import styles from "../../../styles/Subpage.module.scss"

const DCImgs: XImage[] = [
  { url: "/assets/images/kids/k1.jpeg", title: "" },
  { url: "/assets/images/kids/k2.jpeg", title: "" },
  { url: "/assets/images/kids/k3.jpeg", title: "" },
  { url: "/assets/images/kids/k4.jpeg", title: "" },
  { url: "/assets/images/kids/k5.jpeg", title: "" },
  { url: "/assets/images/kids/k6.jpeg", title: "" },
  { url: "/assets/images/kids/k7.jpeg", title: "" },
  { url: "/assets/images/kids/k8.jpeg", title: "" },
  { url: "/assets/images/kids/k9.jpeg", title: "" },
  { url: "/assets/images/kids/k10.jpeg", title: "" },
  { url: "/assets/images/kids/k11.jpeg", title: "" },
  { url: "/assets/images/kids/k12.jpeg", title: "" },
  { url: "/assets/images/kids/k13.jpeg", title: "" },
  { url: "/assets/images/kids/k14.jpeg", title: "" },
  { url: "/assets/images/kids/k15.jpeg", title: "" }
]

const PRImgs: XImage[] = [
  { url: "/assets/images/kids/pr/pr1.jpeg", title: "" },
  { url: "/assets/images/kids/pr/pr2.jpeg", title: "" },
  { url: "/assets/images/kids/pr/pr3.jpeg", title: "" }
]

const OIImgs: XImage[] = [
  { url: "/assets/images/kids/oi/oi1.jpeg", title: "" },
  { url: "/assets/images/kids/oi/oi2.jpeg", title: "" },
  { url: "/assets/images/kids/oi/oi3.jpeg", title: "" }
]

const PGImgs: XImage[] = [
  { url: "/assets/images/kids/pg/pg1.jpeg", title: "" },
  { url: "/assets/images/kids/pg/pg2.jpeg", title: "" },
  { url: "/assets/images/kids/pg/pg3.jpeg", title: "" }
]

const ANImgs: XImage[] = [
  { url: "/assets/images/kids/an/an1.jpeg", title: "" },
  { url: "/assets/images/kids/an/an2.jpeg", title: "" },
  { url: "/assets/images/kids/an/an3.jpeg", title: "" }
]

const LBImgs: XImage[] = [
  { url: "/assets/images/kids/lb/lb1.jpeg", title: "" },
  { url: "/assets/images/kids/lb/lb2.jpeg", title: "" },
  { url: "/assets/images/kids/lb/lb3.jpeg", title: "" }
]

const KNImgs: XImage[] = [
  { url: "/assets/images/kids/kn/kn1.jpeg", title: "" },
  { url: "/assets/images/kids/kn/kn2.jpeg", title: "" },
  { url: "/assets/images/kids/kn/kn3.jpeg", title: "" }
]

const Kids: FC = () => {

  const { store } = useContext(Context)
  const location = useLocation()

  const openGalleryModal = (imgs: XImage[], index: number) => {
    store.setSelectedImages(imgs)
    store.setSelectedImageIndex(index)
    store.setIsImageViewVisible(true)
  }

  return (
    <>
      {location.pathname.includes("/kinozal") ? <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2><strong>Кинозал</strong></h2>
              
              <p>
                Мультики на большом экране<br/>
                Ежедневно в 18:00 мы ждем наших маленьких гостей в кинозале напротив детской комнаты.<br/><br/>
                Просмотр мультфильмов или семейного кино - вы выбираете, что смотреть, а мы угощаем попкорном! Родители тоже могут присоединиться к просмотру, или устроить романтический ужин вдвоем...<br/><br/>
              </p>
              
              <div className={styles.grid_images}>
                <div className={styles.gi_other}>
                  {KNImgs.map((image, ix) => <div style={{height: "260px", backgroundImage: `url(${image.url})`}} onClick={() => openGalleryModal(KNImgs, ix)}></div>)}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div> : <></>}
      
      {location.pathname.includes("/library") ? <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2><strong>Детская библиотека</strong></h2>
              
              <p>
                Детская библиотека - формат уютного времяпрепровождения для детей, влюблённых в чтение и шелест страниц. Найдётся литература от научной до всеми любимых Disney, сказки народов мира и многое другое. «Чтение - это окошко, через которое дети видят и познают мир и самих себя» В.А. Сухомлинский<br/><br/>
              </p>
              
              <div className={styles.grid_images}>
                <div className={styles.gi_other}>
                  {LBImgs.map((image, ix) => <div style={{height: "260px", backgroundImage: `url(${image.url})`}} onClick={() => openGalleryModal(LBImgs, ix)}></div>)}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div> : <></>}
      
      {location.pathname.includes("/animation") ? <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2><strong>Анимация</strong> – счастливое детство в Arkhyz Royal Resort & SPA</h2>
              
              <p>
                Аниматоры отеля Arkhyz Royal Resort & SPA - настоящие профессионалы, обладающие терпением, тактом, артистизмом, разбирающиеся в особенностях психического развития детей и умеющие находить выход из любой неожиданной ситуации. Они найдут подход к любому ребенку и подарят ему только положительные эмоции.<br/>
                А разнообразие костюмов для наших аниматоров порадует даже самого взыскательного гостя.<br/>
                Время мероприятий и программа может варьироваться, актуальное расписание событий можно уточнить у администратора. <br/><br/>
              </p>
              
              <div className={styles.grid_images}>
                <div className={styles.gi_other}>
                  {ANImgs.map((image, ix) => <div style={{height: "260px", backgroundImage: `url(${image.url})`}} onClick={() => openGalleryModal(ANImgs, ix)}></div>)}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div> : <></>}
      
      {location.pathname.includes("/playground") ? <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2><strong>Детская площадка</strong> – игры на свежем воздухе</h2>
              
              <p>
                Просторная детская площадка из натурального дерева, расположенная у входа, станет прекрасным местом для проведения подвижных игр, различных спортивных соревнований, веселых зарядок, интересных квестов. А кварцевый песок, привезенный из Северной Европы, порадует маленьких гостей, которые любят играть в песочнице.<br/><br/>
              </p>
              
              <div className={styles.grid_images}>
                <div className={styles.gi_other}>
                  {PGImgs.map((image, ix) => <div style={{height: "260px", backgroundImage: `url(${image.url})`}} onClick={() => openGalleryModal(PGImgs, ix)}></div>)}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div> : <></>}
      
      {location.pathname.includes("/obuchaushie-igry") ? <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2><strong>Обучающие игры</strong> - учиться и познавать мир</h2>
              
              <p>
                Каждый ребенок — уникален, поэтому мы подобрали мастер-классы и развлечения для всех возрастов. У нас никто не заскучает! Приключенческие квесты, интерактивные шоу-программы, творческие мастерские и многое другое ждёт ваших малышей в Arkhyz Royal Resort & SPA!<br/><br/>
              </p>
              
              <div className={styles.grid_images}>
                <div className={styles.gi_other}>
                  {OIImgs.map((image, ix) => <div style={{height: "260px", backgroundImage: `url(${image.url})`}} onClick={() => openGalleryModal(OIImgs, ix)}></div>)}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div> : <></>}
      
      {location.pathname.includes("/playroom") ? <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2><strong>Игровая комната</strong> - отдыхайте, пока отдыхают ваши дети</h2>
              
              <p>
                Игровая комната отеля Arkhyz Royal Resort & SPA расположена рядом с лобби-баром и отделена от него прозрачной стеной — даже находясь здесь, малыш будет у вас на виду. У нас вы спокойно сможете оставить своего ребенка на несколько часов, где он под присмотром опытного детского воспитателя увлекательно проведет всё это время.<br/>
                Игровая комната работает ежедневно с 09.00 до 21.00.<br/><br/>
              </p>
              
              <div className={styles.grid_images}>
                <div className={styles.gi_other}>
                  {PRImgs.map((image, ix) => <div style={{height: "260px", backgroundImage: `url(${image.url})`}} onClick={() => openGalleryModal(PRImgs, ix)}></div>)}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div> : <></>}
      
      {location.pathname.includes("/detskij-center") ? <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2><strong>Детский клуб «Лео»</strong> - когда не приходится скучать</h2>
              
              <p>
                Оздоровительные программы, разнообразное детское меню, тематические мастер-классы, экскурсионные туры, анимационные вечера и многое другое под профессиональной заботой наших педагогов и аниматоров позволят занять вашего малыша на целый день! Отдыхайте, пока отдыхают ваши дети!<br/>
                Время мероприятий и программа может варьироваться, актуальное расписание событий можно уточнить у администратора.<br/><br/>
              </p>
              
              <div className={styles.grid_images}>
                <div className={styles.gi_other}>
                  {DCImgs.map((image, ix) => <div style={{height: "260px", backgroundImage: `url(${image.url})`}} onClick={() => openGalleryModal(DCImgs, ix)}></div>)}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div> : <></>}
      
      {location.pathname.includes("/detskij-centr") ? <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2><strong>Детский клуб «Лео»</strong> - когда не приходится скучать</h2>
              
              <p>
                Оздоровительные программы, разнообразное детское меню, тематические мастер-классы, экскурсионные туры, анимационные вечера и многое другое под профессиональной заботой наших педагогов и аниматоров позволят занять вашего малыша на целый день! Отдыхайте, пока отдыхают ваши дети!<br/>
                Время мероприятий и программа может варьироваться, актуальное расписание событий можно уточнить у администратора.<br/><br/>
              </p>
              
              <div className={styles.grid_images}>
                <div className={styles.gi_other}>
                  {DCImgs.map((image, ix) => <div style={{height: "260px", backgroundImage: `url(${image.url})`}} onClick={() => openGalleryModal(DCImgs, ix)}></div>)}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div> : <></>}
    </>
  )
}

export default observer(Kids)