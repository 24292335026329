import { observer } from "mobx-react-lite"
import { FC, useContext } from "react"
import {useLocation} from "react-router-dom"
import {Context} from "../../.."
import {XImage} from "../../../core/models/XImage"

import styles from "../../../styles/Subpage.module.scss"

const KS3Imgs: XImage[] = [
  { url: "/assets/images/horses/h1.jpeg", title: "" },
  { url: "/assets/images/horses/school/2.jpg", title: "" },
  { url: "/assets/images/horses/h3.jpeg", title: "" }
]
const KS4Imgs: XImage[] = [
  { url: "/assets/images/horses/h4.jpeg", title: "" },
  { url: "/assets/images/horses/h5.jpeg", title: "" },
  { url: "/assets/images/horses/h6.jpeg", title: "" }
]
const KS5Imgs: XImage[] = [
  { url: "/assets/images/horses/school/4.jpg", title: "" },
  { url: "/assets/images/horses/school/5.jpg", title: "" },
  { url: "/assets/images/horses/school/6.jpg", title: "" }
]

const KSImgs: XImage[] = [
  { url: "/assets/images/horses/h1.jpeg", title: "" },
  { url: "/assets/images/horses/h2.jpeg", title: "" },
  { url: "/assets/images/horses/h3.jpeg", title: "" }
]

const KS2Imgs: XImage[] = [
  { url: "/assets/images/horses/h4.jpeg", title: "" },
  { url: "/assets/images/horses/h5.jpeg", title: "" },
  { url: "/assets/images/horses/h6.jpeg", title: "" },
  { url: "/assets/images/horses/h7.jpeg", title: "" },
  { url: "/assets/images/horses/h8.jpeg", title: "" },
  { url: "/assets/images/horses/h9.jpeg", title: "" }
]

const PRGImgs: XImage[] = [
  { url: "/assets/images/horses/prg/p1.jpeg", title: "" },
  { url: "/assets/images/horses/prg/p2.jpeg", title: "" },
  { url: "/assets/images/horses/prg/p3.jpeg", title: "" }
]

const FTImgs: XImage[] = [
  { url: "/assets/images/horses/ft/ft1.jpeg", title: "" },
  { url: "/assets/images/horses/ft/ft2.jpeg", title: "" },
  { url: "/assets/images/horses/ft/ft3.jpeg", title: "" },
  { url: "/assets/images/horses/ft/ft4.jpeg", title: "" },
  { url: "/assets/images/horses/ft/ft5.jpeg", title: "" },
  { url: "/assets/images/horses/ft/ft6.jpeg", title: "" }
]

const Horses: FC = () => {

  const { store } = useContext(Context)
  const location = useLocation()

  const openGalleryModal = (imgs: XImage[], index: number) => {
    store.setSelectedImages(imgs)
    store.setSelectedImageIndex(index)
    store.setIsImageViewVisible(true)
  }

  return (
    <>
      {location.pathname.includes("/fotosession-with-horse") ? <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2><strong>Фотосессия с лошадьми</strong> - поймать незабываемый момент</h2>
              
              <p>
                Фотосессия с лошадьми — это прекрасная возможность запечатлеть себя и своих близких в необычном и оригинальном образе вместе с одним из самых чутких и загадочных животных под руководством опытного фотографа.<br/>
                Получайте оригинальные и красивые снимки, которым позавидуют друзья! Профессиональный фотограф проведет для Вас незабываемую фотосессию, сделав серию качественных и уникальных фотографий.<br/>
                Заказать фотосессию с лошадьми можно на стойке ресепшна Arkhyz Royal Resort & SPA.<br/><br/>

                Стоимость часовой фотосессии составляет - 3500 рублей.<br/>
                Стоимость двухчасовой фотосессии составляет 5500 рублей.<br/><br/>
              </p>

              <div className={styles.grid_images}>
                <div className={styles.gi_other}>
                  {FTImgs.map((image, ix) => <div style={{height: "586px", backgroundImage: `url(${image.url})`}} onClick={() => openGalleryModal(FTImgs, ix)}></div>)}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div> : <></>}

      {location.pathname.includes("/konnie-progulki-po-arkhyzu") ? <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2><strong>Конные прогулки</strong> - наедине с природой</h2>
              
              <p>
                Избавиться от стресса, почувствовать настоящее умиротворение, научится владеть собственным телом и пообщаться с благородными и умными животными. Такую возможность дают конные прогулки, один из тех редких видов созерцательных упражнений, когда отдыхает и раскрывается душа.<br/>
                Лошади сильные и в тоже время послушные в управлении животные, дарят забытое чувство органичного единения с природой.<br/>
                Конные прогулки от Arkhyz Royal Resort & SPA проходят на территории туристической деревни «Романтик». Продолжительность прогулки составляет от 30 минут до 2 часов. Маршрут подбирается исходя из ваших предпочтений и уровня подготовки.<br/><br/>

                <strong>Прейскурант на конные прогулки:</strong><br/>
                30 минут - 700р<br/>
                1 час - 1 200р<br/>
                1.5 часа - 1 800р<br/>
                2 часа - 2 400р<br/>
                3 часа - 3 300р<br/><br/>

                <strong>Прокат выходного дня, катание на лошадях и пони:</strong><br/>
                5 минут - 350р<br/>
                10 минут - 450р<br/>
                15 минут - 550р<br/>
                20 минут - 650р<br/><br/>
              </p>
              
              <div className={styles.grid_images}>
                <div className={styles.gi_other}>
                  {PRGImgs.map((image, ix) => <div style={{height: "260px", backgroundImage: `url(${image.url})`}} onClick={() => openGalleryModal(PRGImgs, ix)}></div>)}
                </div>
              </div>

              <div className={styles.grid_images}>
                <div className={styles.gi_other}>
                  {KS2Imgs.map((image, ix) => <div style={{height: "260px", backgroundImage: `url(${image.url})`}} onClick={() => openGalleryModal(KS2Imgs, ix)}></div>)}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div> : <></>}
      
      {location.pathname.includes("/konnaya-shkola") || location.pathname.includes("/konniy-club") ? <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2><strong>Конная школа</strong> - обучение верховой езде</h2>
              
              <p>
                Конная школа в Arkhyz Royal Resort & SPA — настоящий центр притяжения для взрослых и детей! Для наших гостей здесь предлагается приятный отдых в компании с грациозными животными — лошадьми. Давно хотели научиться ездить верхом и насладиться незабываемыми видами на горы? Наши опытные инструкторы осуществят вашу мечту!<br/>
                К занятиям допускаются взрослые и дети с 6 лет.<br/><br/>
                <strong>Занятия в конной школе:</strong><br/>
                1 занятие / 45 мин. – 1500 руб.<br/>
                2 занятия / - 2800 руб.<br/>
                Абонемент из 3-х занятий - 4000 руб.<br/>
                Абонемент на 5 занятий - 6400 руб.<br/><br/>
                <strong>Фотосессия с лошадьми</strong><br/>
                3500 руб./час<br/><br/>
              </p>
              
              <div className={styles.grid_images}>
                <div className={styles.gi_other}>
                  {KS3Imgs.map((image, ix) => <div style={{height: "260px", backgroundImage: `url(${image.url})`}} onClick={() => openGalleryModal(KSImgs, ix)}></div>)}
                </div>
              </div>

              <div className={styles.grid_images}>
                <div className={styles.gi_other}>
                  {KS4Imgs.map((image, ix) => <div style={{height: "586px", backgroundImage: `url(${image.url})`}} onClick={() => openGalleryModal(KS2Imgs, ix)}></div>)}
                </div>
              </div>

              <div className={styles.grid_images}>
                <div className={styles.gi_other}>
                  {KS5Imgs.map((image, ix) => <div style={{height: "260px", backgroundImage: `url(${image.url})`}} onClick={() => openGalleryModal(KS2Imgs, ix)}></div>)}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div> : <></>}
    </>
  )
}

export default observer(Horses)