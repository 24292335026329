import { Menu, MenuProps } from "antd"
import { observer } from "mobx-react-lite"
import { FC, useContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Context } from ".."
import useWindowDimensions from "../core/hooks/useWindowDimensions"
import { menuItems, MenuItem as MenuItemA } from "../core/utils/menu"
import { menuItems as menuItemsWin } from "../core/utils/menu_winter"
import { isNullOrUndefined, translit } from "../core/utils/utils"

import styles from "../styles/Header.module.scss"

interface HeaderProps {
  isMobile?: boolean
  onOpenMenu?: any
  goToSection?: any
  onBackCall?: any
}

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

// const items: MenuProps['items'] = [
//   getItem('Navigation Two', 'sub2', null, [
//     getItem('Option 5', '5'),
//     getItem('Option 6', '6'),
//   ]),

//   getItem('Navigation Three', 'sub4', null, [
//     getItem('Option 9', '9'),
//     getItem('Option 10', '10'),
//     getItem('Option 11', '11'),
//     getItem('Option 12', '12'),
//   ])
// ];

const Header: FC<HeaderProps> = ({
  onOpenMenu = null, goToSection = null, onBackCall = null, isMobile = false
}) => {

  const { store } = useContext(Context)
  const { width, height } = useWindowDimensions()
  const navigate = useNavigate();
  const location = useLocation();

  const [headerToggled, setHeaderToggled] = useState(false);

  const [isMenuShown, setIsMenuShown] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  
  const [items, setItems] = useState([] as MenuProps['items']);

  const generateItems = () => {
    let newItems: any[] = [];
    (store.theme == "winter" && !location.pathname.includes("summer") ? menuItemsWin : menuItems).forEach(menuItem => {
      let drop: any[] | undefined = undefined;
      if (menuItem.dropdown != null) {
        drop = []
        menuItem.dropdown.items.forEach(dropItem => {
          drop!.push(getItem(dropItem.title, dropItem.url))
        })
      }
      let newGr = getItem(menuItem.title, menuItem.url == "#" ? translit(menuItem.title) : menuItem.url, null, drop)
      newItems.push(newGr)
    });
    setItems(prev => prev = newItems)
  }

  const openMenu = () => {
    setIsMenuShown(prev => prev = true);
    document.getElementsByTagName("html")[0].style.overflow = "hidden"
    setTimeout(() => {
      setIsMenuVisible(prev => prev = true);
    }, 100);
  }

  const closeMenu = () => {
    setIsMenuVisible(prev => prev = false);
    document.getElementsByTagName("html")[0].style.overflow = "unset"
    setTimeout(() => {
      setIsMenuShown(prev => prev = false);
    }, 300);
  }

  const goTop = () => {
    if (store.menuSelected != 0) {
      store.setIsHomeBlockVisible(false);
      store.setIsHeaderLocked(false);
      store.setSelectedSlide(0);
      setTimeout(() => {
        store.setMenuSelected(0);
        setTimeout(() => {
          store.setIsHomeBlockVisible(true);
        }, 100);
      }, 200);
    }

    const section = document.querySelector(`#top`);
    if (!isNullOrUndefined(section)) {
      section.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }

  const navTo = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    
    let path = "";

    if (event.target.nodeName.toLowerCase() == "img") {
      path = event.target.parentElement.pathname;
    } else {
      path = event.target.pathname;
    }

    console.log(path, event);

    store.setIsFaderVisible(true)
    setTimeout(() => {
      store.setIsFaderShown(true)

      setTimeout(() => {
        navigate(path);
      }, 300);
    }, 100);
  }

  const onClick: MenuProps['onClick'] = (e) => {
    console.log('click ', e)
    closeMenu()
    window.location.href = e.key
  }

  const getUrlLink = (menuItem: MenuItemA) => {
    let url = ""

    if (location.pathname.includes("/summer")) {
      url = "/summer"
    } else if (location.pathname.includes("/winter")) {
      url = "/winter"
    }

    if (menuItem.url == "#") {
      url += `/${menuItem.dropdown!.items[0].url}`
    } else {
      if (menuItem.url.includes("http")) {
        url = menuItem.url
      } else {
        url += `/${menuItem.url}`
      }
    }

    return url
  }

  const getUrlPrep = (url: string) => {
    if (location.pathname.includes("/summer")) {
      return `/summer/${url}`
    } else if (location.pathname.includes("/winter")) {
      return `/winter/${url}`
    } else {
      return `/${url}`
    }
  }

  useEffect(() => {
    const onScroll = (event: any) => {
      console.log(event.target.scrollingElement.scrollTop);
      const scrollTop = event.target.scrollingElement.scrollTop;
      if (scrollTop >= 18) {
        setHeaderToggled(true);
      } else {
        setHeaderToggled(false);
      }
    }

    let txt = ``;
    (store.theme == "winter" && !location.pathname.includes("summer") ? menuItemsWin : menuItems).forEach((item) => {
      txt += `\n${item.url.toUpperCase().replaceAll("-", "_")} = '/${item.url}',`
      if (item.dropdown != null) {
        item.dropdown.items.forEach((subitem) => {
          let path = subitem.url.toUpperCase().replaceAll("-", "_");
          if (!txt.includes(path)) {
            txt += `\n${path} = '/${subitem.url}',`
          }
        })
      }
    });

    generateItems()
    
    document.addEventListener('scroll', onScroll);

    return () => {
      document.removeEventListener('scroll', onScroll);
    }
  }, []);

  return (
    <>
      <div className={styles.mob_menu + (headerToggled ? " " + styles.fixedd : "") + (store.theme == "winter" && !location.pathname.includes("summer") ? " " + styles.winter : "")} style={{
        display: isMenuShown ? "block" : "none",
        left: isMenuVisible ? "0px" : "-80%"
      }}>
        <div className={styles.mm_wrap}>
          <div className={styles.mm_w_logo}>
            <a href="/"><img src="/assets/images/logo_min.png" /></a>
          </div>
          <div className={styles.mm_w_close} onClick={() => closeMenu()}><i className="fal fa-xmark"></i></div>
          <div className={styles.mm_w_items}>
            
            {items!.length > 0 ? <Menu
              onClick={onClick}
              style={{ width: "100%" }}
              defaultSelectedKeys={['1']}
              defaultOpenKeys={['sub1']}
              mode="inline"
              items={items}
            /> : <></>}
            
          </div>
        </div>
      </div>

      <header className={styles.header + (headerToggled ? " " + styles.fixed : "") + (store.isHeaderLocked ? " " + styles.white : "") + (store.theme == "winter" && !location.pathname.includes("summer") ? " " + styles.winter : "")}>
        <div className="container">
          <div className="row">
            <div className={(width > 960 ? "col-1 col-md-2 " : "col-1 ") + styles.centere} style={{display: width > 960 ? "none" : "block"}}>
              <div className={styles.menu_open} onClick={() => openMenu()}><i className="far fa-bars"></i></div>
            </div>
            <div className={(width > 960 ? "col-3 col-md-2 " : "col-3 ") + styles.centere}>
              <div className={styles.logo}><a href="/"><img src={`/assets/images/${width > 960 ? "logo" : "logo_min"}.png`} /></a></div>
            </div>
            <div className="col-6 col-md-6" style={{display: width > 960 ? "block" : "none"}}>
              <div className={styles.menu}>

                {(store.theme == "winter" && !location.pathname.includes("summer") ? menuItemsWin : menuItems).map(menuItem => <div className={styles.menu_item}>
                  <a href={getUrlLink(menuItem)} className={styles.menu_item__title}>{menuItem.title}</a>
                  {menuItem.dropdown != null ? <div className={styles.menu_item__dropdown}>
                    <div className={styles.menu_item__dropdown_body}>
                      {menuItem.dropdown.items.map(dropItem => <a href={getUrlPrep(dropItem.url)}>{dropItem.title}</a>)}
                    </div>
                  </div> : <></>}
                </div>)}

              </div>
            </div>
            <div className={(width > 960 ? "col-8 col-md-4 " : "col-8 ") + styles.ending}>
              <div className={styles.flxd}>
                <div className={styles.socials}>
                  <a href="https://wa.me/79381311333" target="_blank"><img src="/assets/icons/social/wa.png" /></a>
                  <a href="https://vk.com/arkhyz_royal" target="_blank"><img src="/assets/icons/social/vk.png" /></a>
                  <a href="https://t.me/arkhyz_royal" target="_blank"><img src="/assets/icons/social/tg.png" /></a>
                  <a href="https://www.instagram.com/arkhyz_royal/" target="_blank"><img src="/assets/icons/social/ig.png" /></a>
                </div>

                <div className={styles.contacts}>
                  <a href="tel:+78002221027"><span><i className="fas fa-phone"></i></span>{width > 1280 ? <> 8 (800) 222-10-27</> : <></>}</a>
                  {width > 960 ? <a href="/subscribe"><img src="/assets/images/s4.png" /></a> : <></>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default observer(Header)