import { observer } from "mobx-react-lite"
import { FC, useContext, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { Context } from "../../.."
import { useFetching } from "../../../core/hooks/useFetching"

import styles from "../../../styles/Subpage.module.scss"

const Booking: FC = () => {

  const { store, adminStore } = useContext(Context)

  const location = useLocation()

  const [page, setPage] = useState(null as null | any)

  const findParsedUrl = (url: string) => {
    return url.replace("/", "")
  }
  const [fetchPage, isPageLoading, errorPage] = useFetching(async () => {
    const pagename = findParsedUrl(location.pathname)

    console.log(pagename, adminStore.pages)

    const pageEx = adminStore.pages.find(v => v.pagename == pagename)

    console.log(pageEx)

    setPage((prev: any) => prev = pageEx)
  })

  useEffect(() => {
    fetchPage()
  }, [])

  useEffect(() => {
    (function(w: any) {
      setTimeout(() => {
        var q = [
          ['setContext', 'TL-INT-royalresort-summer', 'ru'],
          ['embed', 'booking-form', {
            container: 'tl-booking-form'
          }]
        ];
        var t = w.travelline = (w.travelline || {}),
          ti = t.integration = (t.integration || {});
        ti.__cq = ti.__cq ? ti.__cq.concat(q) : q;
        if (!ti.__loader) {
          ti.__loader = true;
          var d = w.document,
            p = d.location.protocol,
            s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = (p == 'https:' ? p : 'http:') + '//ibe.tlintegration.com/integration/loader.js';
          (d.getElementsByTagName('head')[0] || d.getElementsByTagName('body')[0]).appendChild(s);
        }
      }, 500);
    })(window)
  }, [])

  return (
    page != null ? <>
      <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 style={{textAlign: "center"}}>БРОНИРОВАНИЕ</h3>
              
              <div dangerouslySetInnerHTML={{__html: page.content}}></div>
              
              <div id="tl-booking-form" className="tl-booking-form"><iframe id="tlFrame0.049391215516969345" name="tlFrame0.049391215516969345" frameBorder="0" width="100%" height="1" scrolling="no" style={{position: "relative", width: "1px", minWidth: "100%", height: "762px"}} src="https://ru-ibe.tlintegration.ru/booking2/hotel/index.20881.gc.html?int=true&amp;adults=2&amp;providerId=20881&amp;theme=20881&amp;accommodationMode=auto&amp;defaultAdults=2&amp;language=ru&amp;width=100%25&amp;tlTrackerUser=585466889217428098&amp;tlTrackerSession=628085969452947190&amp;origin=https%3A%2F%2Froyalresort.ru&amp;surl=https%3A%2F%2Fru-ibe.tlintegration.ru&amp;conId=11195723647950473&amp;contWidth=1259"></iframe></div>
            </div>
          </div>
        </div>
      </div>
    </> : <></>
  )
}

export default observer(Booking)