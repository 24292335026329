import { makeAutoObservable } from "mobx";

export default class AdminStore {

  metaData: any = {}
  roomsData: any[] = []
  pages: any[] = []
  salesData: any[] = []

  dragId: number = 0

  constructor() {
    makeAutoObservable(this)
  }

  setDragId(id: number) {
    this.dragId = id
  }

  setSalesData(val: any[]) {
    this.salesData = val
  }

  setRoomsData(val: any[]) {
    this.roomsData = val
  }

  setMetaData(val: any) {
    this.metaData = val
  }

  setPages(val: any[]) {
    this.pages = val
  }

}