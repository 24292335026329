import { observer } from "mobx-react-lite"
import { FC } from "react"

import styles from "../../../styles/Subpage.module.scss"

const Contacts: FC = () => {
  return (
    <>
      <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-5">
              <div className={styles.contacts}>
                <p><strong>Быстрое бронирование:</strong></p>
                <h4><a href="tel:+78002221027">8 (800) 222-10-27</a></h4>
                <h4><a href="tel:+79380358090">8 (938) 035-80-90</a></h4>
                <p>&nbsp;</p>
                <p><strong>Whatsapp:</strong></p>
                <h4><a href="https://wa.me/send?phone=79381311333">+7 (938) 131-13-33</a></h4>
                <p>&nbsp;</p>
                <p><strong>Электронная почта:</strong></p>
                <h4><a href="mailto:zakaz@royalresort.ru">zakaz@royalresort.ru</a><br/><br/></h4>
                <p><strong>Для корпоративных клиентов:</strong></p>
                <h4><a href="mailto:order@royalresort.ru">order@royalresort.ru</a><br/><br/></h4>
                <p><strong>Бухгалтерия:</strong></p>
                <h4><a href="mailto:account@royalresort.ru">account@royalresort.ru</a></h4>
                <p>&nbsp;</p>
                <p><strong>Наш адрес:</strong></p>
                <h4>Карачаево-Черкесская Республика, Зеленчукский район, пос. Романтик, ул. Горная, д. 7</h4>
                <p>&nbsp;</p>
                <p>Заселение:<br/><strong>15:00</strong></p>
                <p>Выезд:<br/><strong>12:00</strong></p>
                <p>&nbsp;</p>
              </div>
            </div>
            <div className="col-12 col-md-7">
              <iframe src="https://yandex.ru/map-widget/v1/?lang=ru_RU&amp;scroll=true&amp;um=constructor%3Adf3e423d421358417f0a5e4335fea55a8e3250f35f466bf5a7ae7df22d737503" frameBorder="0" allowFullScreen={true} width="100%" height="705px" style={{display: "block"}}></iframe>
            </div>
            <div className="col-12">
              <p style={{textAlign: "center", marginTop: "72px"}}><strong>Как до нас добраться?</strong></p>
              <p>Всесезонный комплекс «Архыз» расположен в Зеленчукском районе Карачаево-Черкесской Республики, в 220 км от международного аэропорта «Минеральные Воды», принимающего прямые авиарейсы со всей страны.</p>
              <p>Чтобы ваша поездка на курорт была максимально спокойной и безопасной, мы можем организовать для вас трансфер из аэропорта и обратно на комфортабельных автомобилях из собственного автопарка отеля Arkhyz Royal Resort &amp; Spa, рассчитанных на перевозку разного количества пассажиров.</p>
              <p>&nbsp;</p>
              <p style={{textAlign: "center"}}><strong>Как добраться на автомобиле?</strong></p>
              <p>Самый просто способ это воспользоваться навигатором, например если у вас стоит приложение Яндекс.Карты или Яндекс.Навигатор, то просто нажмите одну из этих кнопок:</p>

              <p style={{textAlign: "center"}}>
                <a className="btnb" href="https://yandex.ru/maps/?rtext=~43.543405%2C41.183830" target="_blank">Проложить маршрут Яндекс.Карты</a><br/>
                <a className="btnb" href="yandexnavi://build_route_on_map?lat_to=43.543405&lon_to=41.183830" target="_blank">Проложить маршрут Яндекс.Навигатор</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(Contacts)