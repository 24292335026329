import { observer } from "mobx-react-lite"
import { FC, useContext } from "react"
import {useLocation} from "react-router-dom"
import {Context} from "../../.."
import {XImage} from "../../../core/models/XImage"

import styles from "../../../styles/Subpage.module.scss"

const SPAImgs: XImage[] = [
  { url: "/assets/images/spa/s1.jpeg", title: "" },
  { url: "/assets/images/spa/s2.jpeg", title: "" },
  { url: "/assets/images/spa/s3.jpeg", title: "" },
  { url: "/assets/images/spa/s4.jpeg", title: "" },
  { url: "/assets/images/spa/s5.jpeg", title: "" },
  { url: "/assets/images/spa/s6.jpeg", title: "" },
  { url: "/assets/images/spa/s7.jpeg", title: "" },
  { url: "/assets/images/spa/s8.jpeg", title: "" },
  { url: "/assets/images/spa/s9.jpeg", title: "" },
  { url: "/assets/images/spa/s10.jpeg", title: "" },
  { url: "/assets/images/spa/s11.jpeg", title: "" },
  { url: "/assets/images/spa/s12.jpeg", title: "" }
]

const PBImgs: XImage[] = [
  { url: "/assets/images/spa/pb/pb1.jpeg", title: "" },
  { url: "/assets/images/spa/pb/pb2.jpeg", title: "" },
  { url: "/assets/images/spa/pb/pb3.jpeg", title: "" },
  { url: "/assets/images/spa/pb/pb4.jpeg", title: "" },
  { url: "/assets/images/spa/pb/pb5.jpeg", title: "" },
  { url: "/assets/images/spa/pb/pb6.jpeg", title: "" }
]

const PB2Imgs: XImage[] = [
  { url: "/assets/images/spa/pb/p1.jpeg", title: "" },
  { url: "/assets/images/spa/pb/p2.jpeg", title: "" },
  { url: "/assets/images/spa/pb/p3.jpeg", title: "" }
]

const KBImgs: XImage[] = [
  { url: "/assets/images/spa/kb/kb1.jpeg", title: "" },
  { url: "/assets/images/spa/kb/kb2.jpeg", title: "" },
  { url: "/assets/images/spa/kb/kb3.jpeg", title: "" }
]

const HMImgs: XImage[] = [
  { url: "/assets/images/spa/hm/hm1.jpeg", title: "" },
  { url: "/assets/images/spa/hm/h2.jpeg", title: "" },
  { url: "/assets/images/spa/hm/hm3.jpeg", title: "" }
]

const SKImgs: XImage[] = [
  { url: "/assets/images/spa/sk/sk1.jpeg", title: "" },
  { url: "/assets/images/spa/sk/sk2.jpeg", title: "" },
  { url: "/assets/images/spa/sk/sk3.jpeg", title: "" }
]

const FSImgs: XImage[] = [
  { url: "/assets/images/spa/fs/fs1.jpeg", title: "" },
  { url: "/assets/images/spa/fs/fs2.jpeg", title: "" },
  { url: "/assets/images/spa/fs/fs3.jpeg", title: "" }
]

const ASImgs: XImage[] = [
  { url: "/assets/images/spa/as/as1.jpeg", title: "" },
  { url: "/assets/images/spa/as/as2.jpeg", title: "" },
  { url: "/assets/images/spa/as/as3.jpeg", title: "" }
]

const MImgs: XImage[] = [
  { url: "/assets/images/spa/mass/m1.jpeg", title: "" },
  { url: "/assets/images/spa/mass/m2.jpeg", title: "" },
  { url: "/assets/images/spa/mass/m3.jpeg", title: "" },
  { url: "/assets/images/spa/mass/m4.jpeg", title: "" },
  { url: "/assets/images/spa/mass/m5.jpeg", title: "" },
  { url: "/assets/images/spa/mass/m6.jpeg", title: "" }
]

const TRImgs: XImage[] = [
  { url: "/assets/images/spa/tr/tr1.jpeg", title: "" },
  { url: "/assets/images/spa/tr/tr2.jpeg", title: "" },
  { url: "/assets/images/spa/tr/tr3.jpeg", title: "" }
]

const CSImgs: XImage[] = [
  { url: "/assets/images/spa/cs/cs1.jpg", title: "" },
  { url: "/assets/images/spa/cs/cs2.jpeg", title: "" },
  { url: "/assets/images/spa/cs/cs3.jpeg", title: "" }
]

const SPAPage: FC = () => {

  const { store } = useContext(Context)
  const location = useLocation()

  const openGalleryModal = (imgs: XImage[], index: number) => {
    store.setSelectedImages(imgs)
    store.setSelectedImageIndex(index)
    store.setIsImageViewVisible(true)
  }

  return (
    <>
      {location.pathname.includes("/cosmetology") ? <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2><strong>Косметология</strong> – стать лучшей версией себя</h2>
              
              <p>Косметология — это большой раздел медицины, разрабатывающий средства и методы улучшения внешности человека. Современная косметология способна улучшить не только качество и внешний вид кожи, но и замедлить процессы старения, сделать кожу более упругой и сияющей, осветлить пигментные пятна, скорректировать морщины и даже остановить выпадение волос, изменить форму лица — это практически альтернатива пластической хирургии.<br/><br/></p>

              <div className={styles.grid_images}>
                <div className={styles.gi_other}>
                  {CSImgs.map((image, ix) => <div style={{height: "260px", backgroundImage: `url(${image.url})`}} onClick={() => openGalleryModal(CSImgs, ix)}></div>)}
                </div>
              </div>

              <div className={styles.pt62}>
                <a href="/cosmetology.pdf" className="arhbtn arhbtn-primary">Скачать прайс</a>
              </div>
            </div>
          </div>
        </div>
      </div> : <></>}
      
      {location.pathname.includes("/fitnes") ? <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2><strong>Тренажерный зал</strong> - восстановление сил и красоты</h2>
              
              <p>Курорт «Архыз» расположен на высоте 1650-1700 метров над уровнем моря. Профессиональные спортсмены и тренеры утверждают, что это оптимальная высота для проведения занятий по общей физической подготовке, когда достигается правильный баланс между физической нагрузкой и гипоксией — пониженным содержанием кислорода в воздухе. Такие тренировки положительно влияют на выносливость, помогают держать организм в тонусе. Именно поэтому многие российские спортсмены тренируются на Северном Кавказе.</p>
              <p>Заняться полезным спортом в условиях среднегорья может и каждый гость отеля Arkhyz Royal Resort & SPA в фитнес-зале, оборудованном современными тренажерами для проведения силовых или аэробных тренировок. Проведите время с пользой на эллипсе, степпере или велосипеде, займитесь йогой, потренируйтесь с фитболом или гантелями.<br/><br/></p>
            
              <div className={styles.grid_images}>
                <div className={styles.gi_other}>
                  {TRImgs.map((image, ix) => <div style={{height: "260px", backgroundImage: `url(${image.url})`}} onClick={() => openGalleryModal(TRImgs, ix)}></div>)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> : <></>}
      
      {location.pathname.includes("/massage") ? <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2><strong>Массажный кабинет</strong> - здоровье ручной работы</h2>
              
              <p>
                Посещение массажного кабинета SPA-комплекса «Термаль» - это прекрасный способ снять физическое и нервное напряжение, очистить организм, привести мышцы в тонус, вернуть себе душевное спокойствие. <br/>
                Наши опытные специалисты, владеющие техникой классического лечебного массажа, помогут избавиться от болевых ощущений в проблемных зонах, с которыми так легко столкнуться в жизни мегаполисов. Мы также готовы предложить вам расслабляющий и тонизирующий массаж, в том числе основанный на тайских и китайских методиках взаимодействия с телом.<br/>
                Обратите внимание и на специальные программы - SPA-комплекс «Термаль» предлагает услуги детского массажа, чтобы отдых на «Архызе» запомнился нашим маленьким гостям как яркое приключение, полное радости и веселья.<br/><br/>
              </p>

              <div className={styles.grid_images}>
                <div className={styles.gi_other}>
                  {MImgs.map((image, ix) => <div style={{height: "260px", backgroundImage: `url(${image.url})`}} onClick={() => openGalleryModal(MImgs, ix)}></div>)}
                </div>
              </div>

              <div className={styles.pt62}>
                <a href="/cosmetology.pdf" className="arhbtn arhbtn-primary">Скачать прайс</a>
              </div>
            </div>
          </div>
        </div>
      </div> : <></>}
      
      {location.pathname.includes("/aroma-sauna") ? <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2><strong>Арома-сауна</strong> - оздоровление с приятным ароматом</h2>
              
              <p>Качественное воздействие эфирных масел на организм человека исследовано достаточно давно. Эффект от посещения происходит комплексно: кожа распаривается от сухого горячего пара, а в расширенные поры проходят полезные вещества, которые положительно воздействуют на ваш организм.<br/><br/></p>

              <div className={styles.grid_images}>
                <div className={styles.gi_other}>
                  {ASImgs.map((image, ix) => <div style={{height: "260px", backgroundImage: `url(${image.url})`}} onClick={() => openGalleryModal(ASImgs, ix)}></div>)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> : <></>}
      
      {location.pathname.includes("/finskaya-sauna") ? <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2><strong>Финская сауна</strong> - воздух, который оздоравливает</h2>
              
              <p>
                Полная релаксация и гармония души и тела – то, что нужно и зимой и летом!<br/>
                Финская сауна в отеле Arkhyz Royal Resort & SPA оказывает благотворное влияние на организм и помогает восстановить силы.<br/><br/>
              </p>

              <div className={styles.grid_images}>
                <div className={styles.gi_other}>
                  {FSImgs.map((image, ix) => <div style={{height: "260px", backgroundImage: `url(${image.url})`}} onClick={() => openGalleryModal(FSImgs, ix)}></div>)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> : <></>}

      {location.pathname.includes("/solyanaya-komnata") ? <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2><strong>Соляная комната</strong> - с заботой о дыхании</h2>
              
              <p>Посещение соляной комнаты позволяет насыщать клетки полезными веществами и ионами, которые восстанавливают функцию всех внутренних органов, в частности легких и дыхательных путей. Вы сможете избавиться от вирусов и инфекций за небольшое проведенное время в такой комнате.<br/><br/></p>

              <div className={styles.grid_images}>
                <div className={styles.gi_other}>
                  {SKImgs.map((image, ix) => <div style={{height: "260px", backgroundImage: `url(${image.url})`}} onClick={() => openGalleryModal(SKImgs, ix)}></div>)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> : <></>}
      
      {location.pathname.includes("/hammam") ? <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2><strong>Хаммам</strong> - кусочек Турции в Архызе</h2>
              
              <p>Хаммам — тип традиционной турецкой бани, отличающийся невысоким температурным режимом: 35–50 градусов Цельсия. Конструкция предназначена для прогревания позвоночника, мышц и суставов.<br/><br/></p>

              <div className={styles.grid_images}>
                <div className={styles.gi_other}>
                  {HMImgs.map((image, ix) => <div style={{height: "260px", backgroundImage: `url(${image.url})`}} onClick={() => openGalleryModal(HMImgs, ix)}></div>)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> : <></>}

      {location.pathname.includes("/kritiy-bassein") ? <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2><strong>Крытый бассейн</strong> - оздоровление и релаксация</h2>
              
              <p>Крытый бассейн Arkhyz Royal Resort & SPA станет лучшим выбором для вашего роскошного отдыха. Расслабляющая музыка, просторная зона отдыха с шезлонгами - то, что позволит забыть о городской суете.<br/><br/></p>
              
              <div className={styles.grid_images}>
                <div className={styles.gi_other}>
                  {KBImgs.map((image, ix) => <div style={{height: "260px", backgroundImage: `url(${image.url})`}} onClick={() => openGalleryModal(KBImgs, ix)}></div>)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> : <></>}
      
      {location.pathname.includes("/panoramniy-bassein") ? <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2><strong>Панорамный подогреваемый бассейн</strong> - один из самых красивых видовых бассейнов России</h2>
              
              <p>Открытый бассейн - отличный вариант для пляжного отдыха в горах! На территории отеля Arkhyz Royal Resort & SPA 5* функционирует всесезонный панорамный бассейн, подогреваемый в холодное время года. Его бесспорно можно назвать визитной карточкой отеля.</p>
              <p>Умопомрачительный вид на Кавказский хребет и комфортная температура радует наших гостей круглый год. А приятный сервис и освежающие напитки будут всегда рядом.<br/><br/></p>

              <div className={styles.grid_images}>
                <div className={styles.gi_other}>
                  {PBImgs.map((image, ix) => <div style={{height: "260px", backgroundImage: `url(${image.url})`}} onClick={() => openGalleryModal(PBImgs, ix)}></div>)}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div> : <></>}
      
      {location.pathname.includes("/spa") ? <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>РЕЛАКС И УМИРОТВОРЕНИЕ — SPA-ЦЕНТР «ТЕРМАЛЬ»</h2>
              
              <p>Клубный SPA-комплекс с 2-мя бассейными, один из которых расположен под открытым небом с «живой панорамой» Северного Кавказа, позволяет ощутить себя частью природы, слиться с горными красотами Архыза вне зависимости от времени года, пусть то зима, или лето!</p>
              <p>Комплекс бань, услуги первоклассного массажа, кедровая бочка, соляная пещера, обертывание и другие современные процедуры, направленные на восстановление жизненных сил, поднятие тонуса - достойно поддерживают концепцию качества жизни современного человека. Ждем Вас в нашем SPA-комплексе!<br/><br/></p>
              
              <div className={styles.grid_images}>
                <div className={styles.gi_other}>
                  {SPAImgs.map((image, ix) => <div style={{height: "260px", backgroundImage: `url(${image.url})`}} onClick={() => openGalleryModal(SPAImgs, ix)}></div>)}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div> : <></>}
    </>
  )
}

export default observer(SPAPage)