import { observer } from "mobx-react-lite";
import React, { FC, useContext, useEffect } from "react"
import { Route, Routes } from "react-router-dom"
import { Context } from "..";
import { summerRoutes, winterRoutes } from "../core/router"

const AppRouter: FC = () => {
  const { store } = useContext(Context)

  useEffect(() => {
    console.log(store.backTheme)
  }, [store.backTheme])

  return (
    <React.Suspense fallback={<><div className="loading">{/* <LoadingOutlined rev={null} spin style={{fontSize: "42px", color: "#fff"}} /> */}</div></>}>
      <Routes>
        {store.backTheme != "" ? (store.backTheme == "winter" ? winterRoutes.map(route => 
          <Route
            path={route.path}
            element={route.component}
            key={route.path}
            />
        ) : summerRoutes.map(route => 
          <Route
            path={route.path}
            element={route.component}
            key={route.path}
            />
        )) : <></>}
      </Routes>
    </React.Suspense>
  )
}

export default observer(AppRouter)