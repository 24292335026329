import { observer } from "mobx-react-lite"
import { FC, useContext, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { Context } from "../../.."
import { useFetching } from "../../../core/hooks/useFetching"

import styles from "../../../styles/Subpage.module.scss"

const Contacts: FC = () => {

  const { store, adminStore } = useContext(Context)

  const location = useLocation()

  const [page, setPage] = useState(null as null | any)

  const findParsedUrl = (url: string) => {
    return url.replace("/", "")
  }
  const [fetchPage, isPageLoading, errorPage] = useFetching(async () => {
    const pagename = findParsedUrl(location.pathname)

    console.log(pagename, adminStore.pages)

    const pageEx = adminStore.pages.find(v => v.pagename == pagename)

    console.log(pageEx)

    setPage((prev: any) => prev = pageEx)
  })

  useEffect(() => {
    fetchPage()
  }, [])
  
  return (
    page != null ? <>
      <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-5">
              <div className={styles.contacts} dangerouslySetInnerHTML={{__html: page.content}}></div>
            </div>
            <div className="col-12 col-md-7">
              <iframe src="https://yandex.ru/map-widget/v1/?lang=ru_RU&amp;scroll=true&amp;um=constructor%3Adf3e423d421358417f0a5e4335fea55a8e3250f35f466bf5a7ae7df22d737503" frameBorder="0" allowFullScreen={true} width="100%" height="705px" style={{display: "block"}}></iframe>
            </div>
            <div className="col-12" style={{marginTop: "72px"}}>
              <div dangerouslySetInnerHTML={{__html: page.data.content2}}></div>

              <p style={{textAlign: "center"}}>
                <a className="btnb" href="https://yandex.ru/maps/?rtext=~43.543405%2C41.183830" target="_blank">Проложить маршрут Яндекс.Карты</a><br/>
                <a className="btnb" href="yandexnavi://build_route_on_map?lat_to=43.543405&lon_to=41.183830" target="_blank">Проложить маршрут Яндекс.Навигатор</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </> : <></>
  )
}

export default observer(Contacts)