import { observer } from "mobx-react-lite"
import { FC } from "react"

import styles from "../../../styles/Subpage.module.scss"

const Transfer: FC = () => {
  return (
    <>
      <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2><strong>Трансфер</strong> - любовь к гостю во всем!</h2>
              
              <div className={styles.flexed + " " + styles.flxd}>
                <div><img src="/assets/images/subpages/trs1.jpg" /></div>
                <div>
                  <p>Чтобы ваша поездка на курорт была максимально спокойной и безопасной, предлагаем воспользоваться услугами трансфера из аэропорта и обратно на комфортабельных автомобилях из собственного автопарка отеля Arkhyz Royal Resort &amp; SPA.<br/><br/>Трансфер на легковых автомобилях Kia Optima и минивенах Hyundai. Провоз горнолыжного снаряжения, детские кресла и вода в дорогу предоставляются бесплатно.<br/><br/></p>
                  <p>Стоимость указана за автомобиль (на 3-7 чел) в одну сторону.</p>
                </div>
              </div>

              <p style={{textAlign: "center", marginTop: "64px", marginBottom: "24px"}}><strong>ТАРИФЫ на услуги трансфера</strong></p>
              
              <table width="546">
                <tbody>
                  <tr>
                    <td width="414"><strong>Направление</strong></td>
                    <td width="132"><strong>Стоимость за машину (руб)</strong></td>
                  </tr>
                  <tr>
                    <td width="414">Ставрополь</td>
                    <td width="132">10 000</td>
                  </tr>
                  <tr>
                    <td width="414">Минеральные воды</td>
                    <td width="132">9 000</td>
                  </tr>
                  <tr>
                    <td width="414">Пятигорск, Кисловодск, Есентуки, Невинномысск</td>
                    <td width="132">9 000</td>
                  </tr>
                  <tr>
                    <td width="414">Черкесск</td>
                    <td width="132">5 000</td>
                  </tr>
                </tbody>
              </table>

              <p style={{marginBottom: "96px"}}>*Ожидание в пункте назначения – 500 р/час</p>
              
              <div className={styles.flexed + " " + styles.reversed + " " + styles.flxd}>
                <div>
                  <h3 style={{textAlign: "center"}}>VIP трансфер</h3>
                  <p>Для гостей, ценящих особый комфорт, мы предлагаем трансфер на минивене Toyota Alphard из собственного автопарка отеля. Роскошный и тихий салон, капитанские кресла первого и второго ряда OTTOMAN с электрорегулировкой в шести направлениях и профессиональный вежливый водитель превратят дорогу в удовольствие.</p>
                  <p>Провоз горнолыжного снаряжения, детские кресла и вода в дорогу предоставляются бесплатно.</p>
                  <p>Стоимость указана за машину (3-5 пассажиров) в одну сторону.</p>
                  <br/>
                  <p>✔️ капитанские кресла первого и второго ряда OTTOMAN с электрорегулировкой в шести направлениях;<br/>✔️ трехзонный климат-контроль и система ионизации воздуха;<br/>✔️ USB-разъемы для зарядки Ваших гаджетов;<br/>✔️ LED-монитор для просмотра кино или мультфильмов;<br/>✔️ акустическая система премиум-класса.</p>
                </div>
                <div><img src="/assets/images/subpages/trs2.jpg" /></div>
              </div>

              <p style={{textAlign: "center", marginTop: "74px", marginBottom: "24px"}}><strong>ТАРИФЫ на услуги VIP трансфера</strong></p>
              
              <table width="546">
                <tbody>
                  <tr>
                    <td width="414"><strong>Направление</strong></td>
                    <td width="132"><strong>Стоимость за машину (руб)</strong></td>
                  </tr>
                  <tr>
                    <td width="414">Ставрополь</td>
                    <td width="132">13 000</td>
                  </tr>
                  <tr>
                    <td width="414">Минеральные воды</td>
                    <td width="132">12 000</td>
                  </tr>
                  <tr>
                    <td width="414">Пятигорск, Кисловодск, Есентуки, Невинномысск</td>
                    <td width="132">12 000</td>
                  </tr>
                  <tr>
                    <td width="414">Черкесск</td>
                    <td width="132">7 000</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(Transfer)