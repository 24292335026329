import {API_URL, ControllerName} from "../utils/defaults";
import $api from "./http";

export class ApiService {

  constructor() {}

  static users = {
    signIn: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.USERS, `signin`), dto)
    },
    changeAdminPwd: async (new_pwd: string) => {
      return await $api.post(this.buildUrl(ControllerName.USERS, `change_admin_pwd`), {new_pwd})
    }
  }

  static pages = {
    getList: async () => {
      return await $api.get(this.buildUrl(ControllerName.PAGES, `list`))
    },
    getPage: async (name: string) => {
      return await $api.get(this.buildUrl(ControllerName.PAGES, `list/${name}`))
    },
    create: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.PAGES, `list`), dto)
    },
    update: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.PAGES, `list/${id}`), dto)
    },
    delete: async (id: number) => {
      return await $api.delete(this.buildUrl(ControllerName.PAGES, `list/${id}`))
    }
  }

  static core = {
    getSettings: async () => {
      return await $api.get(this.buildUrl(ControllerName.CORE, `settings`))
    },
    updateSetting: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.CORE, `settings/${id}`), dto)
    },
    getImageRes: async (filename: string) => {
      return await $api.get(this.buildUrl(ControllerName.CORE, `image/${filename}`))
    }
  }

  static versions = {
    getVersions: async () => {
      return await $api.get(this.buildUrl(ControllerName.CORE, `versions`));
    },
    getLatestVersion: async () => {
      return await $api.get(this.buildUrl(ControllerName.CORE, `versions/latest`));
    },
    createVersion: async () => {},
    updateVersion: async () => {},
    deleteVersion: async () => {}
  }

  static buildUrl(
    controller: ControllerName,
    method: string
  ) {
    return `${API_URL}${controller}/${method}`
  }

  static buildParams(map: any) {
    let query = "";
    for (let key in map) {
      if (query == "") {
        query = `?${key}=${map[key]}`;
      } else {
        query += `&${key}=${map[key]}`;
      }
    }
    return query;
  }
}