import { observer } from "mobx-react-lite"
import { FC, useContext, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { Context } from ".."
import styles from "../styles/ThemeSwitcher.module.scss"

const ThemeSwitcher: FC = () => {

  const { store } = useContext(Context)

  const location = useLocation()

  useEffect(() => {
    
  }, [])

  return (
    <>
      <div className={styles.theme_switcher + (store.theme == "winter" && !location.pathname.includes("/summer") ? " " + styles.winter : "")}>
        <div className={store.theme == "winter" && !location.pathname.includes("/summer") ? styles.active : ""} onClick={() => {
          window.location.href = "/"
        }}><i className="fal fa-snowflake"></i></div>
        <div className={store.theme == "summer" || location.pathname.includes("/summer") ? styles.active : ""} onClick={() => {
          window.location.href = "/summer"
        }}><i className="fas fa-sun"></i></div>
      </div>
    </>
  )
}

export default observer(ThemeSwitcher)