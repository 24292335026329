import {DeleteOutlined} from "@ant-design/icons"
import {Editor} from "@tinymce/tinymce-react"
import {message, Switch, Upload, UploadProps} from "antd"
import imageCompression from "browser-image-compression"
import { observer } from "mobx-react-lite"
import { FC, useContext, useState, useEffect, useRef } from "react"
import { useLocation } from "react-router-dom"
import { Context } from "../../.."
import {ApiService} from "../../../core/api/api"
import {useFetching} from "../../../core/hooks/useFetching"
import {XImage} from "../../../core/models/XImage"
import {IXImages, IXPage} from "../../../core/models/XPage"
import {API_URL} from "../../../core/utils/defaults"
import {sortById, translit} from "../../../core/utils/utils"

import styles from "../../../styles/AdminManager.module.scss"

const PagesManager: FC = () => {

  const { store, adminStore } = useContext(Context)
  const location = useLocation()

  const editorRef = useRef(null as any)
  const editorRef2 = useRef(null as any)
  const editorRef3 = useRef(null as any)
  const editorRef4 = useRef(null as any)
  
  const [selectedPage, setSelectedPage] = useState(null as null | IXPage)
  const [pageTitle, setPageTitle] = useState("")
  const [pageContent, setPageContent] = useState("")
  const [pageContentT, setPageContentT] = useState("")
  const [pageContent2, setPageContent2] = useState("")
  const [pageContentT2, setPageContentT2] = useState("")
  const [pageContent3, setPageContent3] = useState("")
  const [pageContentT3, setPageContentT3] = useState("")
  const [pageContent4, setPageContent4] = useState("")
  const [pageContentT4, setPageContentT4] = useState("")
  const [pageName, setPageName] = useState("")
  const [pagePagename, setPagePagename] = useState("")
  const [pageGroup, setPageGroup] = useState("")
  const [pageStatus, setPageStatus] = useState(0)
  const [pageContactForm, setPageContactForm] = useState(0)
  const [pageImages, setPageImages] = useState(null as IXImages[] | null)
  const [pageData, setPageData] = useState(null as any)

  const [activeTab, setActiveTab] = useState(1)
  const [activeTabEd, setActiveTabEd] = useState(1)
  const [isDataLoading, setIsDataLoading] = useState(false)

  const props3: UploadProps = {
    name: 'images',
    multiple: false,
    maxCount: 1,
    showUploadList: false,
    headers: {
      "Authorization": "Bearer " + store.token
    },
    action: `${API_URL}/core/uploader/images`,
    beforeUpload: async (file, FileList) => {
      setIsDataLoading(prev => prev = true);
      const options = { maxSizeMB: 0.8, maxWidthOrHeight: 1920, useWebWorker: true }
      try {
        const compressedFile = await imageCompression(file, options);
        console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
        console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
        return compressedFile;
      } catch (error) {
        console.log(error);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  }

  const [fetchPages, isPagesLoading] = useFetching(async () => {
    const res = await ApiService.pages.getList()

    console.log(res)

    adminStore.setPages(res.data.pages)
  })

  const selectPage = (page: IXPage) => {
    setPageTitle(page.title!)
    setPageContent(page.content!)
    if (page.data != null) {
      setPageContent2(page.data.content2!)
      setPageContent3(page.data.content3!)
      setPageContent4(page.data.content4!)
    }
    setPageName(page.name!)
    setPagePagename(page.pagename!)
    setPageGroup(page.group!)
    setPageStatus(page.status!)
    setPageContactForm(page.contact_form!)
    setPageImages(page.images!)
    setPageData(page.data!)
    setSelectedPage(page)
  }

  const getFilteredItems = () => {
    if (activeTab == 1) {
      return adminStore.pages.filter(v => v.group == "about")
    } else if (activeTab == 2) {
      return adminStore.pages.filter(v => v.group == "restoran")
    } else if (activeTab == 3) {
      return adminStore.pages.filter(v => v.group == "spa")
    } else if (activeTab == 4) {
      return adminStore.pages.filter(v => v.group == "kids")
    } else if (activeTab == 5) {
      return adminStore.pages.filter(v => v.group == "horses")
    } else if (activeTab == 6) {
      return adminStore.pages.filter(v => v.group == "entertmnt")
    } else if (activeTab == 7) {
      return adminStore.pages.filter(v => v.group == "events")
    } else {
      return adminStore.pages
    }
  }

  const addImagesBlock = () => {
    setPageImages(prev => prev = [...prev!, {
      height: "260px",
      items: []
    } as IXImages])
  }

  const deleteImagesBlock = (ix: number) => {
    setPageImages(prev => prev = [...prev!.filter((v, i) => i != ix)])
  }

  const addNewPageAction = async () => {}

  const savePageAction = async () => {
    console.log(editorRef.current.getContent())
    if (selectedPage != null) {
      let data: any = {
        title: pageTitle,
        content: editorRef.current.getContent(),
        data: null,
        pagename: pagePagename,
        group: pageGroup,
        status: pageStatus,
        contact_form: pageContactForm,
        images: pageImages
      }
      if (selectedPage.pagename == "about-us" || selectedPage.pagename == "contacts" || selectedPage.pagename == "transfer") {
        data["data"] = {
          content2: editorRef2.current.getContent(),
          ...(selectedPage.pagename == "transfer" ? {
            content3: editorRef3.current.getContent(),
            content4: editorRef4.current.getContent()
          } : {}),
        }
      }
      const res = await ApiService.pages.update(selectedPage.id!, data)

      console.log(res)
      
      message.success("Страница успешно обновлена!")
    } else {
      message.error("Для начала выберите страницу...")
    }
  }

  const openGalleryModal = (imgs: XImage[], index: number) => {
    store.setSelectedImages(imgs)
    store.setSelectedImageIndex(index)
    store.setIsImageViewVisible(true)
  }

  const isDataChanged = () => {
    if (
      selectedPage != null &&
      (
        pageTitle != selectedPage.title ||
        pageContentT != selectedPage.content ||
        pageName != selectedPage.name ||
        pagePagename != selectedPage.pagename ||
        pageGroup != selectedPage.group ||
        pageStatus != selectedPage.status ||
        pageContactForm != selectedPage.contact_form
      )
    ) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    fetchPages()
  }, [])

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className={styles.grid_block}>
              <h3><span style={{
                display: selectedPage != null ? "inline-block" : "none"
              }} onClick={() => setSelectedPage(prev => prev = null)}><i className="far fa-arrow-left-long"></i> </span>{selectedPage == null ? "Список страниц" : "Редактирование страницы"}</h3>

              {selectedPage == null ? <>

                <div className={styles.xtabs + " " + styles.minimized + " " + styles[`active${activeTab}`]}>
                  <div className={styles.xtabs_item} onClick={() => setActiveTab(prev => prev = 1)}>О нас</div>
                  <div className={styles.xtabs_item} onClick={() => setActiveTab(prev => prev = 2)}>Рестораны</div>
                  <div className={styles.xtabs_item} onClick={() => setActiveTab(prev => prev = 3)}>SPA</div>
                  <div className={styles.xtabs_item} onClick={() => setActiveTab(prev => prev = 4)}>Детям</div>
                  <div className={styles.xtabs_item} onClick={() => setActiveTab(prev => prev = 5)}>Конный клуб</div>
                  <div className={styles.xtabs_item} onClick={() => setActiveTab(prev => prev = 6)}>Развлечения</div>
                  <div className={styles.xtabs_item} onClick={() => setActiveTab(prev => prev = 7)}>Мероприятия</div>
                </div>

                <div className={styles.xtable_wrap}>
                  <div className={styles.xtable}>
                    <div className={styles.xtable_head}>
                      <div>ID</div>
                      <div>Наименование</div>
                      <div>URL</div>
                      <div>Группа</div>
                      <div>Действия</div>
                    </div>
                    <div className={styles.xtable_body}>
                      {getFilteredItems().sort(sortById).map((item: IXPage) => <div className={styles.xlist_item} onClick={() => selectPage(item)}>
                        <div className={styles.xlist_item__id}>{item.id}</div>
                        <div className={styles.xlist_item__name}><span>{item.name}</span></div>
                        <div className={styles.xlist_item__pagename}>{item.pagename}</div>
                        <div className={styles.xlist_item__group}>{item.group}</div>
                        <div className={styles.xlist_item__actions}>
                          <button><i className="far fa-pen-to-square"></i></button>
                        </div>
                      </div>)}
                    </div>
                  </div>
                </div>
              </> : <>
                <div className={styles.xactions}>
                  <div className={styles.xactions_item + " " + styles.success} onClick={() => savePageAction()}>Cохранить</div>
                </div>
                
                <div className="row">
                  <div className="col-12 col-md-9">

                    <div className={styles.xtabs + " " + styles.minimized + " " + styles[`active${activeTabEd}`]}>
                      <div className={styles.xtabs_item} onClick={() => setActiveTabEd(prev => prev = 1)}>Основное</div>
                      <div className={styles.xtabs_item} onClick={() => setActiveTabEd(prev => prev = 2)}>Изображения</div>
                    </div>

                    <div className={styles.xform} style={{display: activeTabEd == 1 ? "block" : "none"}}>
                      <div className={styles.xform_input}>
                        <label htmlFor="sale_title">Заголовок</label>
                        <input type="text" id="sale_title" placeholder="Заголовок акции" value={pageTitle} onChange={(event) => {
                          setPageTitle(prev => prev = event.target.value)
                        }} />
                      </div>
                      <div className={styles.flexed}>
                        <div className={styles.xform_input}>
                          <label htmlFor="sale_title">URL страницы</label>
                          <input type="text" id="sale_title" placeholder="URL страницы" value={pagePagename} onChange={(event) => setPagePagename(prev => prev = event.target.value)} />
                        </div>
                        <div className={styles.xform_input}>
                          <label htmlFor="sale_title">Группа страницы</label>
                          <div className={styles.xselect + " " + styles.disabled}>
                            <select name="group_sel" id="group_sel" value={pageGroup} onChange={(event) => {
                              setPageGroup(event.target.value)
                            }}>
                              <option value="restoran">Рестораны</option>
                              <option value="spa">SPA</option>
                              <option value="kids">Детям</option>
                              <option value="horses">Конный клуб</option>
                              <option value="entertmnt">Развлечения</option>
                              <option value="events">Мероприятия</option>
                            </select>
                          </div>
                        </div>
                        <div className={styles.xform_input}>
                          <label>Статус страницы</label>
                          <Switch defaultChecked={pageStatus == 1} onChange={(event) => {setPageStatus(prev => prev = event ? 1 : 0)}} /> {pageStatus == 1 ? "Активна" : "Отключена"}
                        </div>
                        <div className={styles.xform_input}>
                          <label>Обратная связь</label>
                          <Switch defaultChecked={pageContactForm == 1} onChange={(event) => {setPageContactForm(prev => prev = event ? 1 : 0)}} /> {pageContactForm == 1 ? "Активна" : "Отключена"}
                        </div>
                      </div>
                      <div className={styles.xform_input}>
                        <label htmlFor="sale_title">Контент</label>
                        <Editor
                          onInit={(evt, editor) => editorRef.current = editor}
                          initialValue={pageContent}
                          onEditorChange={(content: string) => {
                            setPageContentT(prev => prev = content)
                          }}
                          apiKey="ez24admnrtvwwg51bfav53892ae99r6lwfqz058ayemt3a42"
                          init={{
                            height: selectedPage.pagename == "about-us" || selectedPage.pagename == "contacts" || selectedPage.pagename == "transfer" ? 300 : 400,
                            menubar: true,
                            language: "ru",
                            plugins: [
                              'autolink', 'lists','link','image','charmap','preview','anchor','searchreplace','visualblocks',
                              'fullscreen','insertdatetime','media','table','help','wordcount'
                            ],
                            toolbar: 'undo redo | formatselect | ' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'removeformat | help',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; color: #000;}'
                          }}
                        />
                      </div>

                      {selectedPage.pagename == "about-us" || selectedPage.pagename == "contacts" || selectedPage.pagename == "transfer" ? <div className={styles.xform_input}>
                        <label htmlFor="sale_title">Контент 2</label>
                        <Editor
                          onInit={(evt, editor) => editorRef2.current = editor}
                          initialValue={pageContent2}
                          onEditorChange={(content: string) => {
                            setPageContentT2(prev => prev = content)
                          }}
                          apiKey="ez24admnrtvwwg51bfav53892ae99r6lwfqz058ayemt3a42"
                          init={{
                            height: 300,
                            menubar: true,
                            language: "ru",
                            plugins: [
                              'autolink', 'lists','link','image','charmap','preview','anchor','searchreplace','visualblocks',
                              'fullscreen','insertdatetime','media','table','help','wordcount', 'code'
                            ],
                            toolbar: 'undo redo | formatselect | ' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'removeformat | help | code',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; color: #000;}'
                          }}
                        />
                      </div> : <></>}

                      {selectedPage.pagename == "transfer" ? <>
                        <div className={styles.xform_input}>
                          <label htmlFor="sale_title">Контент 3</label>
                          <Editor
                            onInit={(evt, editor) => editorRef3.current = editor}
                            initialValue={pageContent3}
                            onEditorChange={(content: string) => {
                              setPageContentT3(prev => prev = content)
                            }}
                            apiKey="ez24admnrtvwwg51bfav53892ae99r6lwfqz058ayemt3a42"
                            init={{
                              height: 300,
                              menubar: true,
                              language: "ru",
                              plugins: [
                                'autolink', 'lists','link','image','charmap','preview','anchor','searchreplace','visualblocks',
                                'fullscreen','insertdatetime','media','table','help','wordcount', 'code'
                              ],
                              toolbar: 'undo redo | formatselect | ' +
                              'bold italic backcolor | alignleft aligncenter ' +
                              'alignright alignjustify | bullist numlist outdent indent | ' +
                              'removeformat | help | code',
                              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; color: #000;}'
                            }}
                          />
                        </div>
                        <div className={styles.xform_input}>
                          <label htmlFor="sale_title">Контент 4</label>
                          <Editor
                            onInit={(evt, editor) => editorRef4.current = editor}
                            initialValue={pageContent4}
                            onEditorChange={(content: string) => {
                              setPageContentT4(prev => prev = content)
                            }}
                            apiKey="ez24admnrtvwwg51bfav53892ae99r6lwfqz058ayemt3a42"
                            init={{
                              height: 300,
                              menubar: true,
                              language: "ru",
                              plugins: [
                                'autolink', 'lists','link','image','charmap','preview','anchor','searchreplace','visualblocks',
                                'fullscreen','insertdatetime','media','table','help','wordcount', 'code'
                              ],
                              toolbar: 'undo redo | formatselect | ' +
                              'bold italic backcolor | alignleft aligncenter ' +
                              'alignright alignjustify | bullist numlist outdent indent | ' +
                              'removeformat | help | code',
                              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; color: #000;}'
                            }}
                          />
                        </div>
                      </> : <></>}
                    </div>
                    
                    <div className={styles.xform} style={{display: activeTabEd == 2 ? "block" : "none"}}>
                      <div className={styles.xform_input}>
                        <label htmlFor="sale_title">Изображения</label>
                        <div className={styles.ximages}>
                          <div className={styles.ximages_add}><button onClick={() => addImagesBlock()}>Добавить блок</button></div>
                          {pageImages != null ? pageImages.map((imagesBlock: IXImages, ix: number) => <div className={styles.ximages_block}>
                            <div className={styles.ximages_delete} onClick={() => deleteImagesBlock(ix)}>Удалить блок</div>
                            <label>Высота изображений</label>
                            <input type="text" placeholder="" value={imagesBlock.height} onChange={(event) => {
                              setPageImages(prev => {
                                if (prev != null) {
                                  let newImages: any[] = []
                                  prev.forEach((im: IXImages, ixx: number) => {
                                    let newIm: any = {...im}
                                    if (ix == ixx) {
                                      newIm.height = event.target.value
                                    }
                                    newImages.push(newIm)
                                  });
                                  return newImages
                                } else {
                                  return null
                                }
                              })
                            }} />
                            <Upload {...props3} onChange={(info) => {
                              const { status } = info.file;
                              if (status === 'done') {
                                message.success(`Файл успешно загружен!`);
                                console.log(info.file.response)
                                setPageImages(prev => {
                                  let lst: IXImages[] = []

                                  if (prev != null) {
                                    prev.forEach((el, ixy) => {
                                      if (ixy == ix) {
                                        el.items.push({title: "", url: info.file.response.urls[0]})
                                      }
                                      lst.push(el)
                                    })
                                  }

                                  return lst
                                })
                              }
                            }} style={{width: "100%"}}>
                              <div className={styles.xuploader}>
                                <div className={styles.xupload_data}>
                                  <h4>Изображения</h4>
                                  <p>Нажмите или перенесите файл в эту область,<br/>в формате <b>PNG</b> / <b>JPG</b> / <b>WEBP</b></p>
                                </div>
                                <div className={styles.xupload_items}>
                                  {imagesBlock.items.map((image, ixxx) => <div className={styles.xupload_image} onClick={(event) => {
                                    event.preventDefault()
                                    event.stopPropagation()
                                    openGalleryModal(imagesBlock.items.map(v => {
                                      return {
                                        url: v.url, title: ""
                                      }
                                    }), ixxx)
                                  }} style={{backgroundImage: `url(${image.url})`}}>
                                    <div className={styles.delete} onClick={(event) => {
                                      event.preventDefault()
                                      event.stopPropagation()
                                      setPageImages(prev => {
                                        if (prev != null) {
                                          let newImages: any[] = []
                                          prev.forEach((im: IXImages, ixxxx: number) => {
                                            let newIm: any = {...im}
                                            if (ix == ixxxx) {
                                              newIm.items = newIm.items.filter((v: any) => v.url != image.url)
                                            }
                                            newImages.push(newIm)
                                          });
                                          return newImages
                                        } else {
                                          return null
                                        }
                                      })
                                    }}><i className="far fa-xmark"></i></div>
                                  </div>)}
                                </div>
                              </div>
                            </Upload>
                          </div>) : <></>}
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </>}

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(PagesManager)