import { ReactElement } from "react";

import AboutUs from "../../pages/summer/components/AboutUs"
import UsloviyaProzhiv from "../../pages/summer/components/UsloviyaProzhiv"
import Reviews from "../../pages/summer/components/Reviews"
import Transfer from "../../pages/summer/components/Transfer"
import Booking from "../../pages/summer/components/Booking"
import Contacts from "../../pages/summer/components/Contacts"

import NashiNomera from "../../pages/summer/components/Rooms/NashiNomera"
import RoomPage from "../../pages/summer/components/Rooms/RoomPage"
import Promo from "../../pages/summer/components/Promos/Promo";
import Restoran from "../../pages/summer/components/Restoran";

import SPAPage from "../../pages/summer/components/SPA"
import Kids from "../../pages/summer/components/Kids";
import Horses from "../../pages/summer/components/Horses";
import Entertmnt from "../../pages/summer/components/Entertmnt";
import Events from "../../pages/summer/components/Events";

export interface MenuItem {
  url: string
  component?: ReactElement | null
  title: string
  desc?: string
  dropdown?: null | MenuItemDropdown

  priority?: number
  change?: "always" | "weekly" | "hourly" | "daily"
}

export interface MenuItemDropdown {
  items: MenuSubItem[]
}

export interface MenuSubItem {
  image?: string
  images?: string[]
  images_hidden?: string[]
  url: string
  title: string
  desc?: string
  desc2?: string
  component?: ReactElement | null

  priority?: number
  change?: "always" | "weekly" | "hourly" | "daily"

  size?: string
  rooms?: number
  mans?: string
  text?: string
}

export const roomsCompiled: MenuSubItem[] = [
  {
    images: [
      "/assets/images/subpages/rooms/delux/1.jpg",
      "/assets/images/subpages/rooms/delux/2.jpeg",
      "/assets/images/subpages/rooms/delux/3.jpeg",
      "/assets/images/subpages/rooms/delux/4.jpeg",
      "/assets/images/subpages/rooms/delux/5.jpeg",
      "/assets/images/subpages/rooms/delux/6.jpeg"
    ], 
    "url":"deluxe-twin-king",
    "title":"Deluxe", 
    desc2: "Без дополнительных мест",
    desc: "Стандартный однокомнатный номер с балконом, 1 кроватью king-size или 2 кроватями twin.<br/><b>Без дополнительных мест.</b>",
    size: "24",
    rooms: 1, mans: "2",
    text: "Стандартный однокомнатный номер площадью 24 кв.м с балконом, с одной двуспальной кроватью размера кинг-сайз или двумя отдельными кроватями. Панорамные окнами с видом на Кавказские горы или долину. Номер оформлен в стиле современного горного шале из натуральных материалов."
  }, {
    images: [
      "/assets/images/subpages/rooms/premiumdel/1.jpeg",
      "/assets/images/subpages/rooms/premiumdel/2.jpeg",
      "/assets/images/subpages/rooms/premiumdel/3.jpeg",
      "/assets/images/subpages/rooms/premiumdel/4.jpeg",
      "/assets/images/subpages/rooms/premiumdel/5.jpeg",
      "/assets/images/subpages/rooms/premiumdel/6.jpeg",
      "/assets/images/subpages/rooms/premiumdel/7.jpeg"
    ], 
    "url":"premium-deluxe",
    "title":"Premium Deluxe", 
    desc2: "2 основных + 2 дополнительных места",
    desc: "1-комнатный номер 35 кв.м с балконом, имеет обособленную зону отдыха с диваном.<br/><b>2 основных + 2 дополнительных места.</b>",
    size: "35", rooms: 1, mans: "2+2",
    text: `
      Просторный номер площадью 35 кв.м с одной двуспальной кроватью размера кинг-сайз ( или двумя кроватями размером 200х90 см.) и с обособленной зоной отдыха с диваном.<br/>
      Номер с панорамными окнами, балконам, оборудован зеркальным LED телевизором диагональю 32 дюйма, чайной зоной, раскладным диваном, со столиком и письменным столом в зоне отдыха, эргономичной группой шкафов с зеркалом в пол и вместительной гардеробной в зоне спальни, комфортной ванной комнатой с душем.<br/>
      Пространство номера спроектировано с зонированием, что дает ощущение обособленности и дополнительного комфорта.<br/>
      Номер оформлен в стиле современного горного шале из натуральных материалов, с природными элементами декора и мягким ковровым покрытием. Отделка номера выполнена в теплых древесных тонах, с яркими акцентами золотистого текстиля и гобеленов.
    `
  }, {
    images: [
      "/assets/images/subpages/rooms/luxe/1.jpeg",
      "/assets/images/subpages/rooms/luxe/2.jpeg",
      "/assets/images/subpages/rooms/luxe/3.jpeg",
      "/assets/images/subpages/rooms/luxe/4.jpeg",
      "/assets/images/subpages/rooms/luxe/5.jpeg",
      "/assets/images/subpages/rooms/luxe/6.jpeg",
      "/assets/images/subpages/rooms/luxe/7.jpeg"
    ], 
    "url":"luxe",
    "title":"Luxe", 
    desc2: "2 основных + 2 дополнительных места",
    desc: "Двухкомнатный номер площадью 37 кв.м. с отделенной спальней и зоной отдыха с диваном, вместительной гардеробной и балконом.<br/><b>2 основных + 2 дополнительных места.</b>",
    size: "37", rooms: 2, mans: "2+2",
    text: `
      Номер площадью 37 кв.м с отделенной спальней с одной двуспальной кроватью размера кинг-сайз и зоной отдыха с диваном.<br/>
      Номер Люкс в зоне отдыха оборудован всем необходимым для максимального комфорта и продуман до мелочей, прекрасно подойдет для отдыха в кругу семьи или общения с друзьями.<br/>
      Компактная спальня в номере Люкс имеет свой балкон с мебелью, вместительную гардеробную, оборудована зеркальным LED телевизором диагональю 32 дюйма и Меню подушек для ощущения полного уюта.<br/>
      Номер оформлен в стиле современного горного шале из натуральных материалов, с природными элементами декора и мягким ковровым покрытием. Отделка номера выполнена в теплых древесных тонах, с яркими акцентами золотистого текстиля и гобеленов.
    `
  }, {
    images: [
      "/assets/images/subpages/rooms/rluxe/1.jpeg",
      "/assets/images/subpages/rooms/rluxe/2.jpeg",
      "/assets/images/subpages/rooms/rluxe/3.jpeg",
      "/assets/images/subpages/rooms/rluxe/4.jpeg",
      "/assets/images/subpages/rooms/rluxe/5.jpeg",
      "/assets/images/subpages/rooms/rluxe/6.jpeg",
      "/assets/images/subpages/rooms/rluxe/7.jpeg"
    ], 
    "url":"royal-luxe",
    "title":"Royal Luxe", 
    desc2: "2 основных места",
    desc: "Эксклюзивный, комфортабельный двухкомнатный номер размером 37 кв.м. с роскошной террасой.<br/><b>2 основных места.</b>",
    size: "37", rooms: 2, mans: "2",
    text: `
      Эксклюзивный, комфортабельный номер, размером 37 кв.м. С роскошной видовой террасой, с отделенной спальней с одной двуспальной кроватью размера кинг-сайз и прекрасно декорированными гостиной и зонами отдыха.<br/>
      Номер Royal Люкс в зоне отдыха декорирован дизайнерской мебелью ручной работы и оборудован всем необходимым для максимального комфорта и продуман до мелочей, прекрасно подойдет для отдыха в кругу семьи, общения с друзьями или приема гостей.<br/>
      Просторная спальня в номере Royal Люкс оборудована зеркальным LED телевизором диагональю 32 дюйма, панорамное остекление окон позволит любоваться горными пейзажами. Номер оснащен ванной комнатой с душем с нотками роскоши в виде золотой мозаики.
    `
  }, {
    images: [
      "/assets/images/subpages/rooms/fam1/1.jpeg",
      "/assets/images/subpages/rooms/fam1/2.jpeg",
      "/assets/images/subpages/rooms/fam1/3.jpeg",
      "/assets/images/subpages/rooms/fam1/4.jpeg",
      "/assets/images/subpages/rooms/fam1/5.jpeg",
      "/assets/images/subpages/rooms/fam1/6.jpeg",
      "/assets/images/subpages/rooms/fam1/7.jpeg"
    ], 
    "url":"semejnyj-luxe-s-odnoj-spalnej-i-kuxnej",
    "title":"Семейный Luxe с одной спальней", 
    desc2: "2 основных + 2 дополнительных места",
    desc: "Двухкомнатный номер площадью 45 кв.м. с кухней, зоной отдыха с диваном и обеденным столом.<br/><b>2 основных + 2 дополнительных места.</b>",
    size: "45", rooms: 2, mans: "2+2",
    text: `
      Семейный Люкс площадью 45 кв.м с отделенной спальней с одной двуспальной кроватью размера кинг-сайз, полностью оборудованной кухней и зоной отдыха с диваном и телевизором.<br/>
      Номер полностью приспособлен для проживания с детьми или небольшой компанией, имеет полностью укомплектованную кухню, обеденный стол на 4 человека, комфортную зону отдыха с раздвижным диваном и просторную входную группу, где можно разместить детскую коляску.<br/>
      Обособленная спальня со своим балкон с мебелью, вместительной гардеробной и камерной атмосферой подарит ощущения особого уюта.<br/>
      Номер оформлен в стиле современного горного шале из натуральных материалов, с природными элементами декора и мягким ковровым покрытием. Отделка номера выполнена в теплых древесных тонах, с яркими акцентами золотистого текстиля и гобеленов.
    `
  }, {
    images: [
      "/assets/images/subpages/rooms/fam2/1.jpeg",
      "/assets/images/subpages/rooms/fam2/2.jpeg",
      "/assets/images/subpages/rooms/fam2/3.jpeg",
      "/assets/images/subpages/rooms/fam2/4.jpeg",
      "/assets/images/subpages/rooms/fam2/5.jpeg",
      "/assets/images/subpages/rooms/fam2/6.jpeg",
      "/assets/images/subpages/rooms/fam2/7.jpeg"
    ], 
    "url":"semejnyj-luxe-s-dvumya-spalnyami-i-kuxnej",
    "title":"Семейный Luxe с двумя спальнями", 
    desc2: "4 основных + 2 дополнительных места",
    desc: "Трехкомнатный номер площадью 50 кв.м. с кухней, зоной отдыха с диваном.<br/><b>4 основных + 2 дополнительных места.</b>",
    size: "50", rooms: 3, mans: "4+2",
    text: `
      Семейный Люкс площадью 50 кв.м с двумя отделенными спальнями с двумя двуспальными кроватями размера кинг-сайз, полностью оборудованной кухней с зоной отдыха с диваном и телевизором.<br/>
      Номер полностью приспособлен для проживания всей семьей или компанией до 5 человек, имеет полностью укомплектованную кухню, обеденный стол на 4 человека, комфортную зону отдыха с раздвижным диваном.<br/>
      Две отдельные спальни с собственными ванными комнатами обеспечат поистине домашний уют и максимальный комфорт.<br/>
      Номер оформлен в стиле современного горного шале из натуральных материалов, с природными элементами декора и мягким ковровым покрытием. Отделка номера выполнена в теплых древесных тонах, с яркими акцентами золотистого текстиля и гобеленов.
    `
  }, {
    images: [
      "/assets/images/subpages/rooms/inter/1.jpeg",
      "/assets/images/subpages/rooms/inter/2.jpeg",
      "/assets/images/subpages/rooms/inter/3.jpeg",
      "/assets/images/subpages/rooms/inter/4.jpeg",
      "/assets/images/subpages/rooms/inter/5.jpeg",
      "/assets/images/subpages/rooms/inter/6.jpeg",
      "/assets/images/subpages/rooms/inter/7.jpeg"
    ], 
    "url":"interconnect",
    "title":"Интерконнект", 
    desc2: "4 основных места",
    desc: "Два объединенных межкомнатной дверью номера категории Delux, 48 кв.м. с двумя санузлами и балконами. <b>4 основных места.</b>",
    size: "48", rooms: 2, mans: "4",
    text: `
      Интерконнект – это два, объединенных межкомнатной дверью номера категории Delux, общей площадью 48 кв. м с двумя санузлами<br/>
      Номера с панорамными окнами с видом на Кавказские горы или долину с двумя двуспальными кроватями размера кинг-сайз, с балконом и мебелью, зеркальным LED телевизором диагональю 32 дюйма, бесплатным Wi-Fi, чайной зоной для отдыха, письменным столом, эргономичной группой шкафов с зеркалом в пол, двумя комфортными санузлами с душем.<br/>
      Номер-интерконнект оформлен в стиле современного горного шале из натуральных материалов, с природными элементами декора и мягким ковровым покрытием. Отделка номера выполнена в теплых древесных тонах, с яркими акцентами золотистого текстиля и гобеленов.
    `
  }, {
    images: [
      "/assets/images/subpages/rooms/shale/1.jpeg",
      "/assets/images/subpages/rooms/shale/2.jpeg",
      "/assets/images/subpages/rooms/shale/4.jpeg",
      "/assets/images/subpages/rooms/shale/5.jpeg",
      "/assets/images/subpages/rooms/shale/7.jpeg",
      "/assets/images/subpages/rooms/shale/8.jpeg",
      "/assets/images/subpages/rooms/shale/10.jpeg"
    ],
    images_hidden: [
      "/assets/images/rooms/sh1.jpeg",
      "/assets/images/rooms/sh2.jpeg",
      "/assets/images/rooms/sh3.jpeg"
    ],
    "url":"shale",
    "title":"Шале", 
    desc2: "8 основных + 4 дополнительных места",
    desc: "Двухэтажный коттедж площадью 120 кв.м. 5 комнат. 4 отдельные спальни и большая кухня-гостиная.<br/><b>8 основных + 4 дополнительных места</b>",
    size: "2 этажа", rooms: 4, mans: "12",
    text: `
      Двухэтажное шале идеально вписано в окружающий ландшафт. Из окон открывается вид на долину реки Архыз и северный склон Кавказского хребта.<br/>
      Шале обустроены четырьмя спальнями с большими двуспальными кроватями King-size или Twin-size, гостиной и кухонной зоной.<br/>
      Гостиная комната разделена на функциональные зоны: кухня со встроенной техникой, трансформируемая обеденная зона, где с комфортом может расположится до 12 человек, а также зона-отдыха с 2 диванами-кроватью и журнальным столиком.<br/>
      Комнаты оформлены в стиле современного горного шале из натуральных материалов, с природными элементами декора. Дизайнерские отделка и предметы интерьера выполнена исключительно из ценных пород дерева.<br/>
      Шале оборудованы телевизорами с плоским экраном, доступен бесплатный WI-FI. Для удобства гостей есть три санузла с душевыми кабинами. Гостям предлагаются индивидуальные туалетные принадлежности, постельное белье из натуральных тканей, набор полотенец, набор посуды и инвентарь для кухни.
    `
  }
]

export const EntertItems: any[] = [
  {
    image: "/assets/images/home/entert/1.jpeg",
    title: "Конные прогулки",
    text: "Давно хотели научиться ездить верхом и насладиться незабываемыми видами на горы? Это весело и безопасно. Мы осуществим вашу мечту!",
    url: "konnie-progulki-po-arkhyzu"
  }, {
    image: "/assets/images/home/entert/2.jpeg",
    title: "Экскурсии",
    text: "Интересные и познавательные эксурсии не оставят Вас равнодушными. Кристально чистые озера и реки поразят вас своей безупречной красотой.",
    url: "excursion-programs"
  }, {
    image: "/assets/images/home/entert/3.jpeg",
    title: "Велопрогулки",
    text: "Хотите насладиться умопомрачительными красотами Архыза с пользой для здоровья? Тогда вам к нам! В прокате имеются новые модели горных велосипедов.",
    url: "veloprogulka"
  }, {
    image: "/assets/images/home/entert/4.jpeg",
    title: "Астрономия",
    text: "Экскурсия в самую большую обсерваторию России. Изучение звёзд, открытие новых планет, тайны космоса и многое другое. Очень познавательно!",
    url: "nochnaya-progulka-po-nebu"
  }, {
    image: "/assets/images/piknik/pk1.jpeg",
    title: "Пикник",
    text: "Отличный повод отдохнуть и вкусно подкрепиться на природе, любуясь красотой гор, рек и озер Архыза. Незабываемые ощущения которые невозможно передать.",
    url: "picnick"
  }
]

export const EntertSlides: any[] = [
  {
    image: "/assets/images/home/entert/1.jpeg",
    title: "Конные прогулки",
    url: "konnie-progulki-po-arkhyzu"
  }, {
    image: "/assets/images/home/entert/2.jpeg",
    title: "Экскурсии",
    url: "excursion-programs"
  }, {
    image: "/assets/images/home/entert/3.jpeg",
    title: "Велопрогулки",
    url: "veloprogulka"
  }, {
    image: "/assets/images/home/entert/4.jpeg",
    title: "Астрономия",
    url: "nochnaya-progulka-po-nebu"
  }, {
    image: "/assets/images/piknik/1.jpeg",
    title: "Пикник",
    url: "picnick"
  }, {
    image: "/assets/images/home/entert/6.jpeg",
    title: "Экотропа",
    url: "ecopath"
  }
]

export const RestoSlides: any[] = [
  {
    image: "/assets/images/restoran/r3.jpg",
    title: "Ресторан «Гризайль»",
    url: "restoran"
  }, {
    image: "/assets/images/home/res5.webp",
    title: "Havana bar",
    url: "havana-bar"
  }, {
    image: "/assets/images/home/res2.webp",
    title: "Лобби-бар",
    url: "lobbi-bar"
  }, {
    image: "/assets/images/restoran/v1.jpeg",
    title: "Веранда",
    url: "veranda"
  }, 
  // {
  //   image: "/assets/images/home/res6.webp",
  //   title: "Живая музыка",
  //   url: "live-music"
  // }, 
  {
    image: "/assets/images/home/res4.webp",
    title: "Мангальная зона",
    url: "mangalnaya-zona"
  }, {
    image: "/assets/images/home/res7.webp",
    title: "Ваше торжество",
    url: "torghestvo"
  }
]

export const RestoItems: any[] = [
  {
    image: "/assets/images/restoran/r3.jpg",
    title: "Ресторан «Гризайль»",
    text: "Романтический ужин для двоих или веселое застолье для всей семьи или большой компании. В меню всегда вкусные блюда европейской кухни и кавказские.",
    url: "restoran"
  }, {
    image: "/assets/images/home/res2.webp",
    title: "Лобби-бар",
    text: "В лобби-баре с полудня и до ночи царит непринужденная атмосфера. Здесь можно организовать деловую встречу или просто отдохнуть прогулок по Архызу.",
    url: "lobbi-bar"
  }, {
    image: "/assets/images/restoran/v1.jpeg",
    title: "Открытая терасса",
    text: "Гости Архыза смогут окунуться в атмосферу Северного Кавказа и подарить себе незабываемые моменты единения с природой. Вкусная еда и прохладительные напитки.",
    url: "veranda"
  }, {
    image: "/assets/images/restoran/mangal/r1.jpeg",
    title: "Мангальная зона",
    text: "Открытый огонь обладает особым свойством объединять людей и делать атмосферу уютнее, общение — теплее. Устройте отличный пикник в горах Архыза!",
    url: "mangalnaya-zona"
  }, {
    image: "/assets/images/home/res5.webp",
    title: "Havana Bar",
    text: "Островок прохлады и свежести, в самом сердце Arkhyz Royal Resort & SPA - открытого бассейна! Летнее меню, прохладительные напитки и различные коктейли.",
    url: "havana-bar"
  }, 
  // {
  //   image: "/assets/images/home/res6.webp",
  //   title: "Живая музыка",
  //   text: "Настоящие концерты без платы за входной билет. Главное требование к гостю – отдых, получение удовольствия от трапезы и, конечно, музыки.",
  //   url: "live-music"
  // }
]

export const SPAItems: any[] = [
  {
    image: "/assets/images/home/spa1.webp",
    title: "Хаммам",
    text: "Хаммам — тип традиционной турецкой бани, отличающийся невысоким температурным режимом: 35–50 градусов Цельсия. Очень полезно для здоровья!",
    url: "hammam"
  }, {
    image: "/assets/images/home/spa2.webp",
    title: "Панорамный бассейн",
    text: "Открытый бассейн отличный вариант для пляжного отдыха в горах. Приятный сервис и прохладительные напитки будут ждать всегда рядом.",
    url: "panoramniy-bassein"
  }, {
    image: "/assets/images/spa/kb/kb1.jpeg",
    title: "Крытый бассейн",
    text: "Шикарное место для вашего роскошного отдыха, в любую погоду. Расслабляющая и приятная музыка позволит расслабиться и забыть о городское суете.",
    url: "kritiy-bassein"
  }, {
    image: "/assets/images/home/spa4.webp",
    title: "Соляная комната",
    text: "Посещение соляной комнаты позволяет насыщать клетки полезными веществами, улучшить функцию всех органов, в частности легких.",
    url: "solyanaya-komnata"
  }, {
    image: "/assets/images/home/spa5.webp",
    title: "Финская сауна",
    text: "Полная релаксация и гармония души и тела – то, что нужно и зимой и летом! Оказывает благотворное влияние на организм и согревает душу.",
    url: "finskaya-sauna"
  }, {
    image: "/assets/images/home/spa6.webp",
    title: "Арома сауна",
    text: "Кожа распаривается от сухого пара, а в расширенные поры проходят полезные вещества, которые положительно воздействуют на организм.",
    url: "aroma-sauna"
  }, {
    image: "/assets/images/home/spa7.webp",
    title: "Тренажерный зал",
    text: "",
    url: "fitnes"
  }
]

export const menuItems: MenuItem[] = [
  { 
    url: "#",
    component: <></>,
    title: "О нас",
    dropdown: {
      items: [
        {priority: 0.3, change: "weekly", url: "about-us", title: "Об отеле", component: <AboutUs />},
        {priority: 0.3, change: "weekly", url: "usloviya-prozhivaniya", title: "Условия проживания", component: <UsloviyaProzhiv />},
        {priority: 0.3, change: "weekly", url: "otzyvi", title: "Отзывы", component: <Reviews />},
        {priority: 0.5, change: "hourly", url: "transfer", title: "Трансфер", component: <Transfer />},
        {priority: 0.3, change: "daily", url: "booking", title: "Забронировать", component: <Booking />},
        {priority: 0.5, change: "hourly", url: "contacts", title: "Контакты", component: <Contacts />}
      ]
    }
  },
  { 
    priority: 0.3,
    change: "weekly",
    url: "nashi-nomera",
    component: <NashiNomera />,
    title: "Номера",
    dropdown: {
      items: [
        {
          priority: 0.3,
          change: "weekly",
          component: <RoomPage />,
          image: "/assets/images/subpages/rooms/1.jpg", 
          "url":"deluxe-twin-king",
          "title":"Deluxe", 
          desc: "Стандартный однокомнатный номер с балконом, 1 кроватью king-size или 2 кроватями twin.<br/><b>Без дополнительных мест.</b>",
        }, {
          priority: 0.3,
          change: "weekly",
          component: <RoomPage />,
          image: "/assets/images/subpages/rooms/2.webp", 
          "url":"premium-deluxe",
          "title":"Premium Deluxe", 
          desc: "1-комнатный номер 35 кв.м с балконом, имеет обособленную зону отдыха с диваном.<br/><b>2 основных + 2 дополнительных места.</b>"
        }, {
          priority: 0.3,
          change: "weekly",
          component: <RoomPage />,
          image: "/assets/images/subpages/rooms/3.jpeg", 
          "url":"luxe",
          "title":"Luxe", 
          desc: "Двухкомнатный номер площадью 37 кв.м. с отделенной спальней и зоной отдыха с диваном, вместительной гардеробной и балконом.<br/><b>2 основных + 2 дополнительных места.</b>"
        }, {
          priority: 0.3,
          change: "weekly",
          component: <RoomPage />,
          image: "/assets/images/subpages/rooms/4.webp", 
          "url":"royal-luxe",
          "title":"Royal Luxe", 
          desc: "Эксклюзивный, комфортабельный двухкомнатный номер размером 37 кв.м. с роскошной террасой.<br/><b>2 основных места.</b>"
        }, {
          priority: 0.3,
          change: "weekly",
          component: <RoomPage />,
          image: "/assets/images/subpages/rooms/5.jpeg", 
          "url":"semejnyj-luxe-s-odnoj-spalnej-i-kuxnej",
          "title":"Семейный Luxe с одной спальней и кухней", 
          desc: "Двухкомнатный номер площадью 45 кв.м. с кухней, зоной отдыха с диваном и обеденным столом.<br/><b>2 основных + 2 дополнительных места.</b>"
        }, {
          priority: 0.3,
          change: "weekly",
          component: <RoomPage />,
          image: "/assets/images/subpages/rooms/7.jpeg", 
          "url":"semejnyj-luxe-s-dvumya-spalnyami-i-kuxnej",
          "title":"Семейный Luxe с двумя спальнями и кухней", 
          desc: "Трехкомнатный номер площадью 50 кв.м. с кухней, зоной отдыха с диваном.<br/><b>4 основных + 2 дополнительных места.</b>"
        }, {
          priority: 0.3,
          change: "weekly",
          component: <RoomPage />,
          image: "/assets/images/subpages/rooms/8.jpeg", 
          "url":"interconnect",
          "title":"Интерконнект", 
          desc: "Два объединенных межкомнатной дверью номера категории Delux, 48 кв.м. с двумя санузлами и балконами. <b>4 основных места.</b>"
        }, {
          priority: 0.3,
          change: "weekly",
          component: <RoomPage />,
          image: "/assets/images/subpages/rooms/9.webp", 
          "url":"shale",
          "title":"Шале", 
          desc: "Двухэтажный коттедж площадью 120 кв.м. 5 комнат. 4 отдельные спальни и большая кухня-гостиная.<br/><b>8 основных + 4 дополнительных места</b>"
        }
      ]
    }
  },
  {
    priority: 0.6,
    change: "hourly",
    component: <Promo />,
    url: "promo",
    title: "Акции!",
    dropdown: null
  },
  {
    component: <></>,
    url: "#",
    title: "Рестораны",
    dropdown: {
      items: [
        {priority: 0.3, change: "weekly", component: <Restoran />, "url":"restoran","title":"Ресторан «Гризайль»"},
        {priority: 0.3, change: "weekly", component: <Restoran />, "url":"havana-bar","title":"Havana bar"},
        {priority: 0.3, change: "weekly", component: <Restoran />, "url":"lobbi-bar","title":"Лобби-бар"},
        {priority: 0.3, change: "weekly", component: <Restoran />, "url":"veranda","title":"Веранда"},
        //{priority: 0.3, change: "weekly", component: <Restoran />, "url":"live-music","title":"Вечера живой музыки"},
        {priority: 0.3, change: "weekly", component: <Restoran />, "url":"mangalnaya-zona","title":"Мангальная зона"}
      ]
    }
  },
  {
    priority: 0.5,
    change: "hourly",
    component: <SPAPage />,
    url: "spa",
    title: "SPA",
    dropdown: {
      items: [
        {priority: 0.3, change: "weekly", component: <SPAPage />, "url":"panoramniy-bassein","title":"Панорамный бассейн"},
        {priority: 0.3, change: "weekly", component: <SPAPage />, "url":"kritiy-bassein","title":"Крытый бассейн"},
        {priority: 0.3, change: "weekly", component: <SPAPage />, "url":"hammam","title":"Хаммам"},
        {priority: 0.3, change: "weekly", component: <SPAPage />, "url":"solyanaya-komnata","title":"Соляная комната"},
        {priority: 0.3, change: "weekly", component: <SPAPage />, "url":"finskaya-sauna","title":"Финская сауна"},
        {priority: 0.3, change: "weekly", component: <SPAPage />, "url":"aroma-sauna","title":"Арома-сауна"},
        {priority: 0.3, change: "weekly", component: <SPAPage />, "url":"massage","title":"Массажный кабинет"},
        {priority: 0.3, change: "weekly", component: <SPAPage />, "url":"fitnes","title":"Тренажерный зал"},
        {priority: 0.3, change: "weekly", component: <SPAPage />, "url":"cosmetology","title":"Косметология"}
      ]
    }
  },
  {
    priority: 0.5,
    change: "hourly",
    component: <Kids />, 
    url: "detskij-centr",
    title: "Детям",
    dropdown: {
      items: [
        {priority: 0.3, change: "weekly", component: <Kids />, "url":"detskij-center","title":"Детский клуб ЛЕО"},
        {priority: 0.3, change: "weekly", component: <Kids />, "url":"playroom","title":"Игровая комната"},
        {priority: 0.3, change: "weekly", component: <Kids />, "url":"obuchaushie-igry","title":"Обучающие игры"},
        {priority: 0.3, change: "weekly", component: <Kids />, "url":"animation","title":"Анимация"},
        {priority: 0.3, change: "weekly", component: <Kids />, "url":"playground","title":"Детская площадка"},
        {priority: 0.3, change: "weekly", component: <Kids />, "url":"kinozal","title":"Кинозал"}
      ]
    }
  },
  {
    priority: 0.5,
    change: "hourly",
    component: <Horses />,
    url: "konniy-club",
    title: "Конный клуб",
    dropdown: {
      items: [
        {priority: 0.3, change: "weekly", component: <Horses />, "url":"konnie-progulki-po-arkhyzu","title":"Конные прогулки"},
        {priority: 0.3, change: "weekly", component: <Horses />, "url":"konnaya-shkola","title":"Конная школа"},
        {priority: 0.3, change: "weekly", component: <Horses />, "url":"fotosession-with-horse","title":"Фотосессия с лошадьми"}
      ]
    }
  },
  {
    component: <></>,
    url: "#",
    title: "Развлечения",
    dropdown: {
      items: [
        {priority: 0.3, change: "weekly", component: <Entertmnt />, "url":"excursion-programs","title":"Экскурсионные программы"},
        {priority: 0.3, change: "weekly", component: <Entertmnt />, "url":"kids-cinema","title":"Кинотеатр"},
        {priority: 0.3, change: "weekly", component: <Entertmnt />, "url":"nochnaya-progulka-po-nebu","title":"Астрономия"},
        {priority: 0.3, change: "weekly", component: <Entertmnt />, "url":"veloprogulka","title":"Велопрогулки"},
        {priority: 0.3, change: "weekly", component: <Entertmnt />, "url":"picnick","title":"Пикник"},
        {priority: 0.3, change: "weekly", component: <Entertmnt />, "url":"ecopath","title":"Экотропа"}
      ]
    }
  },
  {
    component: <></>,
    url: "#",
    title: "Мероприятия",
    dropdown: {
      items: [
        {priority: 0.3, change: "weekly", component: <Events />, "url":"corporativnie-predlogheniya","title":"Корпоративным клиентам"},
        {priority: 0.3, change: "weekly", component: <Events />, "url":"big-conference-hall","title":"Большой конференц-зал"},
        {priority: 0.3, change: "weekly", component: <Events />, "url":"small-conference-hall","title":"VIP зал"},
        {priority: 0.3, change: "weekly", component: <Events />, "url":"corporate-catering","title":"Кейтеринг"},
        {priority: 0.3, change: "weekly", component: <Events />, "url":"torghestvo","title":"Ваше торжество"},
        {priority: 0.3, change: "weekly", component: <Events />, "url":"detskie-prazdniki","title":"Детские праздники"}
      ]
    }
  },
  {
    url: "https://royaldetox.ru/",
    title: "Детокс",
    dropdown: null
  }
];

export const HiddenPages: MenuItem[] = [
  {
    url: "cookie-policy",
    title: "Политика использования файлов «Cookie»"
  }, {
    url: "subscribe",
    title: "Будьте в курсе всех уникальный предложений - подпишитесь на скидки!"
  }, {
    url: "payment-rules",
    title: "Правила оплаты и реквизиты"
  }, {
    url: "prices",
    title: "Тарифы на размещение"
  }, {
    url: "zapovednik",
    title: "Тебердинский заповедник"
  }, {
    url: "about-us",
    title: "О нас"
  }, {
    url: "transfer",
    title: "Трансфер"
  }, {
    url: "booking",
    title: "Забронировать"
  }, {
    url: "contacts",
    title: "Контакты"
  }, {
    url: "otzyvi",
    title: "Отзывы"
  }
]

export const findMenuBlock = (url: string): MenuItem | undefined => {
  let finded: MenuItem | undefined = undefined;

  menuItems.forEach(menuItem => {
    if (finded == undefined) {
      if (menuItem.url == url) {
        finded = menuItem
      } else {
        if (menuItem.dropdown != null) {
          menuItem.dropdown.items.forEach(dropItem => {
            if (dropItem.url == url) {
              finded = menuItem
            }
          })
        }
      }
    }
  });

  return finded
}