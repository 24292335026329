import React from "react";

const Home = React.lazy(() => import("../../pages/summer/Home"))
const AllPages = React.lazy(() => import("../../pages/summer/AllPages"))

const Home_winter = React.lazy(() => import("../../pages/winter/Home"))
const AllPages_winter = React.lazy(() => import("../../pages/winter/AllPages"))

const AdminAuth = React.lazy(() => import("../../pages/Admin/AdminAuth"))

export interface IRoute {
  path: string;
  component: React.ReactNode;
  exact?: boolean;
}

export enum RouteNames {
  HOME = '/',
  SUMMER = '/summer',
  SUMMER_PAGED = '/summer/:page',
  WINTER = '/winter',
  WINTER_PAGED = '/winter/:page',
  PAGED = '*',

  ADMIN_AUTH = "/admin/auth"
}

export const summerRoutes: IRoute[ ] = [
  { path: RouteNames.PAGED, exact: true, component: <AllPages /> },
  { path: RouteNames.HOME, exact: true, component: <Home /> },
  { path: RouteNames.WINTER_PAGED, exact: true, component: <AllPages_winter /> },
  { path: RouteNames.WINTER, exact: true, component: <Home_winter /> },
  { path: RouteNames.ADMIN_AUTH, exact: true, component: <AdminAuth /> }
]

export const winterRoutes: IRoute[ ] = [
  { path: RouteNames.SUMMER_PAGED, exact: true, component: <AllPages /> },
  { path: RouteNames.SUMMER, exact: true, component: <Home /> },
  { path: RouteNames.PAGED, exact: true, component: <AllPages_winter /> },
  { path: RouteNames.HOME, exact: true, component: <Home_winter /> },
  { path: RouteNames.ADMIN_AUTH, exact: true, component: <AdminAuth /> }
]
