import { observer } from "mobx-react-lite"
import { FC } from "react"

import styles from "../../../styles/Subpage.module.scss"

const UsloviyaProzhiv: FC = () => {
  return (
    <>
      <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2><strong>Условия проживания</strong></h2>

              <p><strong>Заселение</strong></p>
              <p>Заезд с 15:00.<br/>Выезд до 12:00.</p>
              <p>Ранний заезд предоставляется бесплатно при наличии такой возможности.<br/>Поздний выезд оплачивается в размере 50% суточной стоимости номера.<br/><br/></p>
              <p>Заселение в&nbsp;отель производится только при предъявлении внутреннего паспорта гражданина&nbsp;РФ каждого гостя (для лиц до&nbsp;14&nbsp;лет&nbsp;— свидетельства о&nbsp;рождении).</p>
              <p>&nbsp;</p>
              <p><strong>В стоимость проживания входит:</strong></p>
              <p>— завтрак по системе «Шведский стол»;<br/>— посещение SPA-центра отеля (открытый всесезонный бассейн, крытый бассейн, финская сауна, хаммам, арома-сауна, соляная комната) – 2 часа в день;<br/>— пользование детской игровой комнатой, работа воспитателя;<br/>— пользование детской площадкой;<br/>— пользование детской библиотекой;<br/>— посещение конного клуба (прокат и уроки верховой езды оплачиваются отдельно);<br/>— пользование тренажерным залом;<br/>— доступ к Wi-Fi на всей территории отеля;<br/>— пользование парковкой;<br/>— консультация врача;<br/>— консультация косметолога.</p>
              <p>&nbsp;</p>
              <p><strong>За&nbsp;дополнительную плату предоставляется:</strong></p>
              <p>— Питание и напитки на территории отеля в ресторане «Гризайль» (кроме завтрака «шведский стол»), Havana-bar, Лобби-баре;<br/>— room service &nbsp;(10% от суммы заказа);<br/>— услуги SPA-центра (массажи, обертывания, парения, уход и проч);<br/>— косметологические и медицинские услуги;<br/>— программы оздоровления, снижения веса и очищения организма («Detox», «Post-covid»);<br/>— дополнительное время посещения SPA-центра (2000 руб за сеанс для взрослого и детей старше 12 лет, 1000 руб за сеанс для ребенка до 12 лет, дети до 5 лет -бесплатно);<br/>— конные прогулки для детей и взрослых;<br/>— уроки верховой езды;<br/>— фотосессии, в том числе с лошадьми;<br/>— прокат горных велосипедов;<br/>— прокат оборудования для скандинавской ходьбы;<br/>— экскурсии;<br/>— детская кроватка-манеж;<br/>— аренда кинозала;<br/>— аренда VIP-зала, конференц-зала;<br/>— организация детских праздников, дней рождений;<br/>— трансфер из аэропорта «Минеральные воды», трансфер от жд вокзала.</p>
              <p>&nbsp;</p>
              <p><strong>Проживание с животными</strong></p>
              <p>Проживание в отеле с небольшими животными (не более 5 кг) возможно, стоимость услуги 3000р за заезд. Хозяину необходимо иметь при себе паспорт животного и все необходимое для содержания питомца в номере.</p>
              <p>&nbsp;</p>
              <p><strong>Условия проживания с детьми</strong></p>
              <p>Размещение детей на дополнительном месте до 16 лет оплачивается в размере 2000 руб. за ночь. Размещение взрослых на дополнительном месте оплачивается в размере 4000 руб. за ночь.</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p><strong>Отмена или перенос</strong></p>
              <p>Перенос дат проживания возможен по согласованию со службой бронирования отеля, но не позднее, чем за 24 часа до заселения и при условии наличия свободных номеров на выбранные даты. Расчет стоимости проживания происходит по актуальным ценам на дату переноса, с компенсацией гостем разницы в цене в том случае, если проживание на новые даты дороже отмененной брони;</p>
              <p>Если гость отказался от бронирования, то, руководствуясь п. 16 Постановления Правительства РФ № 1853 от 18.11.2020, отель удерживает плату за простой номера в размере стоимости одних суток проживания.</p>
              <p>Гость вправе перенести даты проживания за 24 часа до заезда без удержания стоимости первых суток, либо оставить оплаченную сумму на депозите отеля, чтобы воспользоваться ей позже.</p>

              
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(UsloviyaProzhiv)