import { observer } from "mobx-react-lite"
import { FC, useContext, useEffect, useState } from "react"
import {useLocation} from "react-router-dom"
import {Context} from "../../../.."
import {SliderToggleDirection,SliderToggleDir} from "../../../../core/utils/defaults"
import {menuItems, roomsCompiled} from "../../../../core/utils/menu"

import ImageViewModal from "../../../../components/ModalImageView"
import styles from "../../../../styles/Subpage.module.scss"
import useWindowDimensions from "../../../../core/hooks/useWindowDimensions"

const RoomPage: FC = () => {

  const { store } = useContext(Context)
  const { width } = useWindowDimensions()
  const location = useLocation()

  const [selectedRoom, setSelectedRoom] = useState(null as any | null)

  const buildRooms = () => {
    if (store.allRooms.length != 0) {
      return;
    }

    let rooms = [...roomsCompiled]
    store.setAllRooms(rooms)

    rooms.forEach(room => {
      if (location.pathname.toLowerCase().includes(`/${room.url.toLowerCase()}`)) {
        setSelectedRoom(room)
      }
    })
  }

  const openGalleryModal = (index: number) => {
    let images: any[] = []

    images.push(...selectedRoom.images.map((v: any) => {
      return {url: v, title: selectedRoom.desc}
    }))

    if (selectedRoom.images_hidden != undefined) {
      images.push(...selectedRoom.images_hidden.map((v: any) => {
        return {url: v, title: selectedRoom.desc}
      }))
    }

    store.setSelectedImages(images)
    store.setSelectedImageIndex(index)
    store.setIsImageViewVisible(true)
  }

  useEffect(() => {
    buildRooms()
  }, [])

  return (
    <>
      <div className={styles.subpage + " " + styles.roompage} style={{paddingTop: width > 960 ? "80px" : "72px"}}>
        
        {selectedRoom != null ? <>
          <div className={styles.room_data}>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className={styles.rd}>
                    <div className={styles.room_data__name}>{selectedRoom.title}</div>
                    <div className={styles.room_data__points}>
                      {selectedRoom.size == "2 этажа" ? <div><b style={{marginRight: "6px"}}>2</b> <span>этажа</span></div> : <div><span>Площадь</span> <b>{selectedRoom.size}</b> <small>м<strong>2</strong></small></div>}
                      <div><span>Кол-во <br/>комнат</span> <b>{selectedRoom.rooms}</b></div>
                      <div><span>Кол-во <br/>человек</span> <b>{selectedRoom.mans}</b></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className={styles.grid_images}>
                  <div className={styles.gi_main}>
                    <div onClick={() => openGalleryModal(0)} style={{backgroundImage: `url(${selectedRoom.images[0]})`}}></div>
                  </div>
                  <div className={styles.gi_other + " " + styles.gi_other_minimized}>
                    {selectedRoom.images.slice(1, selectedRoom.images.length).map((item: any, ix: number) => 
                      <div onClick={() => openGalleryModal(ix + 1)} style={{backgroundImage: `url(${item})`}}></div>
                    )}
                  </div>
                </div>

                <div className={styles.desc} dangerouslySetInnerHTML={{__html: selectedRoom.desc}}></div>

                <div className={styles.text}>{selectedRoom.text}</div>
                
                <div className={styles.booking}>
                  <a href="/booking">Забронировать по лучшей цене!</a>
                </div>

                <div className={styles.features}>
                  <img src="/assets/images/subpages/rooms/icons_feats.png" />
                </div>
              </div>
            </div>
          </div>
                
          <div className={styles.fbg}>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className={styles.allrooms}>
                    {store.allRooms.map(room => <a href={`/${room.url}`} className={styles.allrooms_item + (location.pathname == `/${room.url}` ? " " + styles.selected : "")}>
                      <div className={styles.allrooms_item__wrap}>
                        <div>{room.title}</div>
                        <div>{room.desc2}</div>
                      </div>
                    </a>)}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className={styles.book}>
                  <a href="/booking">Забронировать</a>
                </div>
              </div>
            </div>
          </div>

        </> : <></>}
      </div>
    </>
  )
}

export default observer(RoomPage)