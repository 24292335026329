import { observer } from "mobx-react-lite"
import { FC } from "react"

import styles from "../../../styles/Subpage.module.scss"

const AboutUs: FC = () => {
  return (
    <>
      <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2><strong>О НАС</strong></h2>
              
              <div className={styles.flexed}>
                <div><img src="/assets/images/subpages/about-us.png" /></div>
                <div>
                  <p>Отель Arkhyz Royal Resort &amp; SPA открылся в конце 2018 года. Он расположен в самом сердце туристической деревни Романтик, совсем рядом с канатными дорогами и сервис-центром курорта «Архыз», и имеет прямой доступ к горнолыжным склонам в зоне ski-in.<br/><br/>Отелю Arkhyz Royal Resort &amp; SPA был присвоен официальный статус 5 звезд, что гарантирует вам высочайший уровень сервиса и комфорта.</p>
                  <p>К услугам гостей — 87 номеров со стильными интерьерами, современной мебелью и техникой, доступом в интернет, уютными ванными комнатами, косметическими принадлежностями и всем необходимым для комфортного отдыха на фоне Кавказских гор. Единовременно в отеле могут остановиться около 230 гостей, и каждому будет оказан поистине королевский прием! А для тех, кто хочет уединения и особой атмосферы отдыха, у нас есть два просторных коттеджа в альпийском стиле.</p>
                </div>
              </div>

              <div className={styles.wpb_wrapper}>
                <p>К вашим услугам — ресторан и лобби-бар, фитнес-зал, детский центр, конференц-залы, экскурсионное бюро, пункт проката горнолыжного инвентаря с локерной камерой хранения, парковка для личного транспорта гостей. Гордость нашего отеля — многопрофильный SPA-комплекс «Термаль» с просторным бассейном, где вы сможете прикоснуться к национальным оздоровительным и банным традициям и полностью забыть об усталости и напряжении после сеанса массажа.</p>
                <p>Команда отеля Arkhyz Royal Resort &amp; SPA с радостью окажет вам любую помощь: закажет трансфер в аэропорт, поможет подобрать горные лыжи по размеру, организует увлекательную экскурсию к необычным достопримечательностям здешних мест.</p>
                <p>«Лицо» нашего отеля — переднеазиатский леопард. Когда-то этот вид населял все горные районы Кавказа (в том числе и окрестности «Архыза»), но теперь почти не встречается в дикой природе. Мы хотим познакомить вас с этой удивительной большой кошкой, и потому леопард станет вашим проводником и гидом по отдыху в отеле Arkhyz Royal Resort &amp; SPA.</p>
                <p>Наш отель придерживается общемировых экостандартов, и мы надеемся, что вы разделите нашу любовь к природе.</p>
                <p><strong>Желаем вам незабываемого отдыха!</strong></p>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(AboutUs)