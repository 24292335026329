import { observer } from "mobx-react-lite"
import { FC, useContext, useEffect, useState } from "react"
import {useLocation} from "react-router-dom"
import {Context} from "../../.."
import {XImage} from "../../../core/models/XImage"
import {findMenuBlock, MenuSubItem} from "../../../core/utils/menu"

import styles from "../../../styles/Subpage.module.scss"

const RestoImgs: XImage[] = [
  { url: "/assets/images/restoran/r1.jpg", title: "" },
  { url: "/assets/images/restoran/r2.jpg", title: "" },
  { url: "/assets/images/restoran/r3.jpg", title: "" },
  { url: "/assets/images/restoran/r4.jpg", title: "" },
  { url: "/assets/images/restoran/r5.jpg", title: "" },
  { url: "/assets/images/restoran/r6.jpeg", title: "" }
]

const HavanaImgs: XImage[] = [
  { url: "/assets/images/restoran/r7.jpeg", title: "" },
  { url: "/assets/images/restoran/r8.jpeg", title: "" },
  { url: "/assets/images/restoran/r9.jpeg", title: "" }
]

const LobbiImgs: XImage[] = [
  { url: "/assets/images/restoran/r10.jpeg", title: "" },
  { url: "/assets/images/restoran/r11.jpeg", title: "" },
  { url: "/assets/images/restoran/r12.jpeg", title: "" }
]

const VerandaImgs: XImage[] = [
  { url: "/assets/images/restoran/v1.jpeg", title: "" },
  { url: "/assets/images/restoran/v2.jpeg", title: "" },
  { url: "/assets/images/restoran/v3.jpeg", title: "" }
]

const LiveImgs: XImage[] = [
  { url: "/assets/images/restoran/live/r1.jpeg", title: "" },
  { url: "/assets/images/restoran/live/r2.jpeg", title: "" },
  { url: "/assets/images/restoran/live/r3.jpeg", title: "" }
]

const Live2Imgs: XImage[] = [
  { url: "/assets/images/restoran/live/r4.jpeg", title: "" },
  { url: "/assets/images/restoran/live/r5.jpeg", title: "" },
  { url: "/assets/images/restoran/live/r6.jpeg", title: "" }
]

const MangalImgs: XImage[] = [
  { url: "/assets/images/restoran/mangal/r1.jpeg", title: "" },
  { url: "/assets/images/restoran/mangal/r2.jpeg", title: "" },
  { url: "/assets/images/restoran/mangal/r3.jpeg", title: "" }
]

const Restoran: FC = () => {

  const { store } = useContext(Context)
  const location = useLocation()

  const [pages, setPages] = useState([] as MenuSubItem[])

  const openGalleryModal = (imgs: XImage[], index: number) => {
    store.setSelectedImages(imgs)
    store.setSelectedImageIndex(index)
    store.setIsImageViewVisible(true)
  }

  const fetchBlock = () => {
    console.log(location.pathname, location.pathname.split("/"))
    const block = findMenuBlock(location.pathname.split("/")[1])
    console.log(block)

    if (block != undefined && block.dropdown != null) {
      setPages(block.dropdown.items)
    }
  }

  useEffect(() => {
    fetchBlock()
  }, [location])

  useEffect(() => {
    fetchBlock()
  }, [])

  return (
    <>
      {location.pathname.includes("/mangalnaya-zona") ? <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2><strong>Мангальная зона</strong> - мясо на огне в компании самых близких</h2>

              <p>Мангальная зона находится на собственной территории отеля Arkhyz Royal Resort & SPA: освещенная площадка, живописные горы Архыза, беседка и прекрасная природа вокруг.</p>
              <p>Открытый огонь обладает особым свойством объединять людей и делать атмосферу уютнее, общение — теплее. Устройте спонтанный пикник по случаю отличного настроения и хорошей погоды или запланированный праздник на свежем воздухе и готовьте все вместе самое свежее мясо на углях.</p>
              <p>Стоимость аренды мангала - 3000 руб. за 2 часа.<br/><br/></p>

              <div className={styles.grid_images}>
                <div className={styles.gi_other}>
                  {MangalImgs.map((image, ix) => <div style={{height: "260px", backgroundImage: `url(${image.url})`}} onClick={() => openGalleryModal(MangalImgs, ix)}></div>)}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div> : <></>}

      {location.pathname.includes("/live-music") ? <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2><strong>Вечера живой музыки</strong> - раствориться в музыке</h2>

              <p>Регулярно в ресторане «Гризайль» проходят живые концерты для меломанов и гостей заведения. Саксофон, гитара, диджеи, музыкальные группы – варианты на любой вкус!</p>
              <p>Это настоящий концерт без платы за входной билет. Главное требование к гостю – отдых, получение удовольствия от трапезы и, конечно, музыки.<br/><br/></p>

              <div className={styles.grid_images}>
                <div className={styles.gi_other}>
                  {LiveImgs.map((image, ix) => <div style={{height: "260px", backgroundImage: `url(${image.url})`}} onClick={() => openGalleryModal(LiveImgs, ix)}></div>)}
                </div>
              </div>

              <div className={styles.grid_images}>
                <div className={styles.gi_other}>
                  {Live2Imgs.map((image, ix) => <div style={{height: "586px", backgroundImage: `url(${image.url})`}} onClick={() => openGalleryModal(Live2Imgs, ix)}></div>)}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div> : <></>}
    
      {location.pathname.includes("/veranda") ? <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2><strong>Веранда</strong> - терраса с видом на горы</h2>

              <p>Ещё один повод остановиться в Arkhyz Royal Resort &amp; SPA - это всесезонная открытая терраса, рассчитанная на 30 человек, с которой открываются умопомрачительные виды на горные хребты.</p>
              <p>Гости курорта «Архыз» смогут окунуться в могущественную атмосферу Северного Кавказа и подарить себе незабываемые моменты единения с природой, не отказываясь от комфорта и высокого уровня сервиса.</p>
              <p>На самом деле, что может быть лучше, чем неспешно полакомиться любимыми блюдами кавказской кухни от шеф-повара ресторана «Гризайль» на свежем воздухе, дополнив обед бокалом освежающего игристого вина.</p>
              <p>Вечером же - мягкое освещение, уютные пледы и легкая фоновая музыка создают особую романтическую атмосферу, свойственную, самому роскошному отдыху.<br/><br/></p>

              <div className={styles.grid_images}>
                <div className={styles.gi_other}>
                  {VerandaImgs.map((image, ix) => <div style={{height: "260px", backgroundImage: `url(${image.url})`}} onClick={() => openGalleryModal(VerandaImgs, ix)}></div>)}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div> : <></>}
      
      {location.pathname.includes("/lobbi-bar") ? <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2><strong>Лобби-бар</strong> - лаунж-зона с панорамными окнами</h2>

              <p>В лобби-баре на первом этаже отеля Arkhyz Royal Resort &amp; SPA с полудня и до поздней ночи царит непринужденная атмосфера. Здесь можно организовать деловую встречу, выпить ароматного горного чая в перерыве между катанием или отдохнуть в компании друзей после дня на горнолыжном склоне.</p>
              <p>Располагайтесь, где вам больше нравится: за столиками у широких панорамных окон с видом на заснеженные трассы курорта «Архыз» или на мягких диванах в лаундж-зоне.</p>
              <p>В меню лобби-бара — легкие закуски и салаты, оригинальные десерты и свежая выпечка, лимонады и морсы собственного приготовления, большой выбор согревающих коктейлей. Наши бармены и официанты помогут вам определиться с выбором, расскажут о новинках в меню и сделают все возможное для вашего комфортного отдыха.<br/><br/></p>
            
              <div className={styles.grid_images}>
                <div className={styles.gi_other}>
                  {LobbiImgs.map((image, ix) => <div style={{height: "260px", backgroundImage: `url(${image.url})`}} onClick={() => openGalleryModal(LobbiImgs, ix)}></div>)}
                </div>
              </div>
            
            </div>
          </div>
        </div>
      </div> : <></>}
      
      {location.pathname.includes("/havana-bar") ? <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2><strong>Havana bar</strong> - тропический отдых в горах</h2>
            
              <p>Это островок прохлады и свежести, в самом сердце Arkhyz Royal Resort &amp; SPA - открытого бассейна!<br/>Летнее меню, включая всевозможные напитки, фреши и коктейли, позволят вам освежиться и продолжить наслаждаться отдыхом.<br/><br/></p>
            
              <div className={styles.grid_images}>
                <div className={styles.gi_other}>
                  {HavanaImgs.map((image, ix) => <div style={{height: "260px", backgroundImage: `url(${image.url})`}} onClick={() => openGalleryModal(HavanaImgs, ix)}></div>)}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div> : <></>}

      {location.pathname.includes("/restoran") ? <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2><strong>Добро пожаловать в мир авторской кухни!</strong></h2>
              
              <p>Шеф ресторана GRIZAILLE Олег Лукашин творчески подошел к созданию нового меню, где каждое блюдо – результат поиска нового и неожиданного.<br/><br/></p>
              <p>Современный взгляд на кухню позволяет команде GRIZAILLE создавать яркие сочетания, а огромная палитра насыщенных вкусов открывает новые оттенки и источник эмоций, которыми повар и гость обмениваются друг с другом.<br/><br/></p>
              <p>Мы отдаем дань уважения мировой и кавказкой кухне, поэтому всегда стремимся к новым прочтениям местных традиций и современных тенденций мировой гастрономии, используя для этого дары полей, лесов и садов – все, чем так богат наш край.</p>
              <p>&nbsp;</p>
              <p>Мы большая и дружная семья и с уважением относимся к своему ремеслу.</p>
              <p>Ждем Вас в гости! <br/><br/></p>

              <div className={styles.grid_images}>
                <div className={styles.gi_other}>
                  {RestoImgs.map((image, ix) => <div style={{height: "260px", backgroundImage: `url(${image.url})`}} onClick={() => openGalleryModal(RestoImgs, ix)}></div>)}
                </div>
              </div>

              <div className={styles.pt62}>
                <a className="arhbtn arhbtn-primary" href="/menu/qr.pdf">Скачать меню</a>
              </div>

            </div>
          </div>
        </div>
      </div> : <></>}
    </>
  )
}

export default observer(Restoran)