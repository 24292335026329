import {DeleteOutlined} from "@ant-design/icons"
import {Editor} from "@tinymce/tinymce-react"
import {message, Switch, Upload, UploadProps} from "antd"
import imageCompression from "browser-image-compression"
import { observer } from "mobx-react-lite"
import { FC, useContext, useState, useEffect, useRef } from "react"
import { useLocation } from "react-router-dom"
import { Context } from "../../.."
import {ApiService} from "../../../core/api/api"
import {useFetching} from "../../../core/hooks/useFetching"
import {IXImages, IXPage} from "../../../core/models/XPage"
import {IXRoom} from "../../../core/models/XRoom"
import {API_URL, StorageKeys} from "../../../core/utils/defaults"
import {sortById, sortByIndex, translit} from "../../../core/utils/utils"

import styles from "../../../styles/AdminManager.module.scss"

const RoomsManager: FC = () => {

  const { store, adminStore } = useContext(Context)
  const location = useLocation()

  const editorRef = useRef(null as any)
  
  const [selectedRoom, setSelectedRoom] = useState(null as null | IXRoom)
  const [roomImage, setRoomImage] = useState("")
  const [roomTitle, setRoomTitle] = useState("")
  const [roomUrl, setRoomUrl] = useState("")
  const [roomText, setRoomText] = useState("")
  const [roomDesc, setRoomDesc] = useState("")
  const [roomDesc2, setRoomDesc2] = useState("")
  const [roomSize, setRoomSize] = useState("")
  const [roomRooms, setRoomRooms] = useState("")
  const [roomMans, setRoomMans] = useState("")
  const [roomImages, setRoomImages] = useState([] as string[])

  const [activeTab, setActiveTab] = useState(1)
  const [activeTabEd, setActiveTabEd] = useState(1)
  const [isDataLoading, setIsDataLoading] = useState(false)

  const props2: UploadProps = {
    name: 'images',
    multiple: false,
    maxCount: 1,
    showUploadList: false,
    headers: {
      "Authorization": "Bearer " + store.token
    },
    action: `${API_URL}/core/uploader/images`,
    beforeUpload: async (file, FileList) => {
      setIsDataLoading(prev => prev = true);
      const options = { maxSizeMB: 0.8, maxWidthOrHeight: 1920, useWebWorker: true }
      try {
        const compressedFile = await imageCompression(file, options);
        console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
        console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
        return compressedFile;
      } catch (error) {
        console.log(error);
      }
    },
    onChange(info) {
      const { status } = info.file;
      if (status === 'done') {
        message.success(`Файл успешно загружен!`);
        console.log(info.file.response)
        setRoomImage(prev => prev = info.file.response.urls[0])
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  }

  const props3: UploadProps = {
    name: 'images',
    multiple: false,
    maxCount: 1,
    showUploadList: false,
    headers: {
      "Authorization": "Bearer " + store.token
    },
    action: `${API_URL}/core/uploader/images`,
    beforeUpload: async (file, FileList) => {
      setIsDataLoading(prev => prev = true);
      const options = { maxSizeMB: 0.8, maxWidthOrHeight: 1920, useWebWorker: true }
      try {
        const compressedFile = await imageCompression(file, options);
        console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
        console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
        return compressedFile;
      } catch (error) {
        console.log(error);
      }
    },
    onChange(info) {
      const { status } = info.file;
      if (status === 'done') {
        message.success(`Файл успешно загружен!`);
        console.log(info.file.response)
        setRoomImages(prev => prev = [...prev, info.file.response.urls[0]])
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  }

  const selectPage = (room: IXRoom) => {
    setRoomTitle(room.title!)
    setRoomImage(room.image!)
    setRoomImages(room.images!)
    setRoomUrl(room.url!)
    setRoomText(room.text!)
    setRoomDesc(room.desc!)
    setRoomDesc2(room.desc2!)
    setRoomMans(`${room.mans}`)
    setRoomRooms(`${room.rooms}`)
    setRoomSize(`${room.size}`)
    setSelectedRoom(room)
  }

  const getFilteredItems = () => {
    return adminStore.roomsData
  }

  const addNewRoomAction = async () => {

  }

  const saveRoomAction = async () => {
    let props: any = {
      title: roomTitle,
      text: roomText,
      desc: roomDesc,
      desc2: roomDesc2,
      url: roomUrl,
      image: roomImage,
      index: selectedRoom!.index,
      images: roomImages,
      size: parseInt(roomSize),
      mans: parseInt(roomMans),
      rooms: parseInt(roomRooms)
    }

    let newLst: any[] = []
    adminStore.roomsData.forEach((item: IXRoom) => {
      if (item.index == selectedRoom!.index) {
        newLst.push(props)
      } else {
        newLst.push(item)
      }
    })

    const res = await ApiService.core.updateSetting(3, {
      data: newLst
    })

    localStorage.setItem(StorageKeys.SETTINGS_ROOMS, JSON.stringify(newLst))
    adminStore.setRoomsData(newLst)

    console.log(newLst)

    message.success("Номер успешно обновлен!")
  }

  const isDataChanged = () => {
    if (
      selectedRoom != null
    ) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    
  }, [])

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className={styles.grid_block}>
              <h3><span style={{
                display: selectedRoom != null ? "inline-block" : "none"
              }} onClick={() => setSelectedRoom(prev => prev = null)}><i className="far fa-arrow-left-long"></i> </span>{selectedRoom == null ? "Список номеров" : "Редактирование номера"}</h3>

              {selectedRoom == null ? <>
                <div className={styles.xactions}>
                  <div className={styles.xactions_item + " " + styles.success + " " + styles.disabled} onClick={() => addNewRoomAction()}>Добавить номер</div>
                </div>

                <div className={styles.xtable_wrap} style={{marginTop: "24px"}}>
                  <div className={styles.xtable}>
                    <div className={styles.xtable_head}>
                      <div>ID</div>
                      <div>Наименование</div>
                      <div>URL</div>
                      <div>Кол-во комнат</div>
                      <div>Действия</div>
                    </div>
                    <div className={styles.xtable_body}>
                      {getFilteredItems().map((item: IXRoom) => <div className={styles.xlist_item} onClick={() => selectPage(item)}>
                        <div className={styles.xlist_item__id}>{item.index}</div>
                        <div className={styles.xlist_item__name}><span>{item.title}</span></div>
                        <div className={styles.xlist_item__pagename}>{item.url}</div>
                        <div className={styles.xlist_item__group}>{item.rooms}</div>
                        <div className={styles.xlist_item__actions}>
                          <button><i className="far fa-pen-to-square"></i></button>
                        </div>
                      </div>)}
                    </div>
                  </div>
                </div>
              </> : <>
                <div className={styles.xactions}>
                  <div className={styles.xactions_item + " " + styles.success} onClick={() => saveRoomAction()}>Cохранить</div>
                </div>
                
                <div className="row">
                  <div className="col-12 col-md-9">

                    <div className={styles.xtabs + " " + styles.minimized + " " + styles[`active${activeTabEd}`]}>
                      <div className={styles.xtabs_item} onClick={() => setActiveTabEd(prev => prev = 1)}>Основное</div>
                      <div className={styles.xtabs_item} onClick={() => setActiveTabEd(prev => prev = 2)}>Изображения</div>
                    </div>

                    <div className={styles.xform} style={{display: activeTabEd == 1 ? "block" : "none"}}>
                      <div className={styles.xform_input}>
                        <label htmlFor="sale_title">Заголовок</label>
                        <input type="text" id="sale_title" placeholder="Заголовок акции" value={roomTitle} onChange={(event) => {
                          setRoomTitle(prev => prev = event.target.value)
                        }} />
                      </div>
                      <div className={styles.flexed}>
                        <div className={styles.xform_input}>
                          <label htmlFor="room_url">URL страницы</label>
                          <input type="text" id="room_url" placeholder="URL страницы" value={roomUrl} onChange={(event) => setRoomUrl(prev => prev = event.target.value)} />
                        </div>
                        <div className={styles.xform_input}>
                          <label htmlFor="room_size">Размер номера</label>
                          <input type="number" id="room_size" placeholder="Размер номера" value={roomSize} onChange={(event) => setRoomSize(prev => prev = event.target.value)} />
                        </div>
                        <div className={styles.xform_input}>
                          <label htmlFor="room_rooms">Кол-во комнат</label>
                          <input type="number" id="room_rooms" placeholder="Кол-во комнат" value={roomRooms} onChange={(event) => setRoomRooms(prev => prev = event.target.value)} />
                        </div>
                        <div className={styles.xform_input}>
                          <label htmlFor="room_mans">Кол-во чел.</label>
                          <input type="number" id="room_mans" placeholder="Кол-во человек" value={roomMans} onChange={(event) => setRoomMans(prev => prev = event.target.value)} />
                        </div>
                      </div>
                      <div className={styles.xform_input}>
                        <label htmlFor="sale_title">Описание 1</label>
                        <textarea rows={2} placeholder="Текст описания..." value={roomDesc} onChange={(event) => setRoomDesc(prev => prev = event.target.value)}></textarea>
                      </div>
                      <div className={styles.xform_input}>
                        <label htmlFor="sale_title">Описание 2</label>
                        <textarea rows={2} placeholder="Текст описания..." value={roomDesc2} onChange={(event) => setRoomDesc2(prev => prev = event.target.value)}></textarea>
                      </div>
                      <div className={styles.xform_input}>
                        <label htmlFor="sale_title">Текст</label>
                        <textarea rows={6} placeholder="Текст описания..." value={roomText} onChange={(event) => setRoomText(prev => prev = event.target.value)}></textarea>
                      </div>
                    </div>
                    
                    <div className={styles.xform} style={{display: activeTabEd == 2 ? "block" : "none"}}>
                      <div className={styles.xform_input}>
                        <label htmlFor="sale_title">Изображения</label>
                        <div className={styles.ximages} style={{marginTop: "10px"}}>
                          <div className={styles.ximages_block}>
                            <Upload {...props2} style={{width: "100%"}}>
                              <div className={styles.xupload}>
                                <div className={styles.xupload_image} style={{backgroundImage: `url(${roomImage})`}}></div>
                                <div className={styles.xupload_data}>
                                  <h4>Картинка номера</h4>
                                  <p>Нажмите или перенесите файл в эту область,<br/>в формате <b>PNG</b> / <b>JPG</b> / <b>WEBP</b></p>
                                </div>
                              </div>
                            </Upload>
                            
                            <Upload {...props3} style={{width: "100%"}}>
                              <div className={styles.xuploader}>
                                <div className={styles.xupload_data}>
                                  <h4>Изображения</h4>
                                  <p>Нажмите или перенесите файл в эту область,<br/>в формате <b>PNG</b> / <b>JPG</b> / <b>WEBP</b></p>
                                </div>
                                <div className={styles.xupload_items}>
                                  {roomImages.map((image, ixxx) => <div className={styles.xupload_image} style={{backgroundImage: `url(${image})`}}>
                                    <div className={styles.delete} onClick={(event) => {
                                      event.preventDefault()
                                      event.stopPropagation()
                                      setRoomImages(prev => prev = prev.filter((v, ixy) => ixy != ixxx))
                                    }}><i className="far fa-xmark"></i></div>
                                  </div>)}
                                </div>
                              </div>
                            </Upload>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </>}

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(RoomsManager)