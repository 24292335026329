import { observer } from "mobx-react-lite"
import { FC, useContext, useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { Context } from "../../.."

import styles from "../../../styles/AdminManager.module.scss"

const HomeManager: FC = () => {

  const { store } = useContext(Context)
  const location = useLocation()

  const [login, setLogin] = useState("")

  useEffect(() => {

  }, [])

  return (
    <>
      <div className={styles.indev}>В разработке...</div>
    </>
  )
}

export default observer(HomeManager)