import { observer } from "mobx-react-lite"
import { FC, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";

import styles from "../styles/Footer.module.scss"

const Footer: FC = () => {

  const navigate = useNavigate()

  const [isToTopVisible, setIsToTopVisible] = useState(false)
  
  const toTop = () => {
    window.scrollTo({
      behavior: "smooth",
      top: 0
    })
  }

  useEffect(() => {
    const onScroll = (event: any) => {
      const scrollTop = event.target.scrollingElement.scrollTop;
      if (scrollTop >= 80) {
        setIsToTopVisible(true);
      } else {
        setIsToTopVisible(false);
      }
    }

    document.addEventListener('scroll', onScroll);

    return () => {
      document.removeEventListener('scroll', onScroll);
    }
  }, [])

  return (
    <>
      <div className={styles.to_top} onClick={() => toTop()} style={{opacity: isToTopVisible ? "1" : "0"}}><i className="far fa-chevron-up"></i></div>
    
      <footer className={styles.footer}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12">
              <div className={styles.lgs}>
                <div className="row">
                  <div className="col-12 col-md-4">
                    <div className={styles.f_block}>
                      <h3>Отдел бронирования:</h3>
                      <a href="tel:88002221027">8 (800) 222-10-27</a>
                      <p className={styles.smalled}>Звонок бесплатный для всех регионов</p>
                    </div>
                    <div className={styles.f_block}>
                      <h3>Whatsapp:</h3>
                      <a href="tel:+79381311333">+7 (938) 131-13-33</a>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className={styles.f_block}>
                      <h3>Наш адрес:</h3>
                      <a href="https://yandex.ru/maps/11020/karachay-cherkess-republic/house/gornaya_ulitsa_7/YEgYdw9jQEABQFppfXl1cn9hZA==/?ll=41.185693%2C43.542418&mode=search&sctx=ZAAAAAgAEAAaKAoSCZ5eKcsQz0JAEdOgaB7A4EtAEhIJQnxgx38B9T8RDDz3Hi454T8iBgABAgMEBSgKOABA1QFIAWIScG9pbnRfY29udGV4dF92Mj0xagJydZ0BzcxMPaABAKgBAL0BZbLPbMIBAwAAAOoBAPIBAPgBAIIClgHQmtCw0YDQsNGH0LDQtdCy0L4t0KfQtdGA0LrQtdGB0YHQutCw0Y8g0KDQtdGB0L%2FRg9Cx0LvQuNC60LAsINCX0LXQu9C10L3Rh9GD0LrRgdC60LjQuSDRgNCw0LnQvtC9LCDQv9C%2B0YEuINCg0L7QvNCw0L3RgtC40LosINGD0LsuINCT0L7RgNC90LDRjywg0LQuIDeKAgCSAgCaAgxkZXNrdG9wLW1hcHM%3D&sll=41.221376%2C43.545341&sspn=0.102360%2C0.090467&text=%D0%9A%D0%B0%D1%80%D0%B0%D1%87%D0%B0%D0%B5%D0%B2%D0%BE-%D0%A7%D0%B5%D1%80%D0%BA%D0%B5%D1%81%D1%81%D0%BA%D0%B0%D1%8F%20%D0%A0%D0%B5%D1%81%D0%BF%D1%83%D0%B1%D0%BB%D0%B8%D0%BA%D0%B0%2C%20%D0%97%D0%B5%D0%BB%D0%B5%D0%BD%D1%87%D1%83%D0%BA%D1%81%D0%BA%D0%B8%D0%B9%20%D1%80%D0%B0%D0%B9%D0%BE%D0%BD%2C%20%D0%BF%D0%BE%D1%81.%20%D0%A0%D0%BE%D0%BC%D0%B0%D0%BD%D1%82%D0%B8%D0%BA%2C%20%D1%83%D0%BB.%20%D0%93%D0%BE%D1%80%D0%BD%D0%B0%D1%8F%2C%20%D0%B4.%207&z=16.31">Карачаево-Черкесская Республика,<br/>Зеленчукский район, пос. Романтик,<br/>ул. Горная, д. 7</a>
                    </div>
                    <div className={styles.f_block}>
                      <h3>Проложить маршрут</h3>
                      <a href="yandexnavi://build_route_on_map?lat_to=43.543405&lon_to=41.183830">через Яндекс.Навигатор</a>
                    </div>
                    <div className={styles.f_block}>
                      <h3>Email:</h3>
                      <a href="mailto:zakaz@royalresort.ru">zakaz@royalresort.ru</a>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className={styles.f_block}>
                      <h3>Мы в социальных сетях:</h3>
                      <div className={styles.socials}>
                        <a href="https://www.youtube.com/channel/UCugkhlTPzvNaEQNG3k21J0w"><img src="/assets/icons/social/yt.png" /></a>
                        <a href="https://wa.me/send?phone=79381311333"><img src="/assets/icons/social/wa.png" /></a>
                        <a href="https://vk.com/arkhyz_royal"><img src="/assets/icons/social/vk.png" /></a>
                        <a href="https://t.me/arkhyz_royal"><img src="/assets/icons/social/tg.png" /></a>
                      </div>
                    </div>
                    
                    <div className={styles.f_block}>
                      <h3>Корпоративным клиентам:</h3>
                      <a href="mailto:order@royalresort.ru">order@royalresort.ru</a>
                    </div>

                    <div className={styles.f_block}>
                      <h3>Акции и новости:</h3>
                      <a href="/subscribe">Подписаться на скидки</a>
                    </div>
                  </div>
                  <div className="col-12 col-md-12">
                    <div className={styles.f_block}>
                      <p className={styles.copys}>
                        © Arkhyz Royal Resort & Spa 2022. Все права защищены. Вся информация о персональных данных, включая платежные реквизиты, защищена в соответствии с законом 152-ФЗ " О ПЕРСОНАЛЬНЫХ ДАННЫХ". Начиная или продолжая пользоваться данным сайтом, вы соглашаетесь на <a href="/cookie-policy">обработку файлов «Cookie»</a>. <a href="https://royalresort.ru/policy.pdf">Политика в отношении обработки персональных данных</a>.
                      </p>
                      <p className={styles.copys_lnks}>
                        <a href="/rekvizity.pdf">Реквизиты</a>
                        <a href="/pravila.pdf">Правила проживания</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default observer(Footer)