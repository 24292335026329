
import { observer } from "mobx-react-lite"
import { FC, useContext } from "react"
import {useLocation} from "react-router-dom"
import {Context} from "../../.."
import {XImage} from "../../../core/models/XImage"

import styles from "../../../styles/Subpage.module.scss"

const EXImgs: XImage[] = [
  { url: "/assets/images/excurses/ex1.jpeg", title: "" },
  { url: "/assets/images/excurses/ex2.jpeg", title: "" },
  { url: "/assets/images/excurses/ex3.jpeg", title: "" },
  { url: "/assets/images/excurses/ex4.jpeg", title: "" },
  { url: "/assets/images/excurses/ex5.jpeg", title: "" },
  { url: "/assets/images/excurses/ex6.jpeg", title: "" }
]

const KCImgs: XImage[] = [
  { url: "/assets/images/excurses/kc/kc1.jpeg", title: "" },
  { url: "/assets/images/excurses/kc/kc2.jpeg", title: "" },
  { url: "/assets/images/excurses/kc/kc3.jpeg", title: "" }
]

const NPPNImgs: XImage[] = [
  { url: "/assets/images/excurses/nppn/n1.jpeg", title: "" },
  { url: "/assets/images/excurses/nppn/n2.jpeg", title: "" },
  { url: "/assets/images/excurses/nppn/n3.jpeg", title: "" },
  { url: "/assets/images/excurses/nppn/n4.jpeg", title: "" },
  { url: "/assets/images/excurses/nppn/n5.jpeg", title: "" },
  { url: "/assets/images/excurses/nppn/n6.jpeg", title: "" }
]

const VPImgs: XImage[] = [
  { url: "/assets/images/velopro/1.jpeg", title: "" },
  { url: "/assets/images/velopro/2.jpeg", title: "" },
  { url: "/assets/images/velopro/3.jpeg", title: "" },
  { url: "/assets/images/velopro/4.jpeg", title: "" },
  { url: "/assets/images/velopro/5.jpeg", title: "" },
  { url: "/assets/images/velopro/6.jpeg", title: "" }
]

const PKImgs: XImage[] = [
  { url: "/assets/images/piknik/1.jpeg", title: "" },
  { url: "/assets/images/piknik/2.jpeg", title: "" },
  { url: "/assets/images/piknik/3.jpeg", title: "" }
]

const EPImgs: XImage[] = [
  { url: "/assets/images/ecopath/1.jpeg", title: "" },
  { url: "/assets/images/ecopath/2.jpeg", title: "" },
  { url: "/assets/images/ecopath/3.jpeg", title: "" }
]

const EPBImgs: XImage[] = [
  { url: "/assets/images/ecopath/4.jpeg", title: "" },
  { url: "/assets/images/ecopath/5.jpeg", title: "" },
  { url: "/assets/images/ecopath/6.jpeg", title: "" }
]

const Entertmnt: FC = () => {

  const { store } = useContext(Context)
  const location = useLocation()

  const openGalleryModal = (imgs: XImage[], index: number) => {
    store.setSelectedImages(imgs)
    store.setSelectedImageIndex(index)
    store.setIsImageViewVisible(true)
  }

  return (
    <>
      {location.pathname.includes("/nochnaya-progulka-po-nebu") ? <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2><strong>Крупнейшая в России обсерватория</strong> - космос рядом!</h2>
                
              <p>
                Экскурсия в обсерваторию познакомит с удивительным миром астрономии. Изучение звёзд, открытие новых планет, тайны космоса.<br/>
                Мы позаботимся о комфортном трансферте до места назначения и горячих напитках.<br/><br/>
              </p>
                
              <div className={styles.grid_images}>
                <div className={styles.gi_other}>
                  {NPPNImgs.map((image, ix) => <div style={{height: "260px", backgroundImage: `url(${image.url})`}} onClick={() => openGalleryModal(NPPNImgs, ix)}></div>)}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div> : <></>}
      
      {location.pathname.includes("/kids-cinema") ? <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2><strong>Кинотеатр</strong></h2>
                
              <p>
                Уютный вечер в компании друзей за просмотром любимого фильма. В Arkhyz Royal Resort & SPA вы всегда можете арендовать кинозал с мягкими креслами и хрустящим попкорном.<br/><br/>
                Попкорн для всей компании входит в стоимость аренды.<br/><br/>
              </p>
                
              <div className={styles.grid_images}>
                <div className={styles.gi_other}>
                  {KCImgs.map((image, ix) => <div style={{height: "260px", backgroundImage: `url(${image.url})`}} onClick={() => openGalleryModal(KCImgs, ix)}></div>)}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div> : <></>}

      {location.pathname.includes("/excursion-programs") ? <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2><strong>Экскурсионные программы - активный отдых</strong></h2>
                
              <p>
                В Arkhyz Royal Resort & SPA вы всегда можете воспользоваться услугами опытного гида, которой сможет гарантировать не только безопасность, но и увлекательную программу.<br/><br/>
                <strong>Экскурсионные туры проходят последующим направлениям:</strong><br/>
                - Аланское городище;<br/>
                - Наскальный лик Христа;<br/>
                - Софийские озера и водопады;<br/>
                - Белый водопад;<br/>
                - Крупнейшая в России астрофизическая обсерватория;<br/>
                - Джип-туры по вашим предпочтениям.<br/><br/>

                Экскурсионные программы могут варьироваться, подробную и актуальную информацию вы всегда можете уточнить у наших администраторов.<br/><br/>
              </p>
                
              <div className={styles.grid_images}>
                <div className={styles.gi_other}>
                  {EXImgs.map((image, ix) => <div style={{height: "260px", backgroundImage: `url(${image.url})`}} onClick={() => openGalleryModal(EXImgs, ix)}></div>)}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div> : <></>}

      {location.pathname.includes("/veloprogulka") ? <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2><strong>Велопрогулка по красотам Архыза - увидеть уникальную природу с пользой для здоровья!</strong></h2>
                
              <p>
                В прокате имеются новые модели горных велосипедов, которые подходят для активной езды по различным дорогам и пересеченной местности.<br/>
                Это тот редкий случай, когда можно совместить удовольствие от путешествия с умеренной физической нагрузкой, которая приносит приятную усталость и позволяет поддерживать себя в отличной физической форме.<br/><br/>
              </p>
                
              <div className={styles.grid_images}>
                <div className={styles.gi_other}>
                  {VPImgs.map((image, ix) => <div style={{height: "260px", backgroundImage: `url(${image.url})`}} onClick={() => openGalleryModal(VPImgs, ix)}></div>)}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div> : <></>}

      {location.pathname.includes("/picnick") ? <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2><strong>Обед в горах</strong> - красоты Архыза</h2>
                
              <p>
                Магия Архыза - мир волшебства и сказки, бесконечные вершины, кристально чистые озера и реки поразят вас своей совершенной и безупречной красотой. Эта услуга подходит, как для любителей экстрима, так и для ценителей единения с природой.<br/>
                В программу входит джип-тур до места пикника от шеф – повара Arkhyz Royal Resort & SPA, конная прогулка (фотоссесия с лошадьми за отдельную плату) и катание на багги по вашему маршруту.<br/><br/>
              </p>
                
              <div className={styles.grid_images}>
                <div className={styles.gi_other + " " + styles.gi_other_simple}>
                  {PKImgs.map((image, ix) => <div style={{height: "260px", backgroundImage: `url(${image.url})`}} onClick={() => openGalleryModal(PKImgs, ix)}></div>)}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div> : <></>}

      {location.pathname.includes("/ecopath") ? <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2><strong>Экотропа</strong> - единение с природой</h2>
                
              <p>
                Идти по нашей тропе не только интересно, но и полезно. Весной здесь еще лежит снег, а летом зеленые горы вокруг кажутся плюшевыми и пестрят цветами. Тропа петляет по сосновому и пихтовому лесу через поляны субальпийского разнотравья, порой выводя на эффектные видовые площадки.<br/>
                Дышите полной грудью: хвойные богаты природными антибиотиками, фитонцидами.<br/><br/>
              </p>
                
              <div className={styles.grid_images}>
                <div className={styles.gi_other + " " + styles.gi_other_simple}>
                  {EPImgs.map((image, ix) => <div style={{height: "260px", backgroundImage: `url(${image.url})`}} onClick={() => openGalleryModal(EPImgs, ix)}></div>)}
                </div>
              </div>
                
              <div className={styles.grid_images}>
                <div className={styles.gi_other + " " + styles.gi_other_simple}>
                  {EPBImgs.map((image, ix) => <div style={{height: "585px", backgroundImage: `url(${image.url})`}} onClick={() => openGalleryModal(EPBImgs, ix)}></div>)}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div> : <></>}
    </>
  )
}

export default observer(Entertmnt)