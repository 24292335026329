import { observer } from "mobx-react-lite"
import { FC, useContext, useEffect, useState } from "react"
import {Context} from "../../../.."
import {menuItems, MenuSubItem} from "../../../../core/utils/menu"

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import { Swiper, SwiperSlide } from "swiper/react"

import styles from "../../../../styles/Subpage.module.scss"

const NashiNomera: FC = () => {

  const { store } = useContext(Context)

  const [swiper, setSwiper] = useState(null as any | null);

  const [selectedImg, setSelectedImg] = useState("")
  const [imageShown, setImageShown] = useState(true)
  const [selectedItem, setSelectedItem] = useState(null as MenuSubItem | null)
  const [selectedIx, setSelectedIx] = useState(0)
  const [allRooms, setAllRooms] = useState([] as MenuSubItem[])

  const buildRooms = () => {
    if (store.allRooms.length != 0) {
      return;
    }

    let rooms = menuItems[1].dropdown!.items
    store.setAllRooms(rooms)
    store.setSelectedIx(0)
    //setSelectedImg(rooms[0].image!)
    //setSelectedItem(rooms[0])

    setInterval(() => {
      if (store.selectedIx < (rooms.length - 1)) {
        store.setSelectedIx(store.selectedIx + 1)
      } else {
        store.setSelectedIx(0)
      }
    }, 3000);
  }

  useEffect(() => {
    buildRooms()
  }, [])

  return (
    <>
      <div className={styles.subpage + " " + styles.sprited}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              
              {store.allRooms.length > 0 ? <div className={styles.list_gallery}>
                <div className={styles.list_gallery__img}>
                  <div style={{opacity: imageShown ? 1 : 0, backgroundImage: `url(${store.allRooms[store.selectedIx].image})`}}></div>
                </div>
                <div className={styles.list_gallery__data}>
                  <h2>НОМЕРА</h2>
                  {store.allRooms.map((item, ix) => <a href={`/${item.url}`} className={ix == store.selectedIx ? styles.white : ""}>—  {item.title}</a>)}
                </div>
              </div> : <></>}
            </div>
          </div>
        </div>

        
        <div className={styles.list_gallery_slider}>
          <div className={styles.slider_prev} onClick={() => swiper.slidePrev()}><i className="fas fa-chevron-left"></i></div>
          <div className={styles.slider_next} onClick={() => swiper.slideNext()}><i className="fas fa-chevron-right"></i></div>

          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className={styles.lgs}>
                  <Swiper
                    loop={true}
                    freeMode={true}
                    slidesPerView={store.isMobile ? 1 : 3}
                    spaceBetween={25}
                    onSwiper={(sw) => setSwiper((prev: any) => prev = sw)}>
                    {store.allRooms.map((room) => <SwiperSlide>
                      <div className={styles.room_item}>
                        <div className={styles.room_item__img}>
                          <div style={{backgroundImage: `url(${room.image})`}}></div>
                        </div>
                        <div className={styles.room_item__title}>{room.title}</div>
                        <div className={styles.room_item__desc} dangerouslySetInnerHTML={{__html: `${room.desc}`}}></div>
                      </div>
                    </SwiperSlide>)}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(NashiNomera)