import { observer } from "mobx-react-lite"
import { FC, useContext, useEffect, useState } from "react"

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import { Swiper, SwiperSlide } from "swiper/react"
import { Context } from "../../../.."
import {useFetching} from "../../../../core/hooks/useFetching"
import { PromoSale } from "../../../../core/models/PromoSale"
import { Promos } from "../../../../core/utils/sales"

import styles from "../../../../styles/Subpage.module.scss"

const Promo: FC = () => {

  const { store, adminStore } = useContext(Context)

  const [swiper, setSwiper] = useState(null as any | null);

  const [allSales, setAllSales] = useState(Promos)

  const [fetchSales, isSalesLoading] = useFetching(async () => {
    console.log(adminStore.salesData)
    setAllSales(adminStore.salesData)
  })

  useEffect(() => {
    fetchSales()
  }, [])
  
  return (
    <>
      <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 style={{marginBottom: "0px"}}><strong>Акции</strong></h2>
            </div>
          </div>
        </div>

        <div className={styles.list_gallery_slider}>
          <div className={styles.slider_prev} onClick={() => swiper.slidePrev()}><i className="fas fa-chevron-left"></i></div>
          <div className={styles.slider_next} onClick={() => swiper.slideNext()}><i className="fas fa-chevron-right"></i></div>

          <div className="container">
            <div className="row">
              <div className="col-12">
                {adminStore.salesData.length > 0 ? <div className={styles.lgs}>
                  <Swiper
                    loop={true}
                    freeMode={true}
                    slidesPerView={store.isMobile ? 1 : 3}
                    spaceBetween={25}
                    onSwiper={(sw) => setSwiper((prev: any) => prev = sw)}>
                    {adminStore.salesData.filter(v => v.status == 1).map((promo) => <SwiperSlide>
                      <a href={promo.url} className={styles.promo_item}>
                        <div className={styles.promo_item__img}>
                          <div style={{backgroundImage: `url(${promo.image})`}}></div>
                        </div>
                        <div className={styles.promo_item__tag}>{promo.tag}</div>
                        <div className={styles.promo_item__title} dangerouslySetInnerHTML={{__html: promo.title}}></div>
                      </a>
                    </SwiperSlide>)}
                  </Swiper>
                </div> : <></>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(Promo)