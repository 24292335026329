import { observer } from "mobx-react-lite"
import { FC, useEffect } from "react"

import styles from "../../../styles/Subpage.module.scss"

const Booking: FC = () => {

  useEffect(() => {
    (function(w: any) {
      setTimeout(() => {
        var q = [
          ['setContext', 'TL-INT-royalresort-summer', 'ru'],
          ['embed', 'booking-form', {
            container: 'tl-booking-form'
          }]
        ];
        var t = w.travelline = (w.travelline || {}),
          ti = t.integration = (t.integration || {});
        ti.__cq = ti.__cq ? ti.__cq.concat(q) : q;
        if (!ti.__loader) {
          ti.__loader = true;
          var d = w.document,
            p = d.location.protocol,
            s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = (p == 'https:' ? p : 'http:') + '//ibe.tlintegration.com/integration/loader.js';
          (d.getElementsByTagName('head')[0] || d.getElementsByTagName('body')[0]).appendChild(s);
        }
      }, 500);
    })(window)
  }, [])

  return (
    <>
      <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 style={{textAlign: "center"}}>БРОНИРОВАНИЕ</h3>
              
              <p>Заезд с 15:00. Выезд до 12:00.&nbsp; Заселение в отель производится только при предъявлении внутреннего паспорта гражданина РФ каждого гостя (для лиц до 14 лет — свидетельства о рождении).<br/><br/></p>
              
              <div id="tl-booking-form" className="tl-booking-form"><iframe id="tlFrame0.049391215516969345" name="tlFrame0.049391215516969345" frameBorder="0" width="100%" height="1" scrolling="no" style={{position: "relative", width: "1px", minWidth: "100%", height: "762px"}} src="https://ru-ibe.tlintegration.ru/booking2/hotel/index.20881.gc.html?int=true&amp;adults=2&amp;providerId=20881&amp;theme=20881&amp;accommodationMode=auto&amp;defaultAdults=2&amp;language=ru&amp;width=100%25&amp;tlTrackerUser=585466889217428098&amp;tlTrackerSession=628085969452947190&amp;origin=https%3A%2F%2Froyalresort.ru&amp;surl=https%3A%2F%2Fru-ibe.tlintegration.ru&amp;conId=11195723647950473&amp;contWidth=1259"></iframe></div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(Booking)