import { observer } from "mobx-react-lite"
import { FC, useContext, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { Context } from "../../.."
import { useFetching } from "../../../core/hooks/useFetching"

import styles from "../../../styles/Subpage.module.scss"

const AboutUs: FC = () => {

  const { store, adminStore } = useContext(Context)

  const location = useLocation()

  const [page, setPage] = useState(null as null | any)

  const findParsedUrl = (url: string) => {
    return url.replace("/", "")
  }
  const [fetchPage, isPageLoading, errorPage] = useFetching(async () => {
    const pagename = findParsedUrl(location.pathname)

    console.log(pagename, adminStore.pages)

    const pageEx = adminStore.pages.find(v => v.pagename == pagename)

    console.log(pageEx)

    setPage((prev: any) => prev = pageEx)
  })

  useEffect(() => {
    fetchPage()
  }, [])

  return (
    page != null ? <>
      <div className={styles.subpage}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2><strong>О НАС</strong></h2>
              
              <div className={styles.flexed}>
                <div><img src={page.images[0].items[0].url} /></div>
                <div dangerouslySetInnerHTML={{__html: page.content}}></div>
              </div>

              <div className={styles.wpb_wrapper} dangerouslySetInnerHTML={{__html: page.data.content2}}></div>
              
            </div>
          </div>
        </div>
      </div>
    </> : <></>
  )
}

export default observer(AboutUs)