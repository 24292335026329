export enum HomeMenuPage {
  NONE = "",
  FEATURES = "features",
  PRODS = "products",
  PRODS_O = "products_one",
  PRODS_TW = "products_two",
  PRODS_T = "products_three",
  USEFULLS = "usefulls",
  EXPIRIENCE = "expirience"
}

export enum ControllerName {
  USERS = "/users",
  PAGES = "/pages",
  CORE = "/core"
}

export const API_URL = "https://api.royalresort.ru";

export type SliderToggleDirection = "next" | "prev";
export enum SliderToggleDir {
  NEXT = "next",
  PREV = "prev"
}

export enum StorageKeys {
  AUTHED = "nx_royalresort_authed",
  USER = "nx_royalresort_user",
  TOKEN = "nx_royalresort_token",

  SETTINGS_THEME = "nx_royalresort_theme",
  SETTINGS_META = "nx_royalresort_meta",
  SETTINGS_ROOMS = "nx_royalresort_rooms",
  SETTINGS_SALES = "nx_royalresort_sales",

  PAGES = "nx_royalresort_pages"
}